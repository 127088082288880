const pointsHole = [
    {
        score: -3,
        pts: 8
    },
    {
        score: -2,
        pts: 5
    },
    {
        score: -1,
        pts: 2
    },
    {
        score: 0,
        pts: 0
    },
    {
        score: 1,
        pts: -1
    },
    {
        score: +2,
        pts: -3
    },
]

export default pointsHole
//react
import React, { useEffect, useState } from 'react'

//redux
import { useSelector } from 'react-redux';
import { setReduxLeaderboard } from '../../store/actions'
import { Redirect, Link } from 'react-router-dom';

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import Collapse from '@mui/material/Collapse';

//Material UI - icons
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';

//Components
import { WhiteButton } from '../../utils/Buttons';
import Menu from '../../components/user/Menu'
import UserData from '../../components/user/UserData'
import Logos from '../../components/user/Logos';

//firebase
import firebase from '../../database/firebase'

//utils
import fixedInt from '../../utils/functions/fixedInt';
import formatMoney from '../../utils/functions/formatMoney'
import { InfoRounded } from '@material-ui/icons';

//loader
import Loader from "react-loader-spinner";

//Constants
import { leaderboard } from '../../constants/tournament';

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
    },
    overlay: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 30%, #0e1d1deb 53%, #0e1d1de8 100%)',
        paddingTop: 30,
        paddingBottom: 100,
        textAlign: 'center',
        color: 'white',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: 30,
        [theme.breakpoints.down(450)]: {
            fontSize: '22px'
        }
    },
    
    actionButtons: {
        maxWidth: '800px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto',
            marginBottom: '15px',
        }
    },

    /* action buttons */
    actionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px',
        marginBottom: '15px',
        maxWidth: '800px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto',
            marginBottom: '15px',
        }
    },

    /* rounds */
    roundsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '5px',
        flex: 1,
        [theme.breakpoints.up(370)]: {
            paddingLeft: '10px'
        }
    },
    roundItem: {
        width: '35px',
        border: '2px solid white',
        borderRadius: '20px',
        marginRight: '8px',
        fontWeight: 'bold',
        fontSize: '12px',
        cursor: 'pointer',
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)'
        },
        [theme.breakpoints.up(370)]: {
            width: '40px',
            marginRight: '8px',
            fontSize: '14px',
        },
        [theme.breakpoints.up(768)]: {
            width: '40px',
            marginRight: '10px',
            fontSize: '16px',
        }
    },
    roundItemFinal: {
        width: '50px',
        border: '2px solid white',
        borderRadius: '20px',
        fontWeight: 'bold',
        fontSize: '12px',
        cursor: 'pointer',
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)'
        },
        [theme.breakpoints.up(370)]: {
            width: '70px',
            marginRight: '8px',
            fontSize: '14px',
        },
        [theme.breakpoints.up(768)]: {
            fontSize: '16px',
        }
    },
    refreshButton: {
        border: '1px solid white',
        borderRadius: '50%',
        padding: '3px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            background: 'white',
            color: 'black'
        },
        [theme.breakpoints.up(370)]: {
            padding: '6px',
        },
        [theme.breakpoints.up(500)]: {
            '&:hover': {
                background: 'white',
                color: 'black'
            }
        }
    },

    /* money */
    infoContainer: {
        maxWidth: '800px',
        margin: '10px',
        marginBottom: '20px',
        [theme.breakpoints.up(800)]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: 'auto',
            marginBottom: '20px'
        },
    },
    moneyContainer: {
        marginBottom: '20px',
        [theme.breakpoints.up(800)]: {
            marginBottom: '0px'
        }
    },
    moneyItem: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',  
    },
    message: {
        border: '1px dashed white',
        borderRadius: '5px',
        padding: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'left',
        flex: 1,
        [theme.breakpoints.up(800)]: {
            margin: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            //smaxWidth: '400px',
            marginLeft: '40px',
        },
    },
    messageText: {
        marginLeft: '10px'
    },

    /* leaderboard */
    leaderboard: {
        margin: '10px',
        borderRadius: '10px',
        //border: '1px solid white',
        //padding: '15px',
        maxWidth: '800px',
        margin: '10px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto'
        },
        
    },
    leaderboardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
        padding: '10px 2px',
        //background: '#0a2e0a'
        background: '#0E1D1D',
        [theme.breakpoints.up(500)]: {
            padding: '10px 3px',
        },
    },
    leaderboardSmallCell: {
        width: '35px',
        textAlign: 'center',
        //borderRight: '0.5px solid white'
    },
    
    leaderboardLargeCell: {
        flex: 1,
        textAlign: 'left',
        paddingLeft: '10px',
        borderLeft: '0.5px solid white',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    leaderboardMediumCell: {
        width: '60px',
        textAlign: 'center',
        borderLeft: '0.5px solid white',
        //borderRight: '0.5px solid white'
    },
    leaderboardLastCell: {
        width: '35px',
        textAlign: 'center',
        borderLeft: '0.5px solid white'
    },
    leaderboardError: {
        padding: '20px',
        margin: '10px auto',
        border: '1px dashed white',
        borderRadius: '10px',
        width: '50%'
    },

    /* player rows */
    leaderboardRow: {
        fontSize: '12px',
        fontWeight: 'bold',
        padding: '10px 3px',
        background: '#193636',
        cursor: 'pointer',
        '&:hover': {
            background: '#225050',
        }
        //background: '#011c11'
    },
    leaderboardRowHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 0px',
    },
    playerImg: {
        width: '30px',
        height: '30px',
        marginRight: '10px',
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },

    /* icons */
    icon: {
        color: 'white',
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)',
        }
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        }
    }
}))

export default function Leaderboard({classes}) {
    classes = useStyles();

    //redux
    const user = useSelector(state => state.userState.user);

    //state
    const [stateLeaderboard, setStateLeaderboard] = useState([]);
    const [leaderboardList, setLeaderboardList] = useState([]);
    const [selectedName, setSelectedName] = useState("");
    const [round, setRound] = useState(1)
    const [total, setTotal] = useState(3000)
    const [loading, setLoading] = useState(true)
    
    let distribution = [0.2, 0.2, 0.2, 0.2]

    useEffect(() => {
        handleRefresh()
    }, [])

    /* sleep */
    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time)
        )
    }

    const handleRefresh = () => {
        setLoading(true)
        sleep(1000).then(() => {
            firebase.db.collection(leaderboard).doc('leaderboard').onSnapshot((doc) => {
                //get position and points
                if(doc.exists) {
                    
                    setStateLeaderboard(doc.data())
                    setLeaderboardList(doc.data())
                    setReduxLeaderboard(doc.data())

                    
                    if(doc.data().r1) {
                        let num = doc.data().r1.length * 1000
                        console.log(num)
                        setTotal(num)
                    } else {
                        setTotal(0)
                    }
                    
                } 
                setLoading(false)
            })
        })
        
        
    }


    /* Handle select name (participant) */
    const handleSelectedName = (name) => {
        setSelectedName(name === selectedName ? "" : name)
    }

    return (
        <>
            {(user === null || user === undefined) && (
                <Redirect to="/"/>
            )}
            {user && user.type === 'user' && !user.hasSelected && (
                <Redirect to="/seleccion"/>
            )}
            
            <div className={classes.backgroundContainer}>
                <div className={classes.overlay}>
                    {/* header */}
                    <div className={classes.header} id="header">
                        <Logos/>
                        <h1 className={classes.title}>Ranking participantes</h1>
                    </div>

                    {user && user.type === 'admin' ? (
                        <>
                            <div className={classes.actionButtons}>
                                <Link to="/admin" className={classes.link}><WhiteButton>Regresar</WhiteButton></Link>
                            </div>
                        </>
                    ) :(
                        <>
                           
                        </>
                    )}

                    {/* Leaderboard */}
                    {/* rounds && refresh */}
                    <div className={classes.actionsContainer}>
                        <div className={classes.roundsContainer}>
                            <div className={classes.roundItem} onClick={() => {setRound(1)}} style={{background: round === 1 ? 'white': "none", color: round === 1 ? '#0a2e0a': 'white'}}>
                                <p>R1</p>
                            </div>
                            <div className={classes.roundItem} onClick={() => {setRound(2)}} style={{background: round === 2 ? 'white': "none", color: round === 2 ? '#0a2e0a': 'white'}}>
                                <p>R2</p>
                            </div>
                            <div className={classes.roundItem} onClick={() => {setRound(3)}} style={{background: round === 3 ? 'white': "none", color: round === 3 ? '#0a2e0a': 'white'}}>
                                <p>R3</p>
                            </div>
                            <div className={classes.roundItem} onClick={() => {setRound(4)}} style={{background: round === 4 ? 'white': "none", color: round === 4 ? '#0a2e0a': 'white'}}>
                                <p>R4</p>
                            </div>
                            <div className={classes.roundItemFinal} onClick={() => {setRound(5)}} style={{background: round === 5 ? 'white': "none", color: round === 5 ? '#0a2e0a': 'white'}}>
                                <p>FINAL</p>
                            </div>
                        </div>
                        <div>
                            <div onClick={handleRefresh} className={classes.refreshButton}>
                                <RefreshRoundedIcon/>
                            </div>
                        </div>
                    </div>

                    {/* money */}
                    <div className={classes.infoContainer}>
                        {leaderboardList.length !== 0 && (
                            <div className={classes.moneyContainer}>
                                <div className={classes.moneyItem}>
                                    <AttachMoneyRoundedIcon/>
                                    Bolsa Total: <b style={{marginLeft: 5}}> {total !== 0 ? formatMoney(total) : "-"}</b>
                                </div>
                                <div className={classes.moneyItem}>
                                    <AttachMoneyRoundedIcon/>
                                    Bolsa Ronda {round === 5 ? 'Final' : round}: <b style={{marginLeft: 5}}> {total !== 0 ? formatMoney(total*distribution[round === 5 ? 3 : round-1]) : "-"}</b>
                                </div>
                            </div>
                        )}
                        {round === 5 && (
                            <div className={classes.message}>
                                <InfoRounded/>
                                <p className={classes.messageText}>Los resultados oficiales de la ronda final serán los que se tengan una vez concluida la ronda 4</p>
                            </div>
                        )}
                    </div>
                    

                    {/* leaderboard */}
                    {loading ? (
                            <div className={classes.loaderContainer}>
                                <Loader  type="ThreeDots" color="#FFFFFF" height={100} width={100} />
                            </div>
                    ) : (
                        <div className={classes.leaderboard}>
                            <div className={classes.leaderboardHeader}>
                                <div className={classes.leaderboardSmallCell}>POS</div>
                                <div className={classes.leaderboardLargeCell}>NOMBRE</div>
                                <div className={classes.leaderboardMediumCell}>PTS</div>
                                <div className={classes.leaderboardMediumCell}>DINERO</div>
                            </div>
                            {/* rows of players */}
                            {leaderboardList.length !== 0 ? (
                                <>
                                    {leaderboardList[`r${round}`] ? leaderboardList[`r${round}`].map((item, index) => (
                                        <div key={index} className={classes.leaderboardRow}>
                                            <div className={classes.leaderboardRowHeader} onClick={() => {handleSelectedName(item.name)}}>
                                                <div className={classes.leaderboardSmallCell}>{item.position}</div>
                                                <div className={classes.leaderboardLargeCell}>
                                                    <p>{item.name}</p>
                                                </div>
                                                <div className={classes.leaderboardMediumCell}>{fixedInt(item.pts)}</div>
                                                <div className={classes.leaderboardMediumCell}>${item.money ? item.money : '0'}</div>
                                            </div>
                                            <Collapse in={item.name === selectedName}>
                                                <UserData team={item.team} round={round}/>
                                            </Collapse>
                                        </div>
                                    )) : (
                                        <div className={classes.leaderboardRow}>
                                            <div className={classes.leaderboardError}>
                                                Todavía no hay resultados de la ronda
                                            </div>
                                        </div>
                                    )}
                                </>
                            ): (
                                <div className={classes.leaderboardError}>
                                    Todavía no hay resultados
                                </div>
                            )}

                        </div>
                    )}
                    
                    
                    
                    <Menu page="leaderboard"/>
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react';

//firebase
import firebase from '../../database/firebase'

//router dom
import { Link, Redirect } from 'react-router-dom'

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from "../../store/actions";

//utils
import { WhiteButton } from '../../utils/Buttons'
import MyMenu from '../../components/user/Menu'

//loader
import Loader from "react-loader-spinner";

//components
import Logos from '../../components/user/Logos';

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
    },
    overlay: {
        width: '100vw',
        height: 'calc(100vh - 100px)',
        backgroundImage: 'linear-gradient(180deg, #0E191C 40%, #0e1d1deb 53%, #0e1d1de8 100%)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: '30px'
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flex: 1
    },
    formContainer: {
        width: '90%',
        maxWidth: '600px',
        borderRadius: "20px",
        height: '275px',
    },
    passwordInput: {
        flex: '1',
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '45px',
        border: 'none',
        background: "none",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    userInput: {
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '45px',
        border: '1px solid white',
        marginBottom: '20px',
        background: "none",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    passwordContainer: {
        display: 'flex',
        width: '100%',
        background: 'rgba(255,255,255,0)',
        borderRadius: '25px',
        border: '1px solid white',
        marginBottom: '20px',
    },
    errorContainer: {
        background: 'rgba(255,255,255,0.25)',
        borderRadius: 10,
        padding: 20,
        marginTop: 20
    },
    errorText: {
        color: 'white',
        fontSize: '25px'
    },
    link: {
        color: 'white',
        marginBottom: 10,
        '&:hover': {
            color: 'white'
        }
    },
    loaderContainer:  {
        margin: 'auto',
        width: 80,
        paddingTop: '30px'
    }
}))

export default function Index({classes}) {
    classes = useStyles();
    
    //redux
    const dispacth = useDispatch();
    const user = useSelector(state => state.userState.user);

    //State
    const [values, setValues] = useState({
        currentPassword: '',
        confirmPassword: '',
        password: '',
        currentShowPassword: false,
        showPassword: false,
        confirmShowPassword: false,
        error: "",
        success: ""
    });

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickCurrentShowPassword = () => {
        setValues({ ...values, currentShowPassword: !values.currentShowPassword });
    };

    const handleClickConfirmShowPassword = () => {
        setValues({ ...values, confirmShowPassword: !values.confirmShowPassword });
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const reauthenticate = (currentPassword) => {
        var currentUser = firebase.firebase.auth().currentUser;
        var cred = firebase.firebase.auth.EmailAuthProvider.credential(currentUser.email, currentPassword);
        return currentUser.reauthenticateWithCredential(cred);
    }

    const changePassword = (currentPassword, newPassword) => {
        reauthenticate(currentPassword).then(() => {
            var currentUser = firebase.firebase.auth().currentUser;
            currentUser.updatePassword(newPassword).then(() => {
                setValues({...values, success: "Se actualizó la contraseña correctamente", error: ""})
            }).catch((error) => { 
                console.error(error)
                setValues({...values, error: "Error al actualizar la contraseña"})
            ; });
        }).catch((error) => { 
            console.error(error); 
            setValues({...values, error: "Contraseña actual incorrecta"})
        });
    }

    const handlePasswordChange = (e) => {
        e.preventDefault();
        if(values.currentPassword !== ""){
            if(values.password === values.confirmPassword){
                changePassword(values.currentPassword, values.password)       
            } else {
                setValues({...values, error: "No coinciden las contraseñas"})
            }
        } else {
            setValues({...values, error: "Escriba su contraseña actual"})
        }
        
    }

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.overlay}>
                <Logos/>
                <div className={classes.infoContainer}>
                    <form className={classes.formContainer}>
                        
                        <input type="hidden" value="something"/>
                        <div className={classes.passwordContainer}>
                            <input 
                                type={values.currentShowPassword ? 'text' : 'password'}
                                placeholder="Contraseña actual"
                                value={values.currentPassword}
                                onChange={(e) => setValues({...values, currentPassword: e.target.value})}
                                className={classes.passwordInput}
                            />
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickCurrentShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {values.currentShowPassword ? <Visibility  style={{color: 'white'}}/> : <VisibilityOff style={{color: 'white'}}/>}
                            </IconButton>
                        </div>
                        
                        <input type="hidden" value="something1"/>
                        <div className={classes.passwordContainer}>
                            <input 
                                type={values.showPassword ? 'text' : 'password'}
                                placeholder="Contraseña"
                                value={values.password}
                                onChange={(e) => setValues({...values, password: e.target.value})}
                                className={classes.passwordInput}
                            />
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {values.showPassword ? <Visibility  style={{color: 'white'}}/> : <VisibilityOff style={{color: 'white'}}/>}
                            </IconButton>
                        </div>

                        <input type="hidden" value="something2"/>
                        <div className={classes.passwordContainer}>
                            <input 
                                type={values.confirmShowPassword ? 'text' : 'password'}
                                placeholder="Confirmar contraseña"
                                value={values.confirmPassword}
                                onChange={(e) => setValues({...values, confirmPassword: e.target.value})}
                                className={classes.passwordInput}
                            />
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickConfirmShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {values.confirmShowPassword ? <Visibility  style={{color: 'white'}}/> : <VisibilityOff style={{color: 'white'}}/>}
                            </IconButton>
                        </div>
                        <div>
                            <WhiteButton type="submit" onClick={(e) => {handlePasswordChange(e)}}>Cambiar contraseña</WhiteButton>
                        </div>
                        
                        {values.error !== "" && (
                            <div className={classes.errorContainer}>
                                <h2 className={classes.errorText}>{values.error}</h2>
                            </div>
                        )}
                        {values.success !== "" && (
                            <div className={classes.errorContainer}>
                                <h2 className={classes.errorText}>{values.success}</h2>
                            </div>
                        )}
                        {/* Loading */}
                        {values.loading && (
                            <div className={classes.loaderContainer}>
                                <Loader  type="BallTriangle" color="#FFFFFF" height={80} width={80} />
                            </div>
                        )}


                        {/* Menu */}
                        <MyMenu page="" />
                        
                    </form>
                </div>
            </div>
        </div>
    )
}

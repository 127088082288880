
import React, { useEffect } from 'react'

//redux
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from '@material-ui/core';

//Material UI - icons
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

//Components
import { WhiteButton } from '../../utils/Buttons';
import Menu from '../../components/user/Menu'
import Logos from '../../components/user/Logos';

//utils
import openInNewTab from '../../utils/functions/openInNewTab';


const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
    },
    overlay: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 30%, #0E191C 53%, #0E191C 100%)',
        paddingTop: 30,
        paddingBottom: 100,
        textAlign: 'center',
        color: 'white',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 100,
        width: '95%',
        [theme.breakpoints.down(450)]: {
            fontSize: '22px'
        }
    },
    subtitle: {
        fontSize: '20px',
        fontWeight: 'bold',
    },

    /* tutorials */
    tutorialsContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        maxWidth: '300px',
        margin: 'auto',
        marginBottom: '50px'
    },
    icon: {
        color: 'white'
    },
    actionButtons: {
        width: '200px',
        margin: 'auto'
    },
    link: {
        textDecoration: 'none'
    }

}))


export default function Tutorials({classes}) {
    classes = useStyles();

    //redux
    const user = useSelector(state => state.userState.user);

    //State
    useEffect(() => {
        console.log(user)
        if(user){

        }
    }, [])


    return (
        <>
            
            
            <div className={classes.backgroundContainer}>
                <div className={classes.overlay}>
                    {/* header */}
                    <div className={classes.header} id="header">
                        <Logos/>
                        <h1 className={classes.title}>Tutoriales para instalar aplicación</h1>
                    </div>

                    {/* reglamento */}
                    <div className={classes.tutorialsContainer}>
                        <div className={classes.tutorialItem}>
                            <h4 className={classes.subtitle}>iPhone</h4>
                            <Tooltip title="Descargar" placement='top'>
                                <IconButton onClick={() => {openInNewTab("./pdf/iphone.pdf")}}>
                                    <FileDownloadRoundedIcon className={classes.icon}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className={classes.tutorialItem}>
                            <h4 className={classes.subtitle}>Android</h4>
                            <Tooltip title="Descargar" placement='top'>
                                <IconButton onClick={() => {openInNewTab("./pdf/android.pdf")}}>
                                    <FileDownloadRoundedIcon className={classes.icon}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className={classes.tutorialItem}>
                            <h4 className={classes.subtitle}>MacOS</h4>
                            <Tooltip title="Descargar" placement='top'>
                                <IconButton onClick={() => {openInNewTab("./pdf/macos.pdf")}}>
                                    <FileDownloadRoundedIcon className={classes.icon}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className={classes.tutorialItem}>
                            <h4 className={classes.subtitle}>Windows</h4>
                            <Tooltip title="Descargar" placement='top'>
                                <IconButton onClick={() => {openInNewTab("./pdf/windows.pdf")}}>
                                    <FileDownloadRoundedIcon className={classes.icon}/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        
                    </div>

                    {user && (
                        <Menu page=""/>
                    )}
                    {!user && (
                        <div className={classes.actionButtons}>
                            <Link to="/sign-up" className={classes.link}><WhiteButton>Participar</WhiteButton></Link>
                        </div>
                    )}
                    
                </div>
            </div>
        </>
    )
}

import { SET_PLAYERS } from "./actionTypes";

const initilaState = {
    players: JSON.parse(localStorage.getItem("players")) || null
}

const players = (state=initilaState, action) => {
    switch(action.type){
        case SET_PLAYERS:
            state = {
                ...state,
                players: action.data
            }
            break
        default:
            state = {...state}

    }
    return state
}

export default players
import { SET_USERS } from "./actionTypes";

const initilaState = {
    users: JSON.parse(localStorage.getItem("reduxUsers")) || null
}

const users = (state=initilaState, action) => {
    switch(action.type){
        case SET_USERS:
            state = {
                ...state,
                users: action.data
            }
            break
        default:
            state = {...state}

    }
    return state
}

export default users
import { SET_USER, LOGOUT } from "./actionTypes";

const initilaState = {
    user: null
}

const user = (state=initilaState, action) => {
    switch(action.type){
        case SET_USER:
            state = {
                ...state,
                user: action.data
            }
            break
        case LOGOUT:
            state = {
                ...state,
                user: null
            }
            break
        default:
            state = {...state}

    }
    return state
}

export default user
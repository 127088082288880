import React, { useState } from 'react';

//firebase
import firebase from "../../database/firebase";

//router dom
import { Link, Redirect } from 'react-router-dom'

//redux
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from "../../store/actions";

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//utils
import { WhiteButton } from '../../utils/Buttons'

//components
import Logos from '../../components/user/Logos';

//Constants
import { title, year } from '../../constants/tournament';

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
    },
    overlay: {
        width: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 20%, #0e1d1deb 53%, #0e1d1de8 100%)',
        paddingBottom: 50,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: '30px'
    },
    title: {
        fontSize: '25px',
        fontWeight: 'bold',
        color: 'white',
        width: '90%',
        margin: 'auto',
        marginBottom: 20,
    },
    infoContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    formContainer: {
        width: '90%',
        maxWidth: '600px',
        borderRadius: "20px",
        height: '500px',
        [theme.breakpoints.up(500)]: {
            minWidth: '450px'
        }
    },
    passwordInput: {
        flex: '1',
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '50px',
        border: 'none',
        background: 'none',
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    userInput: {
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '45px',
        border: '1px solid white',
        marginBottom: '20px',
        background: 'none',
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.55)'
        }
    },
    passwordContainer: {
        display: 'flex',
        width: '100%',
        borderRadius: '45px',
        border: '1px solid white',
        marginBottom: '20px',
        //background: 'rgba(255,255,255,0.25)'
    },
    link: {
        color: 'white',
        textDecoration: 'none',
        '&:hover': {
            color: 'white'
        }
    },
    errorContainer: {
        background: 'rgba(255,255,255,0.25)',
        borderRadius: 10,
        padding: 20,
        marginTop: 20
    },
    errorText: {
        color: 'white',
        fontSize: '25px'
    },
    rulesLink: {
        position: 'relative',
        marginTop: 30,
    }
}))

export default function Register({classes}) {
    classes = useStyles();
    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
        type: "admin",
        nickname: '',
        phone: '',
        succes: "", //succes message
        error: ""
    });

    //redux
    const dispacth = useDispatch();
    const user = useSelector(state => state.userState.user);

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleRegister = (e) => {
        e.preventDefault();
        setValues({...values, loading: !values.loading})
        
        const { email, password, nickname, phone } = values;
        firebase.firebase.auth().createUserWithEmailAndPassword(email, password)
        .then((result) => {
            firebase.db.collection("users").doc(firebase.firebase.auth().currentUser.uid).set({
                email: email,
                type: "user",
                nickname: nickname,
                phone: phone,
                hasSelected: false,
                hasPaid: false,
                paidTeams: 0,
                uid: firebase.firebase.auth().currentUser.uid
            })
            setValues({...values, succes: "Se agregó exitosamente", error: ""})
            
            //redirect to dashboard
            dispacth(setUser({
                email: email,
                type: "user",
                id: result.user.uid,
                nickname: nickname,
                hasSelected: false,
                hasPaid: false,
                paidTeams: 0,
                uid: firebase.firebase.auth().currentUser.uid
            }));
            
        })
        .catch((error) => {
            setValues({...values, succes: "", error: error.message})
        })

    }

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.overlay}>
                {user && (
                    <Redirect to="/home"/>
                )}
                <Logos/>
                <div className={classes.infoContainer}>
                    <div className={classes.formContainer}>
                        <h1 className={classes.title}>Participa en la quiniela {title} {year}</h1>
                        <div style={{display: 'flex'}}>
                            <input 
                                type="email" 
                                name='email'
                                placeholder="Email"
                                value={values.email}
                                onChange={(e) => setValues({...values, email: e.target.value})}
                                className={classes.userInput}
                            />
                        </div>

                        <div style={{display: 'flex'}}>
                            <input 
                                type="text" 
                                name='name'
                                placeholder="Apodo/Nombre"
                                value={values.nickname}
                                onChange={(e) => setValues({...values, nickname: e.target.value})}
                                className={classes.userInput}
                            />
                        </div>

                        <div style={{display: 'flex'}}>
                            <input 
                                type="text" 
                                name='cellphone'
                                placeholder="Teléfono"
                                value={values.phone}
                                onChange={(e) => setValues({...values, phone: e.target.value})}
                                className={classes.userInput}
                            />
                        </div>
                        
                        <div className={classes.passwordContainer}>
                            <input 
                                type={values.showPassword ? 'text' : 'password'}
                                placeholder="Contraseña"
                                name='password'
                                value={values.password}
                                onChange={(e) => setValues({...values, password: e.target.value})}
                                className={classes.passwordInput}
                            />
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {values.showPassword ? <Visibility style={{color: 'white'}}/> : <VisibilityOff style={{color: 'white'}}/>}
                            </IconButton>
                        </div>
                        <div>
                            <WhiteButton onClick={handleRegister}>Participar</WhiteButton>
                            <div style={{marginTop: '20px'}}>
                                <Link to="/" className={classes.link}>¿Ya tienes una cuenta? <u>Inicia sesión</u></Link>
                            </div>
                            {values.error !== "" && (
                                <div className={classes.errorContainer}>
                                    <h2 className={classes.errorText}>{values.error}</h2>
                                </div>
                            )}
                            {values.succes !== "" && (
                                <div className={classes.errorContainer}>
                                    <h2 className={classes.errorText}>{values.succes}</h2>
                                </div>
                            )}
                            <div className={classes.rulesLink}>
                                <Link to="/rules" className={classes.link}><u>Ver reglamento</u></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";



const WhiteButton = withStyles((theme) => ({
  root: {
    color: "rgb(20,20,20)",
    backgroundColor: "rgb(255, 255, 255)",
    borderRadius: "45px",
    transition: "transform 450ms",
    fontWeight: "bolder",
    padding: "15px 20px",
    marginBottom: '20px',
    position: 'relative',
    width: "100%",
    top: "10px",
    
    '&:hover': {
      backgroundColor: "rgb(245, 245, 255)",
      transform: "scale(1.02)",
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: "rgb(255, 255, 255)",
      border: 'none',
    },
    '&:focus': {
      backgroundColor: "rgb(255, 255, 255)",
    },
  },
}))(Button);

const TransparentButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "rgba(255, 255, 255,0)",
    borderRadius: "45px",
    border: '1px solid white',
    transition: "transform 450ms",
    fontWeight: "bolder",
    fontSize: '12px',
    padding: "5px 10px",
    marginBottom: '20px',
    position: 'relative',
    width: "100%",
    top: "10px",
    [theme.breakpoints.up(768)]: {
      padding: "10px 20px",
    },
    '&:hover': {
      backgroundColor: "rgba(255, 255, 255,1)",
      transform: "scale(1.02)",
      color: 'rgb(20,20,20)',
      //border: 'none'
    },
  },
}))(Button);

export { WhiteButton, TransparentButton}
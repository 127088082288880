import { combineReducers } from "redux"

import user from "./user/reducer"
import users from "./users/reducer"
import leaderboard from "./leaderboard/reducer"
import players from "./players/reducer"

const rootReducer = combineReducers({
    userState: user,
    usersState: users,
    leaderboardState: leaderboard,
    playersState: players
})

export default rootReducer
const points_pos = [
50,
30,
19,
13.5,
11,
10,
9,
8.5,
8,
7.5,
6.5,
6,
5.7,
5.5,
5.3,
5.1,
4.9,
4.7,
4.5,
4.3,
4.1,
3.9,
3.7,
3.55,
3.4,
3.25,
3.1,
2.95,
2.8,
2.65,
2.5,
2.35,
2.2,
2.1,
2,
1.9,
1.8,
1.7,
1.6,
1.5,
1.4,
1.3,
1.2,
1.1,
1.05,
1,
0.95,
0.9,
0.85,
0.8,
0.75,
0.7,
0.65,
0.6,
0.58,
0.56,
0.54,
0.52,
0.5,
0.48,
0.46,
0.44,
0.42,
0.4,
0.38,
0.36,
0.34,
0.32,
0.3,
0.29,
0.28,
0.27,
0.26,
0.25,
0.24,
0.23,
0.22,
0.21,
0.2,
0.19,
0.18,
0.17,
0.16,
0.15,
0.14,
0.13,
0.12,
0.11,
0.1,
0.09]

export default points_pos
//Firebase
import { SET_PLAYERS } from "./actionTypes";

export const setReduxPlayers = (players) => {
    localStorage.setItem("players", JSON.stringify(players));
    return {
        type: SET_PLAYERS,
        data: players
    }
}

/* Fedex St. Jude - TPC Southwind */
/* const course = [
    {
        num: 1,
        par: 4
    },
    {
        num: 2,
        par: 4
    },
    {
        num: 3,
        par: 5
    },
    {
        num: 4,
        par: 3
    },
    {
        num: 5,
        par: 4
    },
    {
        num: 6,
        par: 4
    },
    {
        num: 7,
        par: 4
    },
    {
        num: 8,
        par: 3
    },
    {
        num: 9,
        par: 4
    },
    {
        num: 10,
        par: 4
    },
    {
        num: 11,
        par: 3
    },
    {
        num: 12,
        par: 4
    },
    {
        num: 13,
        par: 4
    },
    {
        num: 14,
        par: 3
    },
    {
        num: 15,
        par: 4
    },
    {
        num: 16,
        par: 5
    },
    {
        num: 17,
        par: 4
    },
    {
        num: 18,
        par: 4
    },
] */

/* <-----The Open------> Royal Liverpool Course */
/* const course = [
    {
        num: 1,
        par: 4
    },
    {
        num: 2,
        par: 4
    },
    {
        num: 3,
        par: 4
    },
    {
        num: 4,
        par: 4
    },
    {
        num: 5,
        par: 5
    },
    {
        num: 6,
        par: 3
    },
    {
        num: 7,
        par: 4
    },
    {
        num: 8,
        par: 4
    },
    {
        num: 9,
        par: 3
    },
    {
        num: 10,
        par: 4
    },
    {
        num: 11,
        par: 4
    },
    {
        num: 12,
        par: 4
    },
    {
        num: 13,
        par: 3
    },
    {
        num: 14,
        par: 4
    },
    {
        num: 15,
        par: 5
    },
    {
        num: 16,
        par: 4
    },
    {
        num: 17,
        par: 3
    },
    {
        num: 18,
        par: 5
    },
] */

/*<-----Masters----->*/
/* const course = [
    {
        num: 1,
        par: 4
    },
    {
        num: 2,
        par: 5
    },
    {
        num: 3,
        par: 4
    },
    {
        num: 4,
        par: 3
    },
    {
        num: 5,
        par: 4
    },
    {
        num: 6,
        par: 3
    },
    {
        num: 7,
        par: 4
    },
    {
        num: 8,
        par: 5
    },
    {
        num: 9,
        par: 4
    },
    {
        num: 10,
        par: 4
    },
    {
        num: 11,
        par: 4
    },
    {
        num: 12,
        par: 3
    },
    {
        num: 13,
        par: 5
    },
    {
        num: 14,
        par: 4
    },
    {
        num: 15,
        par: 5
    },
    {
        num: 16,
        par: 3
    },
    {
        num: 17,
        par: 4
    },
    {
        num: 18,
        par: 4
    },
] */

/* <------the players-----> */
/* const course = [
    {
        num: 1,
        par: 4
    },
    {
        num: 2,
        par: 5
    },
    {
        num: 3,
        par: 3
    },
    {
        num: 4,
        par: 4
    },
    {
        num: 5,
        par: 4
    },
    {
        num: 6,
        par: 4
    },
    {
        num: 7,
        par: 4
    },
    {
        num: 8,
        par: 3
    },
    {
        num: 9,
        par: 5
    },
    {
        num: 10,
        par: 4
    },
    {
        num: 11,
        par: 5
    },
    {
        num: 12,
        par: 4
    },
    {
        num: 13,
        par: 3
    },
    {
        num: 14,
        par: 4
    },
    {
        num: 15,
        par: 4
    },
    {
        num: 16,
        par: 5
    },
    {
        num: 17,
        par: 3
    },
    {
        num: 18,
        par: 4
    },
] */

/* PGA Championship - Valhalla */
/* const course = [
    {
        num: 1,
        par: 4
    },
    {
        num: 2,
        par: 4
    },
    {
        num: 3,
        par: 3
    },
    {
        num: 4,
        par: 4
    },
    {
        num: 5,
        par: 4
    },
    {
        num: 6,
        par: 4
    },
    {
        num: 7,
        par: 5
    },
    {
        num: 8,
        par: 3
    },
    {
        num: 9,
        par: 4
    },
    {
        num: 10,
        par: 5
    },
    {
        num: 11,
        par: 3
    },
    {
        num: 12,
        par: 4
    },
    {
        num: 13,
        par: 4
    },
    {
        num: 14,
        par: 3
    },
    {
        num: 15,
        par: 4
    },
    {
        num: 16,
        par: 4
    },
    {
        num: 17,
        par: 4
    },
    {
        num: 18,
        par: 5
    },
] */


/* US Open - Pinehurst (Course No. 2) */
/* const course = [
    {
        num: 1,
        par: 4
    },
    {
        num: 2,
        par: 4
    },
    {
        num: 3,
        par: 4
    },
    {
        num: 4,
        par: 4
    },
    {
        num: 5,
        par: 5
    },
    {
        num: 6,
        par: 3
    },
    {
        num: 7,
        par: 4
    },
    {
        num: 8,
        par: 4
    },
    {
        num: 9,
        par: 3
    },
    {
        num: 10,
        par: 5
    },
    {
        num: 11,
        par: 4
    },
    {
        num: 12,
        par: 4
    },
    {
        num: 13,
        par: 4
    },
    {
        num: 14,
        par: 4
    },
    {
        num: 15,
        par: 3
    },
    {
        num: 16,
        par: 4
    },
    {
        num: 17,
        par: 3
    },
    {
        num: 18,
        par: 4
    },
] */


/* The Open - Royal Troon */
/* const course = [
    {
        num: 1,
        par: 4
    },
    {
        num: 2,
        par: 4
    },
    {
        num: 3,
        par: 4
    },
    {
        num: 4,
        par: 5
    },
    {
        num: 5,
        par: 3
    },
    {
        num: 6,
        par: 5
    },
    {
        num: 7,
        par: 4
    },
    {
        num: 8,
        par: 3
    },
    {
        num: 9,
        par: 4
    },
    {
        num: 10,
        par: 4
    },
    {
        num: 11,
        par: 4
    },
    {
        num: 12,
        par: 4
    },
    {
        num: 13,
        par: 4
    },
    {
        num: 14,
        par: 3
    },
    {
        num: 15,
        par: 4
    },
    {
        num: 16,
        par: 5
    },
    {
        num: 17,
        par: 3
    },
    {
        num: 18,
        par: 4
    },
] */

/* Golf Naional (France) - Olympics 2024 */
const course = [
    {
        num: 1,
        par: 4
    },
    {
        num: 2,
        par: 3
    },
    {
        num: 3,
        par: 5
    },
    {
        num: 4,
        par: 4
    },
    {
        num: 5,
        par: 4
    },
    {
        num: 6,
        par: 4
    },
    {
        num: 7,
        par: 4
    },
    {
        num: 8,
        par: 3
    },
    {
        num: 9,
        par: 5
    },
    {
        num: 10,
        par: 4
    },
    {
        num: 11,
        par: 3
    },
    {
        num: 12,
        par: 4
    },
    {
        num: 13,
        par: 4
    },
    {
        num: 14,
        par: 5
    },
    {
        num: 15,
        par: 4
    },
    {
        num: 16,
        par: 3
    },
    {
        num: 17,
        par: 4
    },
    {
        num: 18,
        par: 4
    },
]

export default course
//redux
import { useSelector } from 'react-redux';
//router
import { Redirect, Link } from 'react-router-dom';

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";

//components
import { WhiteButton } from '../../utils/Buttons';
import Logos from '../../components/user/Logos';

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        overflowX: 'hidden'
    },
    overlay: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 30%, #0e1d1deb 53%, #0e1d1de8 100%)',
        paddingTop: 30,
        paddingBottom: 50,
        textAlign: 'center',
        color: 'white',
    },

    /* welcome */
    welcome__container: {
        width: '98%',
        maxWidth: '1200px',
        textAlign: 'center',
        margin: 'auto',
    },
    welcome: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: 30
    },


    /* action buttons */
    actionButtons: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: 20,
        marginTop: 50,
        width: '70%',
        maxWidth: '600px',
        margin: 'auto',
        marginBottom: 50,
    },
    link: {
        textDecoration: 'none'
    }
}))


export default function NotFound({classes}) {
    classes = useStyles();
    
    //redux
    const user = useSelector(state => state.userState.user);

    return (
        <>
            <div className={classes.backgroundContainer}>
                <div className={classes.overlay}>
                    {/* welcome message */}
                    <div className={classes.welcome__container}>
                        <Logos/>
                        {user ? (
                            <h2 className={classes.welcome}>Hola {user && user.nickname}, no se encontró la página.</h2> 
                        ) : (
                            <h2 className={classes.welcome}>Hola, no se encontró la página que buscabas.</h2> 
                        )}
                        
                        {/* action buttons */}
                        <div className={classes.actionButtons}>
                            {user ? (
                                <Link to={user.type === "admin" ? "/admin" : "/home"} className={classes.link}><WhiteButton>Regresar</WhiteButton></Link>
                            ) : (
                                <Link to="/sign-up" className={classes.link}><WhiteButton>Participa</WhiteButton></Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            
        </>
    )
}

const memberType = [
    {
        title: "Capitán",
        per: "50%",
        sum: 0.5,
        mult: 1.5
    },
    {
        title: "Subcapitán",
        per: "40%",
        sum: 0.4,
        mult: 1.4
    },
    {
        title: "Jugador 3",
        per: "30%",
        sum: 0.3,
        mult: 1.3
    },
    {
        title: "Jugador 4",
        per: "20%",
        sum: 0.2,
        mult: 1.2
    },
    {
        title: "Jugador 5",
        per: "10%",
        sum: 0.1,
        mult: 1.1
    },
    {
        title: "Jugador 6",
        per: "0%",
        sum: 0,
        mult: 1
    },
]

export default memberType
const players = [
    /* {
        name: "Aaron Wise",
        img: "./img/players/aaron_wise.png"
    }, */
    /* {
        name: "Aaron Rai",
        img: "./img/players/aaron_rai.png"
    }, */
    {
        name: "Abraham Ancer",
        img: "./img/players/abraham_ancer.png"
    },
    /* {
        name: "Adam Hadwin",
        img: "./img/players/adam_hadwin.png"
    },
    {
        name: "Adam Schenk",
        img: "./img/players/adam_schenk.png"
    }, 
    {
        name: "Adam Scott",
        img: "./img/players/adam_scott.png"
    }, */
    /* {
        name: "Adam Svensson",
        img: "./img/players/adam_svensson.png"
    }, */
    /* {
        name: "Adri Arnaus",
        img: "./img/players/adri_arnaus.png"
    },*/
    {
        name: "Adrian Meronk",
        img: "./img/players/adrian_meronk.png"  
    },
    {
        name: "Adrien Dumont",
        img: "./img/players/adrien_dumont.png"
    },
    /* {
        name: "Adrian Otaegui",
        img: "./img/players/adrian_otaegui.png"  
    },*/
    {
        name: "Alejandro Tosti",
        img: "./img/players/alejandro_tosti.png"
    },
    /*{
        name: "Alejandro Cañizares",
        img: "./img/players/alejandro_canizares.png"
    },
    {
        name: "Alex Fitzpatrick",
        img: "./img/players/alex_fitzpatrick.png"
    },
    {
        name: "Alex Maguire (a)",
        img: "./img/players/alex_maguire.png"
    },*/
    /* {
        name: "Akshay Bhatia",
        img: "./img/players/akshay_bhatia.png"  
    },
    {
        name: "Alex Cejka",
        img: "./img/players/alex_cejka.png"
    }, */
    {
        name: "Alex Noren",
        img: "./img/players/alex_noren.png"
    }, 
    /* {
        name: "Alex Smalley",
        img: "./img/players/alex_smalley.png"
    }, */ 
    /* {
        name: "Alexander Bjork",
        img: "./img/players/alexander_bjork.png"
    }, 
    {
        name: "Altin Van Der Merwe (a)",
        img: "./img/players/altin_van_der_merwe.png"
    }, */
    /* {
        name: "Aldrich Potgieter",
        img: "./img/players/aldrich_potgieter.png"
    },
    {
        name: "Alejandro Del Rey",
        img: "./img/players/alejandro_del_rey.png"
    }, */
    /* 
    {
        name: "Alex Schaake",
        img: "./img/players/alex_schaake.png"
    },
    {
        name: "Alexander Yang",
        img: "./img/players/alexander_yang.png"
    },*/
    /* {
        name: "Andrew Putnam",
        img: "./img/players/andrew_putnam.png"
    }, */
    /*{
        name: "Antoine Rozner",
        img: "./img/players/antoine_rozner.png"
    },*/
    /* {
        name: "Andrew Svoboda",
        img: "./img/players/andrew_svoboda.png"
    }, */
    /*{
        name: "Angel Cabrera",
        img: "./img/players/angel_cabrera.png"
    }, 
     {
        name: "Austen Truslow",
        img: "./img/players/austen_truslow.png"
    }, */
    /* {
        name: "Andy Ogletree",
        img: "./img/players/andy_ogletree.png"
    }, */
    /* {
        name: "Ashton McCulloch",
        img: "./img/players/ashton_mcculloch.png"
    }, */
    /* {
        name: "Angel Hidalgo",
        img: "./img/players/angel_hidalgo.png"
    },
    {
        name: "Austin Eckroat",
        img: "./img/players/austin_eckroat.png"
    }, */
    /*{
        name: "Barclay Brown",
        img: "./img/players/barclay_brown.png"
    },
    {
        name: "Ben Carr",
        img: "./img/players/ben_carr.png"
    },
    {
        name: "Berry Henson",
        img: "./img/players/berry_henson.png"
    }, */
    /* {
        name: "Beau Hossler",
        img: "./img/players/beau_hossler.png"
    }, */
    /* {
        name: "Ben Crenshaw",
        img: "./img/players/ben_crenshaw.png"
    },
    {
        name: "Bernhard Langer",
        img: "./img/players/bernhard_langer.png"
    },*/
    /* {
        name: "Ben Curtis",
        img: "./img/players/ben_curtis.png"
    }, */
    /* {
        name: "Ben Griffin",
        img: "./img/players/ben_griffin.png"
    }, */
    /* {
        name: "Ben Polland",
        img: "./img/players/ben_polland.png"
    }, */
    /* {
        name: "Ben Kohles",
        img: "./img/players/ben_kohles.png"
    },
    {
        name: "Benjamin James (a)",
        img: "./img/players/benjamin_james.png"
    }, */
    /* {
        name: "Billy Horschel",
        img: "./img/players/billy_horschel.png"
    }, */
    /*{
        name: "Bio Kim",
        img: "./img/players/bio_kim.png"
    },*/
    /* {
        name: "Brendan Valdes (a)",
        img: "./img/players/brendan_valdes.png"
    }, */
    /*{
        name: "Brent Grant",
        img: "./img/players/brent_grant.png"
    }, 
    {
        name: "Branden Grace",
        img: "./img/players/branden_grace.png"
    },
    {
        name: "Brandon Robinson",
        img: "./img/players/brandon_robinson.png"
    },*/
    /* {
        name: "Brandon Robinson Thompson",
        img: "./img/players/brandon_robinson_thompson.png"
    }, */
    /* {
        name: "Brad Marek",
        img: "./img/players/brad_marek.png"
    },
    {
        name: "Braden Shattuck",
        img: "./img/players/braden_shattuck.png"
    }, */
    /* {
        name: "Brandon Wu",
        img: "./img/players/brandon_wu.png"
    }, */
    /* {
        name: "Brendon Todd",
        img: "./img/players/brendon_todd.png"
    }, */
    /* {
        name: "Brian Campbell",
        img: "./img/players/brian_campbell.png"
    }, */
    /* {
        name: "Brian Harman",
        img: "./img/players/brian_harman.png"
    }, */
    /* {
        name: "Brice Garnett",
        img: "./img/players/brice_garnett.png"
    }, */
    /* {
        name: "Brooks Koepka",
        img: "./img/players/brooks_koepka.png"
    }, */
    /* {
        name: "Bryan Kim (a)",
        img: "./img/players/bryan_kim.png"
    }, */
    /* {
        name: "Bryson DeChambeau",
        img: "./img/players/bryson_dechambeau.png"
    }, */
    /* {
        name: "Bubba Watson",
        img: "./img/players/bubba_watson.png"
    },*/
    {
        name: "Byeong Hun An",
        img: "./img/players/byeong_hun_an.png"
    }, 
    /* {
        name: "Callum Shinkwin",
        img: "./img/players/callum_shinkwin.png"
    }, */
    /* {
        name: "Cam Davis",
        img: "./img/players/cam_davis.png"
    }, */
    /* {
        name: "Calum Scott (a)",
        img: "./img/players/calum_scott.png"
    },
    {
        name: "Cameron Smith",
        img: "./img/players/cameron_smith.png"
    },
    {
        name: "Cameron Young",
        img: "./img/players/cameron_young.png"
    }, */
    {
        name: "Camillo Villegas",
        img: "./img/players/camillo_villegas.png"
    },
    {
        name: "Carl Yuan",
        img: "./img/players/carl_yuan.png"
    },
    {
        name: "Carlos Ortiz",
        img: "./img/players/carlos_ortiz.png"
    },
    /* {
        name: "Carson Young",
        img: "./img/players/carson_young.png"
    }, */
    /* {
        name: "Carson Schaake",
        img: "./img/players/carson_schaake.png"
    }, 
    {
        name: "Carter Jenkins",
        img: "./img/players/carson_jenkins.png"
    },
    {
        name: "Casey Jarvis",
        img: "./img/players/carsey_jarvis.png"
    },
    {
        name: "Charles Reiter",
        img: "./img/players/chales_reiter.png"
    },
    {
        name: "Chesson Hadley",
        img: "./img/players/chesson_hadley.png"
    }, */
    /* {
        name: "Charley Hoffman",
        img: "./img/players/charley_hoffman.png"
    }, */
    /* {
        name: "Charl Schwartzel",
        img: "./img/players/charl_schwartzel.png"
    },*/
    /* {
        name: "Charlie Lindh",
        img: "./img/players/charlie_lindh.png"
    }, */
    {
        name: "Cheng-Tsung Pan",
        img: "./img/players/cheng_tsung_pan.png"
    },
    /* {
        name: "Chris Kirk",
        img: "./img/players/chris_kirk.png"
    }, */
    /* {
        name: "Chris Naegel",
        img: "./img/players/chris_naegel.png"
    },
    {
        name: "Chris Petefish",
        img: "./img/players/chris_petefish.png"
    }, */
    /* {
        name: "Chris Gotterup",
        img: "./img/players/chris_gotterup.png"
    }, */
    {
        name: "Christiaan Bezuidenhout",
        img: "./img/players/christiaan_bezuidenhout.png"
    },
    {
        name: "Chun-An Yu",
        img: "./img/players/chun_an_yu.png"
    },
    /*{
        name: "Christo Lamprecht (a)",
        img: "./img/players/christo_lamprecht.png"
    },
     {
        name: "Christian Cavaliere",
        img: "./img/players/christian_cavaliere.png"
    }, */
    /* {
        name: "Colin Prater (a)",
        img: "./img/players/colin_prater.png"
    }, */
    {
        name: "Collin Morikawa",
        img: "./img/players/collin_morikawa.png"
    },
    /*{
        name: "Connor McKinney",
        img: "./img/players/connor_mckinney.png"
    },
    {
        name: "Connor Syme",
        img: "./img/players/connor_syme.png"
    },*/
    {
        name: "Corey Conners",
        img: "./img/players/corey_conners.png"
    },
    /* {
        name: "C. T. Pan",
        img: "./img/players/ct_pan.png"
    }, */
    /*{
        name: "Corey Pereira",
        img: "./img/players/corey_pereira.png"
    },*/
    /* {
        name: "Dan Bradbury",
        img: "./img/players/dan_bradbury.png"
    }, */
    {
        name: "Daniel Hillier",
        img: "./img/players/daniel_hillier.png"
    },
    /*{
        name: "Danny Willett",
        img: "./img/players/danny_willett.png"
    },*/
    /* {
        name: "Daniel Berger",
        img: "./img/players/daniel_berger.png"
    }, */
    /* {
        name: "Daniel Brown",
        img: "./img/players/daniel_brown.png"
    },
    {
        name: "Darren Clarke",
        img: "./img/players/darren_clarke.png"
    },
    {
        name: "Darren Fichardt",
        img: "./img/players/darren_fichardt.png"
    }, */
    /*{
        name: "David Duval",
        img: "./img/players/david_duval.png"
    },
    {
        name: "David Lingmerth",
        img: "./img/players/david_lingmerth.png"
    },
    {
        name: "David Micheluzzi",
        img: "./img/players/david_micheluzzi.png"
    },
    {
        name: "Davis Riley",
        img: "./img/players/davis_riley.png"
    },
    {
        name: "David Horsey",
        img: "./img/players/david_horsey.png"
    },
    {
        name: "David Nyfjall",
        img: "./img/players/david_nyfjall.png"
    },*/
    {
        name: "David Puig",
        img: "./img/players/david_puig.png"
    },
    /* {
        name: "Davis Thompson",
        img: "./img/players/davis_thompson.png"
    },
    {
        name: "Denny McCarthy",
        img: "./img/players/denny_mccarthy.png"
    }, */
    /*{
        name: "Deon Germishuys",
        img: "./img/players/deon_germishuys.png"
    }, */
    /* {
        name: "Dean Burmester",
        img: "./img/players/dean_burmester.png"
    },
    {
        name: "Denwit Boriboonsub",
        img: "./img/players/denwit_boriboonsub.png"
    }, */
    /* {
        name: "Doug Ghim",
        img: "./img/players/doug_ghim.png"
    }, */
    /* {
        name: "Dominic Clemons",
        img: "./img/players/dominic_clemons.png"
    }, */
    /* {
        name: "Dustin Johnson",
        img: "./img/players/dustin_johnson.png"
    }, */
    /*{
        name: "Dylan Wu",
        img: "./img/players/dylan_wu.png"
    },*/
    /* {
        name: "Edoardo Molinari",
        img: "./img/players/edoardo_molinari.png"
    }, */
    /* {
        name: "Elvis Smylie",
        img: "./img/players/elvis_smylie.png"
    }, */
    {
        name: "Emiliano Grillo",
        img: "./img/players/emiliano_grillo.png"
    },
    /* {
        name: "Eric Cole",
        img: "./img/players/eric_cole.png"
    }, */
    {
        name: "Erik van Rooyen",
        img: "./img/players/erik_van_rooyen.png"
    },
    /* {
        name: "Eugenio Lopez-Chacarra",
        img: "./img/players/eugenio_lopez_chacarra.png"
    }, */
    /* {
        name: "Evan Bowser",
        img: "./img/players/evan_bowser.png"
    }, */
    /* {
        name: "Ernie Els",
        img: "./img/players/ernie_els.png"
    },
    {
        name: "Ewen Ferguson",
        img: "./img/players/ewen_ferguson.png"
    },
    {
        name: "Franceso Molinari",
        img: "./img/players/francesco_molinari.png"
    }, */
     {
        name: "Fabrizio Zanotti",
        img: "./img/players/fabrizio_zanotti.png"
    },
    /* {
        name: "Frankie Capan III",
        img: "./img/players/frankie_capan.png"
    }, 
    {
        name: "Frederik Kjettrup",
        img: "./img/players/frderik_kjettrup.png"
    }, */
    /* {
        name: "Fred Couples",
        img: "./img/players/fred_couples.png"
    },*/
    {
        name: "Gaganjeet Bhullar",
        img: "./img/players/gaganjeet_bhullar.png"
    },
    /* {
        name: "Gary Woodland",
        img: "./img/players/gary_woodland.png"
    }, */
    /*{
        name: "Graeme_Robertson",
        img: "./img/players/graeme_robertson.png"
    },*/
    /* {
        name: "Grayson Murray",
        img: "./img/players/grayson_murray.png"
    }, */
    {
        name: "Guido Migliozzi",
        img: "./img/players/guido_migliozzi.png"
    },
    /*{
        name: "Gunner Wiebe",
        img: "./img/players/gunner_wiebe.png"
    },*/
    /* {
        name: "Gordon Sargent (a)",
        img: "./img/players/gordon_sargent.png"
    },
    {
        name: "Gun-Taek Koh",
        img: "./img/players/gun_taek_koh.png"
    }, */
    /* {
        name: "Grant Forrest",
        img: "./img/players/grant_forrest.png"
    }, */
    /* {
        name: "Greyson Sigg",
        img: "./img/players/greyson_sigg.png"
    },
    {
        name: "Gunnar Broin (a)",
        img: "./img/players/grunn_broin.png"
    }, */
    /*{
        name: "Gunn Charoenkul",
        img: "./img/players/gunn_charoenkul.png"
    },
    {
        name: "Hank Lebioda",
        img: "./img/players/hank_lebioda.png"
    },*/ 
    /* {
        name: "Harris English",
        img: "./img/players/harris_english.png"
    }, */
    /* {
        name: "Harry Higgs",
        img: "./img/players/harry_higgs.png"
    }, */
    /*{
        name: "Harrison Crowe (a)",
        img: "./img/players/harrison_crowe.png"
    },
    {
        name: "Haydn Barron",
        img: "./img/players/haydn_barron.png"
    }, 
    {
        name: "Hayden Buckley",
        img: "./img/players/hayden_buckley.png"
    },*/
    /* {
        name: "Henrik Stenson",
        img: "./img/players/henrik_stenson.png"
    }, */
    {
        name: "Hideki Matsuyama",
        img: "./img/players/hideki_matsuyama.png"
    },
    /* {
        name: "Hiroshi Tai (a)",
        img: "./img/players/hiroshi_tai.png"
    },
    {
        name: "Isaiah Salinda",
        img: "./img/players/isaiah_salinda.png"
    }, */
    /* {
        name: "Hurly Long",
        img: "./img/players/hurly_long.png"
    },
    {
        name: "Isaac Simmons",
        img: "./img/players/isaac_simmons.png"
    },
    {
        name: "J. J. Grey",
        img: "./img/players/jj_grey.png"
    }, 
    {
        name: "J. J. Spaun",
        img: "./img/players/jj_spaun.png"
    }, */
    /* {
        name: "J. T. Poston",
        img: "./img/players/jt_poston.png"
    }, */
    /* {
        name: "Jacob Solomon",
        img: "./img/players/jacob_solomon.png"
    }, */
    /* {
        name: "Jacob Olesen",
        img: "./img/players/jacob_olesen.png"
    },
    {
        name: "Jack McDonald",
        img: "./img/players/jack_mcdonald.png"
    },
    {
        name: "Jaime Montojo (a)",
        img: "./img/players/jaime_montojo.png"
    }, */
    /* {
        name: "Jackson Buchanan (a)",
        img: "./img/players/jackson_buchanan.png"
    },
    {
        name: "Jake Knapp",
        img: "./img/players/jake_knapp.png"
    }, */
    /* {
        name: "Jared Jones",
        img: "./img/players/jared_jones.png"
    }, */
    {
        name: "Jason Day",
        img: "./img/players/jason_day.png"
    },
    /* {
        name: "Jason Scrivener",
        img: "./img/players/jason_scrivener.png"
    }, */
    /* {
        name: "Jason Dufner",
        img: "./img/players/jason_dufner.png"
    }, */
    /* {
        name: "Jasper Stubbs (a)",
        img: "./img/players/jasper_stubbs.png"
    }, */
    /* {
        name: "Jazz Janewattananond",
        img: "./img/players/jazz_janewattananond.png"
    },
    {
        name: "Jens Dantorp",
        img: "./img/players/jens_dantorp.png"
    }, */
    /* {
        name: "Jeff Kellen",
        img: "./img/players/jeff_kellen.png"
    },
    {
        name: "Jeremy Wells",
        img: "./img/players/jeremy_wells.png"
    },
    {
        name: "Jesse Mueller",
        img: "./img/players/jesse_mueller.png"
    },*/
    /* {
        name: "Jesper Svensson",
        img: "./img/players/jesper_svensson.png"
    },
    {
        name: "Jeung-Hun Wang",
        img: "./img/players/jeung_hun_wang.png"
    }, */
    /*{
        name: "Jimmy Walker",
        img: "./img/players/jimmy_walker.png"
    }, */
    /* {
        name: "Jim Herman",
        img: "./img/players/jim_herman.png"
    }, */
    /* {
        name: "Jesse Schutte",
        img: "./img/players/jesse_schutte.png"
    }, */
    /* {
        name: "Joel Dahmen",
        img: "./img/players/joel_dahmen.png"
    },*/
    {
        name: "Joel Girrbach",
        img: "./img/players/joel_girrbach.png"
    },
    {
        name: "Joaquin Niemann",
        img: "./img/players/joaquin_niemann.png"
    }, 
    /* {
        name: "Joe Dean",
        img: "./img/players/joe_dean.png"
    },
    {
        name: "John Daly",
        img: "./img/players/john_daly.png"
    },
    {
        name: "John Catlin",
        img: "./img/players/john_catlin.png"
    }, */
    /* {
        name: "John Somers",
        img: "./img/players/john_somers.png"
    }, */
    /* {
        name: "Joey Vrzich",
        img: "./img/players/joey_vrzich.png"
    },
    {
        name: "John Chin",
        img: "./img/players/john_chin.png"
    }, */
    {
        name: "Jon Rahm",
        img: "./img/players/jon_rahm.png"
    },
    /* {
        name: "Jordan Gumberg",
        img: "./img/players/jordan_gumberg.png"
    },*/ 
    /* {
        name: "Joost Luiten",
        img: "./img/players/joost_luiten.png"
    }, */
    {
        name: "Joohyung Kim",
        img: "./img/players/joohyung_kim.png"
    },
    /* {
        name: "Jordan Smith",
        img: "./img/players/jordan_smith.png"
    },
    {
        name: "Jordan Spieth",
        img: "./img/players/jordan_spieth.png"
    }, */
    /* {
        name: "Josh Bevell",
        img: "./img/players/josh_bevell.png"
    },
    {
        name: "Josh Speight",
        img: "./img/players/josh_speight.png"
    }, */
    /* {
        name: "Jorge Campillo",
        img: "./img/players/jorge_campillo.png"
    }, */
    /* {
        name: "Jose Luis Ballester Barrio (a)",
        img: "./img/players/jose_luis_ballester_barrio.png"
    },*/
    /* {
        name: "Jose María Olazábal",
        img: "./img/players/jose_maria_olazabal.png"
    }, */
    /* {
        name: "Justin Leonard",
        img: "./img/players/justin_leonard.png"
    }, */
    /* {
        name: "Justin Lower",
        img: "./img/players/justin_lower.png"
    }, */
    /* {
        name: "Justin Rose",
        img: "./img/players/justin_rose.png"
    }, */
    /* {
        name: "Justin Suh",
        img: "./img/players/justin_suh.png"
    },*/
    /* {
        name: "Justin Thomas",
        img: "./img/players/justin_thomas.png"
    }, */
    /*{
        name: "Karl Vilips",
        img: "./img/players/karl_vilips.png"
    }, 
    {
        name: "Kalle Samooja",
        img: "./img/players/kalle_samooja.png"
    },
    {
        name: "Kazuki Higa",
        img: "./img/players/kazuki_higa.png"
    },
    {
        name: "Kazuki Yasumori",
        img: "./img/players/kazuki_yasumori.png"
    },*/
    /* {
        name: "Kazuma Kobori",
        img: "./img/players/kazuma_kobori.png"
    },
    {
        name: "Keegan Bradley",
        img: "./img/players/keegan_bradley.png"
    }, */
    {
        name: "Keita Nakajima",
        img: "./img/players/keita_nakajima.png"
    },
    /* {
        name: "Kensei Hirata",
        img: "./img/players/kensei_hirata.png"
    }, */
    /* {
        name: "Keith Mitchell",
        img: "./img/players/keith_mitchell.png"
    },  */
    /*{
        name: "Kevin Streelman",
        img: "./img/players/kevin_streelman.png"
    },*/
    {
        name: "Kiradech Aphibarnrat",
        img: "./img/players/kiradech_aphibarnrat.png"
    },
    {
        name: "Kristoffer Ventura",
        img: "./img/players/kristoffer_ventura.png"
    },
    /* {
        name: "Kurt Kitayama",
        img: "./img/players/kurt_kitayama.png"
    }, */
    {
        name: "Kyle Green",
        img: "./img/players/kyle_green.png"
    },
    /* {
        name: "Kyle Mendoza",
        img: "./img/players/kyle_mendoza.png"
    }, */
    /* {
        name: "Kyle Mueller",
        img: "./img/players/kyle_mueller.png"
    }, */
    /* {
        name: "Kyle Barker",
        img: "./img/players/kyle_barker.png"
    },
    {
        name: "Kyung Nam Kang",
        img: "./img/players/kyung_nam_kang.png"
    },*/
    /* {
        name: "K.H. Lee",
        img: "./img/players/kyoung-hoon_lee.png"
    }, */
    /* {
        name: "Laurie Canter",
        img: "./img/players/laurie_canter.png"
    }, 
    {
        name: "Liam Nolan (a)",
        img: "./img/players/liam_nolan.png"
    },  */
    /* {
        name: "Larkin Gross",
        img: "./img/players/larkin_gross.png"
    },
    {
        name: "Lee Hodges",
        img: "./img/players/lee_hodges.png"
    }, */
    /* {
        name: "Logan McAllister",
        img: "./img/players/logan_mcallister.png"
    }, */
    /* {
        name: "Louis Oosthuizen",
        img: "./img/players/louis_oosthuizen.png"
    }, */
    /* {
        name: "Lucas Herbert",
        img: "./img/players/lucas_herbert.png"
    },  */
    /* {
        name: "Lucas Glover",
        img: "./img/players/lucas_glover.png"
    }, */
    {
        name: "Ludvig Åberg",
        img: "./img/players/ludvig_aberg.png"
    },
    /* {
        name: "Luis Masaveu (a)",
        img: "./img/players/luis_masaveu.png"
    }, */
    /* {
        name: "Luke Clanton (a)",
        img: "./img/players/luke_clanton.png"
    }, */
    /* {
        name: "Luke List",
        img: "./img/players/luke_list.png"
    },
    {
        name: "Luke Donald",
        img: "./img/players/luke_donald.png"
    }, */
    /* {
        name: "Mac Meissner",
        img: "./img/players/mac_meissner.png"
    }, */
    /* {
        name: "Mackenzie Hughes",
        img: "./img/players/mackenzie_hughes.png"
    }, */
    /* {
        name: "Mark Hubbard",
        img: "./img/players/mark_hubbard.png"
    },
    {
        name: "Martin Kaymer",
        img: "./img/players/martin_kaymer.png"
    }, */
    /* {
        name: "Matt Dobyns",
        img: "./img/players/matt_dobyns.png"
    },  */
    /* {
        name: "Marcel Siem",
        img: "./img/players/marcel_siem.png"
    }, */
    /*{
        name: "Marco Penge",
        img: "./img/players/marco_penge.png"
    },*/
    /*{
        name: "Martin Rohwer",
        img: "./img/players/martin_rohwer.png"
    },
    {
        name: "Mateo Fernandez (a)",
        img: "./img/players/mateo_fernandez.png"
    },
    {
        name: "Marc Warren",
        img: "./img/players/marc_warren.png"
    }, */
    /* {
        name: "Masahiro Kawamura",
        img: "./img/players/masahiro_kawamura.png"
    },
    {
        name: "Mason Andersen",
        img: "./img/players/mason_andersen.png"
    },
    {
        name: "Matthew Dodd-Berry (a)",
        img: "./img/players/matt_dodd_berry.png"
    }, */
    {
        name: "Matthew Fitzpatrick",
        img: "./img/players/matt_fitzpatrick.png"
    },
    {
        name: "Mattheiu Pavon",
        img: "./img/players/matthiew_pavon.png"
    },
    {
        name: "Matthias Schmid",
        img: "./img/players/matthias_schmid.png"
    },
    /*{
        name: "Mike Weir",
        img: "./img/players/mike_weir.png"
    },
    {
        name: "Matt NeSmith",
        img: "./img/players/matthew_nesmith.png"
    },*/
    /* {
        name: "Matt Wallace",
        img: "./img/players/matt_wallace.png"
    },
    {
        name: "Matthew Jordan",
        img: "./img/players/matthew_jordan.png"
    },
    {
        name: "Matthew Southgate",
        img: "./img/players/matthew_southgate.png"
    }, */
    /* {
        name: "Matt Kuchar",
        img: "./img/players/matt_kuchar.png"
    }, */
    {
        name: "Matteo Manassero",
        img: "./img/players/matteo_manassero.png"
    },
    {
        name: "Matthiew Pavon",
        img: "./img/players/matthiew_pavon.png"
    },
    /*{
        name: "Matthew McClean",
        img: "./img/players/matthew_mcclean.png"
    },*/
    /* {
        name: "Max Greyserman",
        img: "./img/players/max_greyserman.png"
    }, */
    /* {
        name: "Max Homa",
        img: "./img/players/max_homa.png"
    },
    {
        name: "Maverick McNealy",
        img: "./img/players/maverick_mcnealy.png"
    }, */
    /* {
        name: "Michael Block",
        img: "./img/players/michael_block.png"
    }, */
    /* {
        name: "Michael McGowan",
        img: "./img/players/michael_mcgowan.png"
    },*/
    /* {
        name: "Michael Hendry",
        img: "./img/players/michael_hendry.png"
    }, */
    {
        name: "Min Woo Lee",
        img: "./img/players/min_woo_lee.png"
    },
    /* {
        name: "Minkyu Kim",
        img: "./img/players/minkyu_kim.png"
    }, */
    /*{
        name: "Michael Brennan",
        img: "./img/players/michael_brennan.png"
    }, 
    {
        name: "Michael Thorbjornsen",
        img: "./img/players/michael_thorbjornsen.png"
    },*/
    /*{
        name: "Michael Kim",
        img: "./img/players/michael_kim.png"
    },
    {
        name: "Michael Stewart",
        img: "./img/players/michael_stewart.png"
    },*/
    /* {
        name: "Nacho Elvira",
        img: "./img/players/nacho_elvira.png"
    }, */
    /* {
        name: "Neal Shipley (a)",
        img: "./img/players/neal_shipley.png"
    },
    {
        name: "Nick Dunlap",
        img: "./img/players/nick_dunlap.png"
    }, */
    /* {
        name: "Nick Hardy",
        img: "./img/players/nick_hardy.png"
    }, */
    {
        name: "Nick Taylor",
        img: "./img/players/nick_taylor.png"
    },
    {
        name: "Nicolai Hojgaard",
        img: "./img/players/nicolai_hojgaard.png"
    },
    {
        name: "Mito Pereira",
        img: "./img/players/mito_pereira.png"
    },
    {
        name: "Nicolas Echavarria",
        img: "./img/players/nicolas_echavarria.png"
    },
    /* {
        name: "Olin Browne, Jr.",
        img: "./img/players/olin_browne.png"
    },*/
    /* {
        name: "Omar Morales (a)",
        img: "./img/players/omar_morales.png"
    },
    {
        name: "Otto Black",
        img: "./img/players/otto_black.png"
    }, */
    /*{
        name: "Ockie Strydom",
        img: "./img/players/ockie_strydom.png"
    },
    {
        name: "Oliver Farr",
        img: "./img/players/oliver_farr.png"
    },
    {
        name: "Oliver Wilson",
        img: "./img/players/oliver_wilson.png"
    },
    {
        name: "Pablo Larrazábal",
        img: "./img/players/pablo_larrazabal.png"
    },*/
    {
        name: "Pachara Khongwatmai",
        img: "./img/players/pachara_khongwatmai.png"
    },
    /* {
        name: "Pádraig Harrington",
        img: "./img/players/padraig_harrington.png"
    }, */
    /* {
        name: "Parker Bell (a)",
        img: "./img/players/parker_bell.png"
    }, */
    /*{
        name: "Paul Lawrie",
        img: "./img/players/paul_lawrie.png"
    }, */
    /* {
        name: "Patrick Cantlay",
        img: "./img/players/patrick_cantlay.png"
    }, */
    /* {
        name: "Patrick Cover",
        img: "./img/players/patrick_cover.png"
    },*/
    /* {
        name: "Patrick Reed",
        img: "./img/players/patrick_reed.png"
    }, */
    /* {
        name: "Patrick Rodgers",
        img: "./img/players/patrick_rodgers.png"
    }, */
    /*{
        name: "Paul Barjon",
        img: "./img/players/paul_barjon.png"
    }, 
    {
        name: "Paul Haley II",
        img: "./img/players/paul_haley.png"
    },*/
    /* {
        name: "Peter Malnati",
        img: "./img/players/peter_malnati.png"
    }, */
    /* {
        name: "Phil Mickelson",
        img: "./img/players/phil_mickelson.png"
    }, */
    /* {
        name: "Preston Cole",
        img: "./img/players/preston_cole.png"
    }, */
    /*{
        name: "Preston Summerhays",
        img: "./img/players/preston_summerhays.png"
    }, */
    {
        name: "Rafael Campos",
        img: "./img/players/rafael_campos.png"
    },
    /* {
        name: "Rasmus Hojgaard",
        img: "./img/players/rasmus_hojgaard.png"
    }, */
    /*{
        name: "Richard Bland",
        img: "./img/players/richard_bland.png"
    },
    {
        name: "Richie Ramsay",
        img: "./img/players/richie_ramsay.png"
    },*/
    /* {
        name: "Richard Mansell",
        img: "./img/players/ricard_mansell.png"
    },
    {
        name: "Rickie Fowler",
        img: "./img/players/rickie_fowler.png"
    }, */
    /* {
        name: "Rico Hoey",
        img: "./img/players/rico_hoey.png"
    },
    {
        name: "Riki Kawamoto",
        img: "./img/players/riki_kawamoto.png"
    }, */
    /* {
        name: "Rich Beem",
        img: "./img/players/rich_beem.png"
    }, */
    /* {
        name: "Rikuya Hoshino",
        img: "./img/players/rikuya_hoshino.png"
    }, */
    /* {
        name: "Robert Rock",
        img: "./img/players/robert_rock.png"
    }, */
    /* {
        name: "Roger Sloan",
        img: "./img/players/roger_sloan.png"
    },*/
    /* {
        name: "Romain Langasque",
        img: "./img/players/romain_langasque.png"
    },
    {
        name: "Robert MacIntyre",
        img: "./img/players/robert_mcintyre.png"
    }, */
    {
        name: "Rory McIlroy",
        img: "./img/players/rory_mcilroy.png"
    },
    /* {
        name: "Ross Fisher",
        img: "./img/players/ross_fisher.png"
    }, */
    /* {
        name: "Russell Henley",
        img: "./img/players/russell_henley.png"
    }, */
    /* {
        name: "Ryan Armour",
        img: "./img/players/ryan_armour.png"
    }, */
    {
        name: "Ryan Fox",
        img: "./img/players/ryan_fox.png"
    },
    /* {
        name: "Ryan van Velzen",
        img: "./img/players/ryan_van_velzen.png"
    }, */
    /* {
        name: "Ryan Gerard",
        img: "./img/players/ryan_gerard.png"
    },*/
    /* {
        name: "Ryo Hisatsune",
        img: "./img/players/ryo_hisatsune.png"
    },
    {
        name: "Ryo Kinoshita",
        img: "./img/players/ryo_kinoshita.png"
    }, */
    /* {
        name: "Ryo Ishikawa",
        img: "./img/players/ryo_ishikaw.png"
    }, */
    /*{
        name: "Ryutaro  Nagano",
        img: "./img/players/ryutaro_nagano.png"
    }, */
    /* {
        name: "Sahith Theegala",
        img: "./img/players/sahith_theegala.png"
    }, */
    /* {
        name: "Sam Bairstow",
        img: "./img/players/sam_bairstow.png"
    },
    {
        name: "Sam Bennet",
        img: "./img/players/sam_bennet.png"
    }, */
    /* {
        name: "Sam Ryder",
        img: "./img/players/sam_ryder.png"
    },
    {
        name: "Sam Stevens",
        img: "./img/players/sam_stevens.png"
    },*/
    /* {
        name: "Sam Burns",
        img: "./img/players/sam_burns.png"
    },
    {
        name: "Sam Horsfield",
        img: "./img/players/sam_horsfield.png"
    },
    {
        name: "Santiago de la Fuente (a)",
        img: "./img/players/santiago_de_la_fuente.png"
    }, */
    {
        name: "Sami Valimaki",
        img: "./img/players/sami_valimaki.png"
    },
    /*{
        name: "Scott Stallings",
        img: "./img/players/scott_stallings.png"
    }, */
    {
        name: "Scottie Scheffler",
        img: "./img/players/scottie_scheffler.png"
    },
    /* {
        name: "Seamus Power",
        img: "./img/players/seamus_power.png"
    }, */
    /*{
        name: "Sebastian Muñoz",
        img: "./img/players/sebastian_munoz.png"
    }, */
    /* {
        name: "Sebastian Soderberg",
        img: "./img/players/sebastian_soderberg.png"
    },
    {
        name: "Sean Crocker",
        img: "./img/players/sean_crocker.png"
    }, */
    {
        name: "Sepp Straka",
        img: "./img/players/sepp_straka.png"
    },
    /*{
        name: "Seungsu Han",
        img: "./img/players/seungsu_han.png"
    },*/
    /* {
        name: "Sergio Garcia",
        img: "./img/players/sergio_garcia.png"
    }, */
    {
        name: "Shane Lowry",
        img: "./img/players/shane_lowry.png"
    },
    /* {
        name: "Shaun Micheel",
        img: "./img/players/shaun_micheel.png"
    }, */
    {
        name: "Shubhankar Sharma",
        img: "./img/players/shubhankar_sharma.png"
    },
    /*{
        name: "Sihwan Kim",
        img: "./img/players/sihwan_kim.png"
    }, 
    {
        name: "Simon Forsström",
        img: "./img/players/simon_forsstrom.png"
    }, */
    /* {
        name: "S. H. Kim",
        img: "./img/players/sh_kim.png"
    }, */
    /* {
        name: "Si Woo Kim",
        img: "./img/players/si_woo_kim.png"
    }, */
    {
        name: "Stephan Jaeger",
        img: "./img/players/stephan_jaeger.png"
    },
    /* {
        name: "Steve Stricker",
        img: "./img/players/steve_stricker.png"
    }, */
    /* {
        name: "Stewart Cink",
        img: "./img/players/stewart_cink.png"
    }, */
    /* {
        name: "Stewart Hagestad (a)",
        img: "./img/players/stewart_hagestad.png"
    }, */
    /* {
        name: "Sung Kang",
        img: "./img/players/sung_kang.png"
    }, */
    /* {
        name: "Sungjae Im",
        img: "./img/players/sungjae_im.png"
    }, */
    /* {
        name: "Taichi Kho",
        img: "./img/players/taichi_kho.png"
    },
    {
        name: "Taiga Semikawa",
        img: "./img/players/taiga_semikawa.png"
    },*/
    /* {
        name: "Talor Gooch",
        img: "./img/players/talor_gooch.png"
    }, */
    {
        name: "Tapio Pulkkanen",
        img: "./img/players/tapio_pulkkanen.png"
    },
    /* {
        name: "Taylor Montgomery",
        img: "./img/players/taylor_montgomery.png"
    },  */
    /* {
        name: "Taisei Shimizu",
        img: "./img/players/taisei_shimizu.png"
    },
    {
        name: "Takumi Kanaya",
        img: "./img/players/takumi_kanaya.png"
    }, */
    /* {
        name: "Taylor Moore",
        img: "./img/players/taylor_moore.png"
    }, */
    /* {
        name: "Taylor Pendrith",
        img: "./img/players/taylor_pendrith.png"
    }, */
    /* {
        name: "Thriston Lawrence",
        img: "./img/players/thriston_lawrence.png"
    }, */
    {
        name: "Thomas Detry",
        img: "./img/players/thomas_detry.png"
    },
    /* {
        name: "Tim Widing",
        img: "./img/players/tim_widing.png"
    }, */
    /* {
        name: "Tiger Christensen (a)",
        img: "./img/players/tiger_christensen.png"
    },*/
    /* {
        name: "Tiger Woods",
        img: "./img/players/tiger_woods.png"
    },
    {
        name: "Todd Hamilton",
        img: "./img/players/todd_hamilton.png"
    }, */
    /*{
        name: "Thomas Pieters",
        img: "./img/players/thomas_pieters.png"
    },*/
    {
        name: "Thorbjorn Olesen",
        img: "./img/players/thorbjorn_olesen.png"
    }, 
    /* {
        name: "Tom Hoge",
        img: "./img/players/tom_hoge.png"
    },
    {
        name: "Tom Kim",
        img: "./img/players/tom_kim.png"
    },
    {
        name: "Tom McKibbin",
        img: "./img/players/tom_mckibbin.png"
    }, */
    {
        name: "Tommy Fleetwood",
        img: "./img/players/tommy_fleetwood.png"
    },
    /* {
        name: "Tommy Morrsion (a)",
        img: "./img/players/tommy_morrison.png"
    }, */
    /* {
        name: "Travis Smyth",
        img: "./img/players/travis_smyth.png"
    },
    {
        name: "Trey Mullinax",
        img: "./img/players/trey_mullinax.png"
    }, */
    /* {
        name: "Tony Finau",
        img: "./img/players/tony_finau.png"
    }, */
    /* {
        name: "Tracy Phillips",
        img: "./img/players/tracy_phillips.png"
    },
    {
        name: "Tyler Collet",
        img: "./img/players/tyler_collet.png"
    }, */
    /* {
        name: "Tyrrell Hatton",
        img: "./img/players/tyrrell_hatton.png"
    }, */
    {
        name: "Victor Perez",
        img: "./img/players/victor_perez.png"
    },
    /* {
        name: "Vijay Singh",
        img: "./img/players/vijay_singh.png"
    }, */
    {
        name: "Viktor Hovland",
        img: "./img/players/viktor_hovland.png"
    },
    /* {
        name: "Vincent Norrmman",
        img: "./img/players/vincent_norrman.png"
    }, */
    /* {
        name: "Wenyi Ding",
        img: "./img/players/wenyi_ding.png"
    }, 
    {
        name: "Wilco Nienaber",
        img: "./img/players/wilco_nienaber.png"
    },*/
    /* {
        name: "Webb Simpson",
        img: "./img/players/webb_simpson.png"
    },
    {
        name: "Wells Williams (a)",
        img: "./img/players/wells_williams.png"
    }, */
    /* {
        name: "Will Zalatoris",
        img: "./img/players/will_zalatoris.png"
    }, */
    /* {
        name: "Willie Mack II",
        img: "./img/players/willie_mack.png"
    }, */
    {
        name: "Wyndham Clark",
        img: "./img/players/wyndham_clark.png"
    },
    {
        name: "Xander Schauffele",
        img: "./img/players/xander_schauffele.png"
    },
    /* {
        name: "Wyatt Worthington II",
        img: "./img/players/wyatt_worthington.png"
    },
    {
        name: "Y. E. Yang",
        img: "./img/players/ye_yang.png"
    }, */
    /* {
        name: "Younghan Song",
        img: "./img/players/younghan_song.png"
    }, 
    {
        name: "Yuto Katsuragawa",
        img: "./img/players/yuto_katsuragawa.png"
    }, 
    {
        name: "Yannik Paul",
        img: "./img/players/yannik_paul.png"
    },
    {
        name: "Zach Johnson",
        img: "./img/players/zach_johnson.png"
    }, */
    {
        name: "Zecheng Dou",
        img: "./img/players/zecheng_dou.png"
    },
    /*{
        name: "Zack Fischer",
        img: "./img/players/zach_fischer.png"
    },*/
    /* {
        name: "Zac Oakley",
        img: "./img/players/zac_oakley.png"
    }, */
    /* {
        name: "Zac Blair",
        img: "./img/players/zac_blair.png"
    }, */
]

export default players
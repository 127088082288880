
import React from 'react'

//redux
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";

//Components
import { WhiteButton } from '../../utils/Buttons';
import Menu from '../../components/user/Menu'
import Logos from '../../components/user/Logos';

//Constants
import points_pos from '../../constants/points_pos';
import pointsHole from '../../constants/pointsHole'
import { fechaLim, title, year, version } from '../../constants/tournament';

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
    },
    overlay: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 30%, #0E191C 53%, #0E191C 100%)',
        paddingTop: 30,
        paddingBottom: 100,
        textAlign: 'center',
        color: 'white',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 20,
        width: '95%',
        [theme.breakpoints.down(450)]: {
            fontSize: '22px'
        }
    },
    subtitle: {
        fontSize: '20px',
        fontWeight: 'bold',
    },


    /* error message */
    rulesContainer: {
        //width: '95%',
        maxWidth: '800px',
        margin: '5px',
        //background: 'rgba(255,255,255,0.005)',
        borderRadius: 10,
        padding: 20,
        marginTop: 20,
        marginBottom: 50,
        textAlign: 'left',
        [theme.breakpoints.up(800)]: {
            margin: 'auto',
            marginTop: 20,
        }
    },
    rulesItem: {
        marginBottom: 20,
    },
    rulesText: {
        color: 'white',
        fontSize: '14px',
        marginBottom: 0,
        textAlign: 'justify',
        opacity: 0.9
    },
    iconButton: {
        color: 'white',
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)',
        }
    },
    link: {
        textDecoration: 'none',
        width: '95%',
        '&:hover': {
            textDecoration: 'none',
        }
    },
    actionButtons: {
        width: '95%',
        maxWidth: '200px',
        margin: 'auto',
    },


    /* table */
    tables: {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid white',
        paddingTop: '20px',
        marginTop: '20px',
        maxHeight: '400px',
        overflowY: 'scroll',
        [theme.breakpoints.up(500)]:{
            justifyContent: 'space-evenly'
        }
    },
    table: {
        width: '140px'
    },
    tableTitle: {
        fontSize: '14px',
        fontWeight: 'bolder',
        marginBottom: 10,
    },
    tableHeader: {
        display: 'flex',
        alignContent: 'center',
        background: '#0E1D1D'
    },
    tableRow: {
        display: 'flex',
        alignContent: 'center',
        background: '#193636',
    },
    headerCell: {
        width: '50px',
        textAlign: 'center',
        padding: '10px'
    },
    tableCell: {
        width: '60px',
        textAlign: 'center',
        border: '1px solid #0E1D1D',
        padding: '5px'
    }
}))


export default function Rules({classes}) {
    classes = useStyles();

    //redux
    const user = useSelector(state => state.userState.user);

    return (
        <>
            
            
            <div className={classes.backgroundContainer}>
                <div className={classes.overlay}>
                    {/* header */}
                    <div className={classes.header} id="header">
                        <Logos/>
                        <h1 className={classes.title}>Reglamento Quiniela {title} {year}</h1>
                    </div>

                    {/* reglamento */}
                    <div className={classes.rulesContainer}>
                        <div className={classes.rulesItem}>
                            <h4 className={classes.subtitle}>REGLAS QUINIELA {title.toLocaleUpperCase()} {year}</h4>
                            <p className={classes.rulesText}>Hola a todos, los invitamos a participar en esta asombrosa quiniela del {title} {year}.</p>
                            <p className={classes.rulesText}>Habrá premios para los ganadores y mucha diversión para todos los participantes.</p>
                        </div>
                        <div className={classes.rulesItem}>
                            <h4 className={classes.subtitle}>FECHA LÍMITE DE INSCRIPCIÓN</h4>
                            <p className={classes.rulesText}>Miércoles {fechaLim} del {year} a las 23:59pm.</p>
                        </div>
                        <div className={classes.rulesItem}>
                            <h4 className={classes.subtitle}>COSTO DE INSCRIPCIÓN</h4>
                            <p className={classes.rulesText}>El costo de inscripción es de $1,000 pesos por equipo. Una vez realizado el pago no se podrá devolver. Por lo tanto, es sumamente importante que registre sus equipos antes de la fecha líimte.</p>
                        </div>
                        <div className={classes.rulesItem}>
                            <h4 className={classes.subtitle}>FORMA DE PAGO</h4>
                            <p className={classes.rulesText}><b>Efectivo</b></p>
                            <p className={classes.rulesText} style={{marginBottom: 10}}>Pago en efectivo a Gabriel Gutierrez Castillo (999 997 2751)</p>
                            <p className={classes.rulesText}><b>Transferencia</b></p>
                            <p className={classes.rulesText}>CLABE: 002910701710520076</p>
                            <p className={classes.rulesText}>Banco: Banamex</p>
                            <p className={classes.rulesText}>Titular: Gabriel Gutierrez Castillo</p>
                            <p className={classes.rulesText}>Concepto: Quiniela - tu teléfono</p>
                            <p className={classes.rulesText}>Enviar el comprobante a Gabriel Gutiérrez Castillo (999 997 2751) o Abraham Cepeda Oseguera (999 365 4620)</p>
                        </div>
                        <div className={classes.rulesItem}>
                            <h4 className={classes.subtitle}>PREMIOS</h4>
                            <p className={classes.rulesText}>La bolsa total acumulada será distribuida de la siguiente manera:</p>
                            <ul style={{marginBottom: 10}}>
                                <li className={classes.rulesText}>Premios Ronda 1: 20%</li>
                                <li className={classes.rulesText}>Premios Ronda 2: 20%</li>
                                <li className={classes.rulesText}>Premios Ronda 3: 20%</li>
                                <li className={classes.rulesText}>Premios Ronda 4: 20%</li>
                                <li className={classes.rulesText}>Premios Final (Acumulado): 20%</li>
                            </ul>
                            <p className={classes.rulesText}>Los premios de cada ronda serán calculados con base en los siguientes porcentajes:</p>
                            <ul style={{marginBottom: 10}}>
                                <li className={classes.rulesText}>1er Lugar: 45%</li>
                                <li className={classes.rulesText}>2º Lugar: 27%</li>
                                <li className={classes.rulesText}>3er Lugar: 18%</li>
                                <li className={classes.rulesText}>Organizadores: 10%</li>
                            </ul>
                            <p className={classes.rulesText}>Los lugares serán definidos bajo un sistema de puntos que considera tanto el score obtenido por los jugadores como su posición en el tablero de posiciones como a continuación se describe.</p>
                        </div>
                        <div className={classes.rulesItem}>
                            <h4 className={classes.subtitle}style={{marginBottom: 10}}>INSTRUCCIONES</h4>
                            <p className={classes.rulesText} style={{marginBottom: 10}}><b>Formación de equipos</b></p>
                            <p className={classes.rulesText} style={{marginBottom: 5}}>Cada participante en la quiniela deberá crear la cantidad de equipos que desee conformados por 6 jugadores, siendo cada uno su propia apuesta. Los participantes podrán hacer cambios en sus equipos cuantas veces quieran hasta el <u>{fechaLim} a las 11:59 PM (Hora CDMX).</u></p>
                            <p className={classes.rulesText}>Además de seleccionar su equipo los participantes deberán ordenar a sus 6 jugadores en orden de importancia dado que los puntos de cada jugador se les agregará un porcentaje adicional para el cálculo de los puntos por equipo conforme a la siguiente puntos:</p>
                            <ul style={{marginBottom: 10}}>
                                <li className={classes.rulesText}>Capitán (50%)</li>
                                <li className={classes.rulesText}>Sub-capitán (40%)</li>
                                <li className={classes.rulesText}>1er Jugador (30%)</li>
                                <li className={classes.rulesText}>2º jugador (20%)</li>
                                <li className={classes.rulesText}>3er jugador (10%)</li>
                                <li className={classes.rulesText}>4º jugador (0%)</li>
                            </ul>
                            <p className={classes.rulesText} style={{marginBottom: 10}}></p>

                            {/* puntos pro jugador */}
                            <p className={classes.rulesText} style={{marginBottom: 10}}><b>Puntos por jugador</b></p>
                            <p className={classes.rulesText} style={{marginBottom: 10}}>Cada jugador aportará puntos por el score que obtenga en cada hoyo jugado en la Ronda en turno (ver Tabla 1) y por el lugar que ocupe en el tablero de posiciones (ver Tabla 2) (Leaderboard) en dicha ronda y se le agregará el porcentaje correspondiente de acuerdo al lugar que ocupa en el equipo.</p>
                            <p className={classes.rulesText} style={{marginBottom: 10}}><b>Ejemplo ilustrativo</b></p>
                            <p className={classes.rulesText} style={{marginBottom: 10}}>Ronda 1, Jordan Spieth</p>
                            <p className={classes.rulesText} style={{marginBottom: 10}}>El jugador Jordan Spieth (elegido como capitán del equipo) tira par en el hoyo 1. De acuerdo con la Tabla 1 obtiene 0 puntos. En el Hoyo 2 tira Birdie, obtiene 2 puntos. En el Hoyo 3 hace Águila, obtiene 4 puntos y con ello acumula 6 puntos. En el Hoyo 4 hace un Bogey y resta 1 punto, acumula 5 puntos y así sucesivamente. A manera de ejemplo consideremos que Jordan Spieth concluye la Ronda 1 con 12 puntos por score.</p>
                            <p className={classes.rulesText} style={{marginBottom: 10}}>Ahora, al final de la Ronda 1 si el jugador Jordan Spieth ocupa por ejemplo la posición 6 en el tablero general entonces, de acuerdo con la Tabla 2, por posición le corresponderán 10 puntos.</p>
                            <p className={classes.rulesText} style={{marginBottom: 10}}>Cabe mencionar que si por ejemplo Jordan Spieth empata en posición 6 (T6) con otros 3 jugadores entonces los puntos de las posiciones 6, 7 y 8 se sumarán y se dividirán por igual entre los 3 jugadores empatados.</p>
                            <p className={classes.rulesText} style={{marginBottom: 10}}>Siguiendo con el ejemplo, los puntos totales del jugador Jordan Spieth para la Ronda 1 se calcularán entonces de la siguiente manera:</p>

                            <ul style={{marginBottom: 10}}>
                                <li className={classes.rulesText}>Puntos por score = 12 puntos</li>
                                <li className={classes.rulesText}>Puntos por posición = 10 Puntos</li>
                                <li className={classes.rulesText}>Puntos totales Jordan Spieth Ronda 1 = 22 puntos</li>
                                <li className={classes.rulesText}>Puntos adicionales por se capitán Jordan Spieth Ronda 1 = 22*0.5 = 11 puntos</li>
                                <li className={classes.rulesText}>Puntos finales Jordan Spieth Ronda 1 = 22 + 11 = 33 puntos</li>
                            </ul>

                            <p className={classes.rulesText} style={{marginBottom: 10}}>Es muy importante aclarar que para las Rondas 1, 2 y 3 los puntos por Posición se otorgarán de acuerdo con la posición ocupada por el Score del día y no por el Score acumulado que es el que normalmente se observa en el seguimiento del Torneo en Internet o en la Televisión. Por ejemplo, si el jugador Tony Finau en la Ronda 3 tira un score de 61 y resulta ser el mejor del día recibirá los 50 puntos por posición aunque en el Tablero acumulado ocupe el lugar T27.</p>
                            <p className={classes.rulesText} style={{marginBottom: 10}}>Lo anterior permite que los jugadores que hayan tenido un mal inicio de Torneo puedan aportar puntos importantes a los equipos y con ello se incrementan las posibilidades de ganar para todos los participantes de la Quiniela.</p>
                            <p className={classes.rulesText} style={{marginBottom: 10}}>En la Ronda 4, es decir en la Ronda final, los puntos por Posición se otorgarán ahora sí de acuerdo con el lugar ocupado en el Tablero acumulado. En otras palabras, los 50 puntos del 1er lugar los recibirá quien resulte Campeón del Torneo aunque su score del día no haya sido el más bajo. Además, en esta Ronda final se tomará en cuenta los puntos acumulados en la Rondas 1, 2, 3 y 4 para determinar a los ganadores de la Ronda final.</p>

                            {/* puntos por equipo */}
                            <p className={classes.rulesText} style={{marginBottom: 10}}><b>Puntos por equipo</b></p>
                            <p className={classes.rulesText}>Los puntos de cada jugador serán sumados para obtener los puntos por equipo utilizando los sobrepesos ya descritos. Como ejemplo tomemos el siguiente caso:</p>
                            <p className={classes.rulesText}>Equipo de Abraham Cepeda:</p>
                            <p className={classes.rulesText}>Ronda 2</p>
                            <ul style={{marginBottom: 10}}>
                                <li className={classes.rulesText}>Capitán: Jordan Spieth, Puntos totales = 22 puntos</li>
                                <li className={classes.rulesText}>Subcapitán: Tony Finau, Puntos totales = 10 puntos</li>
                                <li className={classes.rulesText}>1er Jugador: Rory Mcilroy, Puntos totales = 5 puntos</li>
                                <li className={classes.rulesText}>2º jugador: Marc Leishman, Puntos totales = 10 puntos</li>
                                <li className={classes.rulesText}>3er jugador: Danny Willet, Puntos totales = 1 punto</li>
                                <li className={classes.rulesText}>4º jugador: Tommy Fleetwood, Puntos totales = -3 puntos</li>
                            </ul>
                            <p className={classes.rulesText} style={{marginBottom: 10}}>Puntos por Equipo = (22 x 1.5) + (10 x 1.4) + (5 x 1.3) + (10 x 1.2) + (1 x 1.1) + (-3 x 1) = 63.6 puntos</p>
                        </div>
                        <div className={classes.rulesItem}>
                            <h4 className={classes.subtitle}>CRITERIO DE DESEMPATE</h4>
                            <p className={classes.rulesText}>Pese a que la Quiniela se ha diseñado para disminuir las probabilidades de empates, en caso de que se presenten empates en los tres primeros lugares, se hará una distribución de la suma de las ganancias entre los participantes que hayan empatado. Por ejemplo, si dos participantes empatan en primer lugar, se hará la suma de la ganancia entre el primer y el segundo lugar y se dividirá en partes iguales entre ambos participantes.</p>
                        </div>
                        <div className={classes.rulesItem}>
                            <h4 className={classes.subtitle}>DATOS DE CONTACTO</h4>
                            <p className={classes.rulesText}>Gabriel Gutiérrez Castillo – 999 997 2751</p>
                            <p className={classes.rulesText}>Abraham Cepeda Oseguera – 999 365 4620</p>
                        </div>
                        <div className={classes.rulesItem} style={{marginBottom: '40px'}}>
                            <h4 className={classes.subtitle}>¡LES DESEAMOS LA MAYOR DE LAS SUERTES Y GRACIAS POR PARTICIPAR EN ESTA EDICIÓN QUINELA {title.toLocaleUpperCase()} {year}!</h4>
                        </div>

                        <div className={classes.tables}>
                            {/* tabla 1 */}
                            <div className={classes.table}>
                                <p className={classes.tableTitle}>Tabla 1. Puntos por score</p>
                                <div className={classes.tableContainer}>
                                    <div className={classes.tableHeader}>
                                        <div className={classes.headerCell}>
                                            Score
                                        </div>
                                        <div className={classes.headerCell}>
                                            Puntos
                                        </div>
                                    </div>
                                    {pointsHole.map((item,i) => (
                                        <div key={i} className={classes.tableRow}>
                                            <div className={classes.tableCell}>
                                                {item.score}
                                            </div>
                                            <div className={classes.tableCell}>
                                                {item.pts}
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>

                            {/* table 2 */}
                            <div className={classes.table}>
                                <p className={classes.tableTitle}>Tabla 2. Puntos por posición</p>
                                <div className={classes.tableContainer}>
                                    <div className={classes.tableHeader}>
                                        <div className={classes.headerCell}>
                                            Lugar
                                        </div>
                                        <div className={classes.headerCell}>
                                            Puntos
                                        </div>
                                    </div>
                                    {points_pos.map((item,i) => (
                                        <div key={i} className={classes.tableRow}>
                                            <div className={classes.tableCell}>
                                                {i+1}
                                            </div>
                                            <div className={classes.tableCell}>
                                                {item}
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            </div>
                        </div>
                        <div style={{textAlign: 'center', marginTop: '30px'}}>
                            <p><b>Versión {version}</b></p>
                        </div>
                    </div>
                    

                    <div className={classes.actionButtons}>
                        {user && (
                            <Menu page="rules"/>
                        )}
                        {!user && (
                            <Link to="/sign-up" className={classes.link}><WhiteButton>Participar</WhiteButton></Link>
                        )}
                    </div>
                    
                </div>
            </div>
        </>
    )
}
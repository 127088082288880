import React, { useEffect, useState } from 'react'

//redux
import { useSelector } from 'react-redux';

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";

//data
import course from '../../constants/course'
import memberType from '../../constants/memberType'
import { coursePar } from '../../constants/tournament';

//utils
import fixedInt from '../../utils/functions/fixedInt'

const useStyles = makeStyles((theme) =>({
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    roundsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '10px'
    },
    roundItem: {
        width: '30px',
        border: '2px solid white',
        borderRadius: '20px',
        marginRight: '10px',
        fontWeight: 'bold',
        fontSize: '12px',
        cursor: 'pointer',
        [theme.breakpoints.up(768)]: {
            width: '40px',
            marginRight: '10px',
            fontSize: '16px',
        }
    },
    parContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    parItem: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        fontSize: '10px',
        marginLeft: '5px',
        width: '50px',
        background: 'green'
    },
    eagle: {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        border: '5px double white',
        marginRight: '4px'
    },
    birdie: {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        border: '5px solid white',
        marginRight: '4px'
    },

    /* Scorecard */
    scorecard: {
        padding: '10px',
        overflowX: 'scroll'
    },
    scorecardHeader: {
        background: "#0E1D1D",
        fontWeight: 'bold',
        fontSize: '14px',
        textAlign: 'left',
        padding: '8px 4px',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        //overflowX: 'scroll'
        minWidth: '724px',
        borderBottom: '1px solid white'
    },
    scorecardRow: {
        background: "#0E1D1D",
        fontWeight: 'bolder',
        fontSize: '14px',
        textAlign: 'left',
        padding: '8px 4px',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        //overflowX: 'scroll'
        minWidth: '724px',
        borderBottom: '1px solid white'
    },
    scorecardRowTitle: {
        width: '40px',
        textAlign: 'left',
        background: 'none'
    },
    scorecardHoles: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        //overflowX: 'scroll'
        minWidth: '684px',
    },
    scorecardHole: {
        //minWidth: '30px'
        width: 'calc(100% / 19)',
        minWidth: 'calc(100% / 19)',
        textAlign: 'center',
        background: 'none',
        borderRight: '1px solid white',
    },
    scorecardLast: {
        width: 'calc(100% / 19)',
        minWidth: 'calc(100% / 19)',
        textAlign: 'center',
    },
    eagleHole: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        border: '1px double white',
        margin: 'auto',
        '&::before': {
            content: '" "',
            border: '1px solid white',
            borderRadius: '50%',
            display: 'block',
            width: '24px',
            height: '24px',
            position: 'relative',
            left: '-3px',
            top: '-3px'
        },
        '& p': {
            position: 'relative',
            top: '-25px'
        }
    },
    birdieHole: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        border: '1px solid white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto'
    },
    bogeyHole: {
        width: '20px',
        height: '20px',
        border: '1px solid white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto'
    },
    doubleHole: {
        width: '20px',
        height: '20px',
        border: '1px double white',
        margin: 'auto',
        '&::before': {
            content: '" "',
            border: '1px solid white',
            display: 'block',
            width: '24px',
            height: '24px',
            position: 'relative',
            left: '-3px',
            top: '-3px'
        },
        '& p': {
            position: 'relative',
            top: '-25px'
        }
    },

    /* Player poins */
    pointsContainer: {
        textAlign: 'left',
        paddingLeft: '10px',
        paddingBottom: '6px',
        '& h4': {
            marginBottom: '5px'
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    pointsItem: {
        marginRight: '10px',
        [theme.breakpoints.up(450)]: {
            marginRight: '20px',
        }
    },
    pointsNum: {
        fontSize: '15px',
        fontWeight: 'bold',
        [theme.breakpoints.up(450)]: {
            fontSize: '18px',
        }
    },
    pointsLabel: {
        fontSize: '11px',
        opacity: 0.75,
        [theme.breakpoints.up(450)]: {
            fontSize: '14px',
        }
    },
    pts: {
        fontSize: '14px'
    }
}))
const base = {
    r1: {
        score: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        par: 0,
        ptsScore: 0,
        ptsPos: 0,
        pos: "-",
    },
    r2: {
        score: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        par: 0,
        ptsScore: 0,
        ptsPos: 0,
        pos: "-",
    },
    r3: {
        score: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        par: 0,
        ptsScore: 0,
        ptsPos: 0,
        pos: "-",
    },
    r4: {
        score: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
        par: 0,
        ptsScore: 0,
        ptsPos: 0,
        pos: "-",
    }
}


export default function PlayerData({classes, name, type, round2}) {
    classes = useStyles();
    const reduxPlayers = useSelector(state => state.playersState.players);
    const [round, setRound] = useState(1)
    const [player, setPlayer] = useState(null);
    const [memberIndex, setMemberIndex] = useState(0)



    useEffect(() => {
        if(name && reduxPlayers){
            let temp = reduxPlayers.data.find(plr => plr.name === name)
            if(temp !== undefined){
                setPlayer(temp)
            }
        }

        if(type !== ""){
            let index = memberType.findIndex(el => el.title === type)
            setMemberIndex(index)
        } else {
            setMemberIndex(5)
        }

        setRound(round2 === 5 ? 4 : round2)
        
    }, [name,type, round2])


    return (
        <div className={classes.playerContainer}>
            {/* Round selection */}
            <div className={classes.roundsContainer}>
                <div className={classes.roundItem} onClick={() => {setRound(1)}} style={{background: round === 1 ? 'white': "none", color: round === 1 ? '#0a2e0a': 'white'}}>
                    <p>R1</p>
                </div>
                <div className={classes.roundItem} onClick={() => {setRound(2)}} style={{background: round === 2 ? 'white': "none", color: round === 2 ? '#0a2e0a': 'white'}}>
                    <p>R2</p>
                </div>
                <div className={classes.roundItem} onClick={() => {setRound(3)}} style={{background: round === 3 ? 'white': "none", color: round === 3 ? '#0a2e0a': 'white'}}>
                    <p>R3</p>
                </div>
                <div className={classes.roundItem} onClick={() => {setRound(4)}} style={{background: round === 4 ? 'white': "none", color: round === 4 ? '#0a2e0a': 'white'}}>
                    <p>R4</p>
                </div>
            </div>

            {/* Scorecard */}
            {player !== null ? (
                <>
                    <div className={classes.scorecard}>
                        <div className={classes.scorecardHeader}>
                            <div className={classes.scorecardRowTitle}>
                                <p>Hole</p>
                            </div>
                            <div className={classes.scorecardHoles}>
                                {course.map((hole,i) => (
                                    <div key={i} className={classes.scorecardHole}>
                                        <p>{hole.num}</p>
                                    </div>
                                ))}
                                <div className={classes.scorecardLast}>
                                    <p>Tot</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.scorecardHeader}>
                            <div className={classes.scorecardRowTitle}>
                                <p>Par</p>
                            </div>
                            <div className={classes.scorecardHoles}>
                                {course.map((hole,i) => (
                                    <div key={i} className={classes.scorecardHole}>
                                        <p>{hole.par}</p>
                                    </div>
                                ))}
                                <div className={classes.scorecardLast}>
                                    <p>{coursePar}</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.scorecardRow}>
                            <div className={classes.scorecardRowTitle}>
                                <p>R{round}</p>
                            </div>
                            <div className={classes.scorecardHoles}>
                                {player[`r${round}`].score.map((score,i) => (
                                    <div key={i} className={classes.scorecardHole}>
                                        <div className={(score-course[i].par === 0 || score === 0) ? classes.hole : score-course[i].par < -1 ? classes.eagleHole : score-course[i].par < 0 ? classes.birdieHole : score-course[i].par < 2 ? classes.bogeyHole : classes.doubleHole}>
                                            <p>{score === 0 ? "-" : score}</p>
                                        </div>
                                    </div>
                                ))}
                                <div className={classes.scorecardLast}>
                                    <p>{player[`r${round}`].tot === 0 ? "-" : player[`r${round}`].tot}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Player points */}
                    {player.cut ? (
                        <div className={classes.pointsContainer}>
                            <div className={classes.pointsItem}>
                                <p className={classes.pointsNum}>{player[`r${round}`].ties !== 0 ? 'T'+ player[`r${round}`].pos : player[`r${round}`].pos}<span className={classes.pts}>{player[`r${round}`].ties !== 0 ? "(" +player[`r${round}`].ties + ")" : ""}</span></p>
                                <p className={classes.pointsLabel}>Lugar</p>
                            </div>
                            <div className={classes.pointsItem}>
                                <p className={classes.pointsNum}>{player[`r${round}`].ptsScore}<span className={classes.pts}>pts</span></p>
                                <p className={classes.pointsLabel}>Score</p>
                            </div>
                            <div className={classes.pointsItem}>
                                <p className={classes.pointsNum}>{fixedInt(player[`r${round}`].ptsPos)}<span className={classes.pts}>pts</span></p>
                                <p className={classes.pointsLabel}>Pos</p>
                            </div>
                            {(memberIndex !== 5 && type !== "") && (
                                <div className={classes.pointsItem}>
                                    <p className={classes.pointsNum}>
                                        {fixedInt((player[`r${round}`].ptsScore + player[`r${round}`].ptsPos)*memberType[memberIndex].sum,2)}<span className={classes.pts}>pts</span>
                                    </p>
                                    <p className={classes.pointsLabel}>{`+${memberType[memberIndex].per}`}</p>
                                </div>
                            )}
                            <div className={classes.pointsItem}>
                                <p className={classes.pointsNum}>
                                    {fixedInt((player[`r${round}`].ptsScore + player[`r${round}`].ptsPos)*memberType[memberIndex].mult,2)}<span className={classes.pts}>pts</span>
                                </p>
                                <p className={classes.pointsLabel}>Total</p>
                            </div>
                        </div>
                    ) : round > 2 ? (

                        <div className={classes.pointsContainer}>
                            <div className={classes.pointsItem}>
                                <p className={classes.pointsNum}>Missed Cut</p>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.pointsContainer}>
                            <div className={classes.pointsItem}>
                                <p className={classes.pointsNum}>{player[`r${round}`].pos}<span className={classes.pts}></span></p>
                                <p className={classes.pointsLabel}>Lugar</p>
                            </div>
                            <div className={classes.pointsItem}>
                                <p className={classes.pointsNum}>{player[`r${round}`].ptsScore}<span className={classes.pts}>pts</span></p>
                                <p className={classes.pointsLabel}>Score</p>
                            </div>
                            <div className={classes.pointsItem}>
                                <p className={classes.pointsNum}>{fixedInt(player[`r${round}`].ptsPos)}<span className={classes.pts}>pts</span></p>
                                <p className={classes.pointsLabel}>Pos</p>
                            </div>
                            <div className={classes.pointsItem}>
                                {type === "Capitán" ? (
                                    <p className={classes.pointsNum}>
                                        {fixedInt((player[`r${round}`].ptsScore + player[`r${round}`].ptsPos)*1.2,2)}<span className={classes.pts}>pts</span>
                                    </p>
                                ) : type === "Subcapitán" ? (
                                    <p className={classes.pointsNum}>
                                        {fixedInt((player[`r${round}`].ptsScore + player[`r${round}`].ptsPos)*1.1,2)}<span className={classes.pts}>pts</span>
                                    </p>
                                ): (
                                    <p className={classes.pointsNum}>
                                        {fixedInt(player[`r${round}`].ptsScore + player[`r${round}`].ptsPos)}<span className={classes.pts}>pts</span>
                                    </p>
                                )}
                                <p className={classes.pointsLabel}>Total</p>
                            </div>
                        </div>
                    )}
                    
                </>
            ):(
                <>
                    <div className={classes.scorecard}>
                        <div className={classes.scorecardHeader}>
                            <div className={classes.scorecardRowTitle}>
                                <p>Hole</p>
                            </div>
                            <div className={classes.scorecardHoles}>
                                {course.map((hole,i) => (
                                    <div key={i} className={classes.scorecardHole}>
                                        <p>{hole.num}</p>
                                    </div>
                                ))}
                                <div className={classes.scorecardLast}>
                                    <p>Tot</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.scorecardHeader}>
                            <div className={classes.scorecardRowTitle}>
                                <p>Par</p>
                            </div>
                            <div className={classes.scorecardHoles}>
                                {course.map((hole,i) => (
                                    <div key={i} className={classes.scorecardHole}>
                                        <p>{hole.par}</p>
                                    </div>
                                ))}
                                <div className={classes.scorecardLast}>
                                    <p>{coursePar}</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.scorecardRow}>
                            <div className={classes.scorecardRowTitle}>
                                <p>R{round}</p>
                            </div>
                            <div className={classes.scorecardHoles}>
                                {base[`r${round}`].score.map((score,i) => (
                                    <div key={i} className={classes.scorecardHole}>
                                        <div className={(score-course[i].par === 0 || score === 0) ? classes.hole : score-course[i].par < -1 ? classes.eagleHole : score-course[i].par < 0 ? classes.birdieHole : score-course[i].par < 2 ? classes.bogeyHole : classes.doubleHole}>
                                            <p>{score === 0 ? "-" : score}</p>
                                        </div>
                                    </div>
                                ))}
                                <div className={classes.scorecardLast}>
                                    <p>{base[`r${round}`].tot === 0 ? "-" : base[`r${round}`].tot}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* base points */}
                    <div className={classes.pointsContainer}>
                        <div className={classes.pointsItem}>
                            <p className={classes.pointsNum}>{base[`r${round}`].pos}<span className={classes.pts}></span></p>
                            <p className={classes.pointsLabel}>Lugar</p>
                        </div>
                        <div className={classes.pointsItem}>
                            <p className={classes.pointsNum}>{base[`r${round}`].ptsScore.toFixed(2)}<span className={classes.pts}>pts</span></p>
                            <p className={classes.pointsLabel}>Score</p>
                        </div>
                        <div className={classes.pointsItem}>
                            <p className={classes.pointsNum}>{base[`r${round}`].ptsPos.toFixed(2)}<span className={classes.pts}>pts</span></p>
                            <p className={classes.pointsLabel}>Pos</p>
                        </div>
                        <div className={classes.pointsItem}>
                            {type === "Capitán" ? (
                                <p className={classes.pointsNum}>
                                    {((base[`r${round}`].ptsScore + base[`r${round}`].ptsPos)*1.2).toFixed(2)}<span className={classes.pts}>pts</span>
                                </p>
                            ) : type === "Subcapitán" ? (
                                <p className={classes.pointsNum}>
                                    {((base[`r${round}`].ptsScore + base[`r${round}`].ptsPos)*1.1).toFixed(2)}<span className={classes.pts}>pts</span>
                                </p>
                            ): (
                                <p className={classes.pointsNum}>
                                    {((base[`r${round}`].ptsScore + base[`r${round}`].ptsPos))}<span className={classes.pts}>pts</span>
                                </p>
                            )}
                            <p className={classes.pointsLabel}>Total</p>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

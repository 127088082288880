import React, { useState, useEffect, useRef } from 'react'

//firebase
import firebase from '../../database/firebase'

//redux
import { useSelector, useDispatch } from 'react-redux';
import { setReduxPlayers, setUsersRedux } from '../../store/actions'

//router
import { Redirect, Link } from 'react-router-dom';

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { IconButton, Tooltip } from '@material-ui/core';

//Material UI - icons
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import ShuffleOnRoundedIcon from '@mui/icons-material/ShuffleOnRounded';

//data
import course from '../../constants/course'
import points_pos from '../../constants/points_pos'
import typePlayers from '../../constants/typePlayers';
import roundBase from '../../constants/roundBase';
import pointsHole from '../../constants/pointsHole';

//utils
import fixedInt from '../../utils/functions/fixedInt';
import getRandomScore from '../../utils/functions/getRandomScore';
import getRandomPos from '../../utils/functions/getRandomPos';
import getRandomTies from '../../utils/functions/getRandomTies';

//components
import { WhiteButton } from '../../utils/Buttons';
import { MySwitch } from '../../utils/Switch';
import Logos from '../../components/user/Logos';

//constants
import { tournament, leaderboard, coursePar } from '../../constants/tournament'

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        overflowX: 'hidden'
    },
    overlay: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 30%, #0e1d1deb 53%, #0e1d1de8 100%)',
        paddingTop: 30,
        paddingBottom: 50,
        textAlign: 'center',
        color: 'white',
    },
    playerIcon: {
        color: 'white'  
    },
    

    /* welcome */
    welcome__container: {
        width: '98%',
        maxWidth: '1200px',
        textAlign: 'center',
        margin: 'auto',
    },
    welcome: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: 30
    },

    /* header */
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
    },

    /* rounds */
    roundsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    roundItem: {
        width: '40px',
        border: '2px solid white',
        borderRadius: '20px',
        marginRight: '10px',
        fontWeight: 'bold',
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },

    /* action container  */
    actionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    icon: {
        color: 'white'
    },


    /* players list */
    playerList: {
        display: 'grid',
        gridTemplateColumns: 'auto',
        gridGap: '10px',
        padding: '20px',
        width: '80%',
        maxWidth: '800px',
        borderRadius: '10px',
        border: '2px solid white',
        margin: 'auto',
        overflowX: 'scroll'
    },
    playerContainer: {
        background: 'rgba(255,255,255,0.05)',
        borderRadius: '10px',
        marginBottom: '5px',
        padding: '15px',
        '&:hover': {
            background: 'rgba(255,255,255,0.1)',
        }
    },
    playerHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        cursor: 'pointer',
    },
    playerImg: {
        width: '40px',
        height: '40px',
        marginRight: '10px',
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    playerName: {
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
        flex: 1,
        textAlign: 'left'
    },
    playerButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    iconBtn: {
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    icon: {
        color: 'white !important'
    },


    /* Scorecard */
    scorecard: {
        padding: '10px',
    },
    scorecardHeader: {
        background: "#0E1D1D",
        fontWeight: 'bold',
        fontSize: '14px',
        textAlign: 'left',
        padding: '8px 4px',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        //overflowX: 'scroll'
        minWidth: '724px',
        borderBottom: '1px solid white'
    },
    scorecardRow: {
        background: "#0E1D1D",
        fontWeight: 'bolder',
        fontSize: '14px',
        textAlign: 'left',
        padding: '8px 4px',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        //overflowX: 'scroll'
        minWidth: '724px',
        borderBottom: '1px solid white'
    },
    scorecardRowTitle: {
        width: '40px',
        textAlign: 'left',
        background: 'none'
    },
    scorecardHoles: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        //overflowX: 'scroll'
        minWidth: '684px',
    },
    scorecardHole: {
        //minWidth: '30px'
        width: 'calc(100% / 19)',
        minWidth: 'calc(100% / 19)',
        textAlign: 'center',
        background: 'none',
        borderRight: '1px solid white',
    },
    scorecardLast: {
        width: 'calc(100% / 19)',
        minWidth: 'calc(100% / 19)',
        textAlign: 'center',
    },
    eagleHole: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        border: '1px double white',
        margin: 'auto',
        '&::before': {
            content: '" "',
            border: '1px solid white',
            borderRadius: '50%',
            display: 'block',
            width: '24px',
            height: '24px',
            position: 'relative',
            left: '-3px',
            top: '-3px'
        },
        '& p': {
            position: 'relative',
            top: '-25px'
        }
    },
    birdieHole: {
        width: '20px',
        height: '20px',
        borderRadius: '50%',
        border: '1px solid white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto'
    },
    bogeyHole: {
        width: '20px',
        height: '20px',
        border: '1px solid white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto'
    },
    doubleHole: {
        width: '20px',
        height: '20px',
        border: '1px double white',
        margin: 'auto',
        '&::before': {
            content: '" "',
            border: '1px solid white',
            display: 'block',
            width: '24px',
            height: '24px',
            position: 'relative',
            left: '-3px',
            top: '-3px'
        },
        '& p': {
            position: 'relative',
            top: '-25px'
        }
    },
    
    scorecardHole: {
        //minWidth: '30px'
        width: 'calc(100% / 19)',
        minWidth: 'calc(100% / 19)',
        textAlign: 'center',
        background: 'none',
        borderRight: '1px solid white',
    },
    scorecardLast: {
        width: 'calc(100% / 19)',
        minWidth: 'calc(100% / 19)',
        textAlign: 'center',
    },
    hole: {
        width: 'calc(100% / 19)',
        minWidth: 'calc(100% / 19)',
    },
    scorecardInput: {
        width: '20px',
        background: 'none',
        border: '0.5px solid white',
        borderRadius: '5px',
        color: 'white',
        margin: 'auto',
        textAlign: 'center',
        outline: 'none !important',
        '&:focus': {
            outline: 'none !important'
        }
    },

    /* player points and place */
    pointsContainer: {
        textAlign: 'left',
        paddingLeft: '10px',
        '& h4': {
            marginBottom: '5px'
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    pointsItem: {
        marginRight: '20px'
    },
    pointsNum: {
        fontSize: '25px',
        fontWeight: 'bold'
    },
    pointsLabel: {
        fontSize: '16px',
        opacity: '075'
    },
    pts: {
        fontSize: '14px'
    },

    placeInput: {   
        background: 'none',
        border: '1px solid white',
        borderRadius: '5px',
        padding: '10px',
        outline: 'none',
        width: '30px',
        color: 'white',
        '&:focus': {
            outline: 'none',
        }
    },

    /* action buttons */
    actionButtons: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: 20,
        marginTop: 50,
        width: '70%',
        maxWidth: '600px',
        margin: 'auto',
        marginBottom: 50,
    },

    /* error message */
    messageContainer: {
        background: 'rgba(255,255,255,0.05)',
        borderRadius: 10,
        padding: 20,
        margin: 10,
        marginTop: 20,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    infoIcon: {
        width: '30px !important',
        height: '30px !important',
        marginRight: 20,
        color: 'white'
    },
    messageText: {
        color: 'white',
        fontSize: '12px',
        marginBottom: 0,
        textAlign: 'left',
    }
}))


/* Alert */
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export default function Players({classes}) {
    classes = useStyles();
    
    //redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userState.user);
    const users = useSelector(state => state.usersState.users);
    const reduxPlayers = useSelector(state => state.playersState.players);

    //State
    const [allPlayers, setAllPlayers] = useState([])
    const [playersList, setPlayersList] = useState([])
    const [round, setRound] = useState(1)
    const [errors, setErrors] = useState([]);
    const [open, setOpen] = useState(false);

    /* <----------temporary fuctions-----------> */
    /* hadle get players */
    const handleGetPlayers = () => {
        let data = []
        firebase.db.collection('users')
            .where('type', '==', 'user')
            .onSnapshot(snapshot => {
                snapshot.docs.forEach(doc => {
                    if(doc.data().hasSelected) {
                        //add first team
                        if(doc.data().data) {
                        
                            
                            doc.data().data.forEach((item) => {
                                if(data.find((el) => el.name === item.name) === undefined) {
                                    data.push({
                                        name: item.name,
                                        img: item.img,
                                        times: 1,
                                        cut: true,
                                        r1: roundBase,
                                        r2: roundBase,
                                        r3: roundBase,
                                        r4: roundBase,
                                    })
                                }
                            })
                        }

                        //add additional teams
                        if(doc.data().teams) {
                            doc.data().teams.forEach((item) => {
                                if(item.team && item.num <= doc.data().paidTeams) {
                                    item.team.forEach((item2) => {
                                        if(data.find((el) => el.name === item2.name) === undefined) {
                                            data.push({
                                                name: item2.name,
                                                img: item2.img,
                                                times: 1,
                                                cut: true,
                                                r1: roundBase,
                                                r2: roundBase,
                                                r3: roundBase,
                                                r4: roundBase,
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    }

                    
                })
                data.sort((a,b) => {return a.name < b.name ? -1 : 1})
                setAllPlayers(data)
                setPlayersList(data)

                //set players in firebase
                firebase.db.collection(tournament).doc("players").set({
                    data: data
                })
            })
    }

    /* generate random results */
    const generateResults = () => {
        let temp = []
        playersList.forEach((player) => {
            let ptsScore = 0
            let pos = getRandomPos() - 1

            let tot = 0
            player[`r${round}`].score.forEach((el, i) => {
                let score = getRandomScore(course[i].par)
                player[`r${round}`].score[i] = score
                tot += score
            })
            player[`r${round}`].score.forEach((num,i) => {
                if(num > 0){
                    let par = num-course[i].par
                    ptsScore += pointsHole[par > 1 ? 5 : par+3].pts
                }
            })

            player[`r${round}`].ptsScore = ptsScore
            player[`r${round}`].tot = tot
            player[`r${round}`].ptsPos = points_pos[pos]
            player[`r${round}`].pos = pos
            player[`r${round}`].ties = getRandomTies()

            temp.push({
                ...player
            })
        })

        setAllPlayers(temp)
        setPlayersList(temp)
    }


    useEffect(() => {
        //logoutUser()
        if(!reduxPlayers){
            fetchPlayers()
        } else {
            let temp = []
            reduxPlayers.data.forEach((item) => {
                temp.push({
                    ...item,
                    isOpen: false
                })
            })
            setAllPlayers(temp)
            setPlayersList(temp)
        }

        if(!users) {
            fecthUsers()
        } 
    }, [])

    /* Fetch users from firebase */
    const fecthUsers =  () => {
        let data = []
        firebase.db.collection('users')
            .where('type', '==', 'user')
            .onSnapshot(snapshot => {
                snapshot.docs.forEach(doc => {
                    data.push({
                        ...doc.data(),
                        edited: false,
                    })
                })
                console.log(data)
                localStorage.setItem('reduxUsers', JSON.stringify(data))
                setUsersRedux(data)
            })
    }

    /* Fetch player from firebase */
    const fetchPlayers = () => {
        firebase.db.collection(tournament).doc('players').onSnapshot(doc => {
            if(doc.exists){
                dispatch(setReduxPlayers(doc.data()))
                
                let temp = []
                doc.data().data.forEach((item) => {
                    temp.push({
                        ...item,
                        isOpen: false
                    })
                })
                console.log(temp)
                setAllPlayers(temp)
                setPlayersList(temp)
                //set local state
            } else {
                dispatch(setReduxPlayers(null))
            }
        })
    }

    

    const handlePlayerClick = (name) => {
        let temp = []
        allPlayers.forEach((item) => {
            if(item.name === name){
                temp.push(
                    {
                        ...item,
                        isOpen: !item.isOpen 
                    }
                )
            } else {
                temp.push(
                    {
                        ...item,
                        isOpen: false
                    }
                )
            }
            
        })
        
        setAllPlayers(temp)
        setPlayersList(temp)
    }

    /* Handle hole change */
    const handleHoleChange = (name,hole,score) => {
        let temp = []
        let pts = [8,5,2,0,-1,-3]
        if(!isNaN(score) && score !== "" && Number(score) !== 0){
            allPlayers.forEach((item) => {
                if(item.name === name){
                    item[`r${round}`].score[hole] = score
                    //let par = score-course[hole].par
                    let sum = 0
                    item[`r${round}`].score.forEach((el) => {
                        sum += Number(el)
                    })
                    let points = 0
                    item[`r${round}`].score.forEach((num,i) => {
                        if(num > 0){
                            let par = num-course[i].par
                            points += pts[par > 1 ? 5 : par+3]
                        }
                    })
                    item[`r${round}`].ptsScore = points
                    item[`r${round}`].tot = sum
                    //console.log(pts[par > 1 ? 5 : par+3])
                }
                temp.push({...item})
            })
            setAllPlayers(temp)
            setPlayersList(temp)
        } else if(score === ""){
            allPlayers.forEach((item) => {
                if(item.name === name){
                    item[`r${round}`].score[hole] = score
                }
                temp.push({...item})
            })
            setAllPlayers(temp)
            setPlayersList(temp)
        }
        
    }

    /* handle position change */
    const handlePositionChange = (name,pos) => {
        if(!isNaN(pos)){
            let temp = []
            allPlayers.forEach((item) => {
                if(item.name === name){
                    if(pos > 90){
                        item[`r${round}`].ptsPos = 0
                    } else if(item[`r${round}`].ties === 0) {
                        item[`r${round}`].ptsPos = points_pos[pos-1]
                    } else {
                        let ties = item[`r${round}`].ties
                        let sum = 0
                        let avg = 0
                        for (let i = pos-1; i < pos-1+ties; i++) {
                            sum += points_pos[i]
                        }
                        avg = sum / ties
                        item[`r${round}`].ptsPos = avg
                    }
                    item[`r${round}`].pos = pos
                }
                temp.push({...item})
            })
            setAllPlayers(temp)
            setPlayersList(temp)
        }
    }

    /* handle ties change */
    const handleTiesChange = (name,pos,ties) => {
        if(!isNaN(pos) && !isNaN(ties)){
            let temp = []
            allPlayers.forEach((item) => {
                if(item.name === name){
                    
                    if(pos > 90){
                        item[`r${round}`].ptsPos = 0
                    } else if(ties !== 0) {
                        let sum = 0
                        let avg = 0
                        for (let i = pos-1; i < pos-1+ties; i++) {
                            if(i < 90) {
                                sum += points_pos[i]
                            }
                        }
                        avg = sum / ties
                        item[`r${round}`].ptsPos = avg
                    } else {
                        handlePositionChange(name, pos)
                    }
                    item[`r${round}`].ties = ties
                }
                temp.push({...item})
            })
            setAllPlayers(temp)
            setPlayersList(temp)
        }
    }

    /* hanle player cut change */
    const handlePlayerCutChange = (name,e) => {
        let temp = []
        //console.log(value)
        allPlayers.forEach((item) => {
            if(item.name === name) {
                item.cut = e.target.checked
                item.r3.score = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                item.r3.tot = 0
                item.r3.ptsScore = 0
                item.r3.ptsPos = 0
                item.r3.pos = 0
                item.r3.ties = 0
                item.r4.score = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]
                item.r4.tot = 0
                item.r4.ptsScore = 0
                item.r4.ptsPos = 0
                item.r4.pos = 0
                item.r4.ties = 0
            }
            temp.push({...item})
        })
        setAllPlayers(temp)
        setPlayersList(temp)
    }

    /* handle save players data in firebase */
    const handleSavePlayers = () => {
        //no need to verify
        console.log(allPlayers)
        firebase.db.collection(tournament).doc('players').set({
            data: allPlayers,
        }).then(() => {
            setOpen(true)
            dispatch(setReduxPlayers({data:allPlayers}))
        }).catch((error) => {
            setErrors([error.message])
        })
    }

    /* handle snack bar close */
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };

    /* handle update leaderboard */
    const handleUpdateLeaderboard = () => {
        let temp = []
        let final = []
        if(users) {
            console.log(users)
            users.forEach((item) => {
                if(item.hasSelected && item.hasPaid) {
                    let totPts = 0
                    let totalPts = 0
                    let mult = [1.5, 1.4, 1.3, 1.2, 1.1, 1]
                    item.data.forEach((player) => {
                        let tempPlayer = allPlayers.find(el => el.name === player.name)
                        //console.log(tempPlayer)
                        let m = mult[typePlayers.findIndex(el => el === player.type)]
                        totPts += (tempPlayer[`r${round}`].ptsScore + tempPlayer[`r${round}`].ptsPos) * m
                        if(round === 4){
                            totalPts += (tempPlayer[`r${round}`].ptsScore + tempPlayer[`r${round}`].ptsPos) * m
                        }
                        if(round >= 3){
                            totalPts += (tempPlayer[`r${3}`].ptsScore + tempPlayer[`r${3}`].ptsPos) * m
                        }
                        if(round >= 2){
                            totalPts += (tempPlayer[`r${2}`].ptsScore + tempPlayer[`r${2}`].ptsPos) * m
                        }
                        if(round >= 1){
                            totalPts += (tempPlayer[`r${1}`].ptsScore + tempPlayer[`r${1}`].ptsPos) * m
                        }
                        
                    
                    })
                    
                    temp.push({
                        uid: item.uid,
                        name: item.nickname,
                        pts: totPts,
                        team: item.data,
                        num: 1
                    })
                    final.push({
                        uid: item.uid,
                        name: item.nickname,
                        pts: totalPts,
                        team: item.data,
                        posMoney: 0,
                        num: 1
                    })

                    // get data of additional teams
                    if(item.teams) {
                        item.teams.forEach((team, i) => {
                            totPts = 0
                            totalPts = 0
                            if(i < Number(item.paidTeams)-1) {
                                team.team.forEach((player) => {
                                    let tempPlayer = allPlayers.find(el => el.name === player.name)
                                    //console.log(tempPlayer)
                                    let m = mult[typePlayers.findIndex(el => el === player.type)]
                                    totPts += (tempPlayer[`r${round}`].ptsScore + tempPlayer[`r${round}`].ptsPos) * m
                                    if(round === 4){
                                        totalPts += (tempPlayer[`r${round}`].ptsScore + tempPlayer[`r${round}`].ptsPos) * m
                                    }
                                    if(round >= 3){
                                        totalPts += (tempPlayer[`r${3}`].ptsScore + tempPlayer[`r${3}`].ptsPos) * m
                                    }
                                    if(round >= 2){
                                        totalPts += (tempPlayer[`r${2}`].ptsScore + tempPlayer[`r${2}`].ptsPos) * m
                                    }
                                    if(round >= 1){
                                        totalPts += (tempPlayer[`r${1}`].ptsScore + tempPlayer[`r${1}`].ptsPos) * m
                                    }
                                })
                                temp.push({
                                    uid: item.uid,
                                    name: item.nickname +  " E" + team.num,
                                    pts: totPts,
                                    team: team.team,
                                    num: team.num
                                })
                                final.push({
                                    uid: item.uid,
                                    name: item.nickname + " E" + team.num,
                                    pts: totalPts,
                                    team: team.team,
                                    posMoney: 0,
                                    num: team.num
                                })
                            }
                            
                        })
                    }
                }
                
            })
            
            //sort leaderboard by total points
            temp.sort((a,b) => {return a.pts > b.pts ? -1 : 1})
            final.sort((a,b) => {return a.pts > b.pts ? -1 : 1})
            console.log(temp)
            console.log(final)
            

            //assign position to users according to points - round
            let tempUsers = users.filter(el => el.hasPaid && el.hasSelected)
            //let total = tempUsers.length * 1000
            let total = 0
            tempUsers.forEach((item) => {
                if(item.teams) {
                    total += Number(item.paidTeams)
                } else {
                    total += 1
                }
            })
            total *= 1000

            let pos = 1;
            for(let i = 0; i < temp.length; i++) {
                temp[i].position = pos.toString();
                if(i < temp.length - 1) {
                    let j = i+1;
                    let flag = true;
                    while(flag && j < temp.length) {
                        if(temp[i].pts === temp[j].pts) {
                            temp[i].position = "T" + pos.toString();
                            temp[j].position = "T" + pos.toString();
                            j++;
                        } else {
                            flag = false;
                        }
                    }
                    i = j-1;
                    pos = j+1;
                }
            }

            //assign position to users according to points - final round
            pos = 1;
            for(let i = 0; i < temp.length; i++) {
                final[i].position = pos.toString();
                if(i < final.length - 1) {
                    let j = i+1;
                    let flag = true;
                    while(flag && j < final.length) {
                        if(final[i].pts === final[j].pts) {
                            final[i].position = "T" + pos.toString();
                            final[j].position = "T" + pos.toString();
                            j++;
                        } else {
                            flag = false;
                        }
                    }
                    i = j-1;
                    pos = j+1;
                }
            }
            

            //assign money to users according to position - round
            pos = 1
            let i = 0
            let distribution = [0.45,0.27,0.18] //60% (1er), 20% (2do), 10% (3ro)
            

            while(pos < 4 && i < temp.length - 1){
                let roundMoney = 0
                if(round < 4) {
                    roundMoney = total * 0.2
                } else {
                    roundMoney = total * 0.2
                }
                if(temp[i].position === pos.toString()){
                    temp[i].money = roundMoney * distribution[pos-1]
                    pos += 1
                    i += 1
                } else {
                    let ties = 1
                    let tempPos = "T" + pos.toString()
                    let j = i+1
                    let flag = true
                    
                    while(flag && j < tempUsers.length){
                        if(temp[j].position === tempPos){
                            ties += 1
                            j += 1
                        } else {
                            flag = false
                        }
                    }

                    
                    //assign money if there are ties
                    let posMoney = 0
                    for(let k = pos-1; k < pos-1+ties; k++){
                        posMoney += roundMoney * distribution[k]
                    }
                    //console.log(posMoney)
                    let posAvgMoney = posMoney/ties
                    console.log(posMoney, ties)
                    //assign money to tied users 
                    for(let k = pos-1; k < pos-1+ties; k++) {
                        temp[k].money = posAvgMoney
                    }
                    pos += ties
                    i += ties
                }
            }
            console.log(temp)

            //assign money to users according to position - final round
            if(round === 4){
                pos = 1
                i = 0
                //distribution = [0.6,0.2,0.1] //60% (1er), 20% (2do), 10% (3ro)
                

                while(pos < 4 && i < temp.length - 1){
                    let roundMoney = 0
                    if(round < 4) {
                        roundMoney = total * 0.2
                    } else {
                        roundMoney = total * 0.2
                    }
                    if(final[i].position === pos.toString()){
                        final[i].money = roundMoney * distribution[pos-1]
                        pos += 1
                        i += 1
                    } else {
                        let ties = 1
                        let tempPos = "T" + pos.toString()
                        let j = i+1
                        let flag = true
                        
                        while(flag && j < tempUsers.length){
                            if(final[j].position === tempPos){
                                ties += 1
                                j += 1
                            } else {
                                flag = false
                            }
                        }

                        
                        //assign money if there are ties
                        let posMoney = 0
                        for(let k = pos-1; k < pos-1+ties; k++){
                            posMoney += roundMoney * distribution[k]
                        }
                        //console.log(posMoney)
                        let posAvgMoney = posMoney/ties
                        //assign money to tied users 
                        for(let k = pos-1; k < pos-1+ties; k++) {
                            final[k].money = posAvgMoney
                        }
                        pos += ties
                        i += ties
                    }
                }
            }
            
            console.log(temp)
            console.log(final)

            //upload leaderboard data to firebase
            firebase.db.collection(leaderboard).doc('leaderboard').set({
                [`r${round}`]: temp,
                ['r5']: final
            }, {
                merge: true
            }).catch(error => {
                console.log(error)
            }).then(() => {
                console.log("Succes setting leaderboard..")
                setOpen(true)
            })
        }
        
    }

    return (
        <>
            {!user && (
                <Redirect to="/admin"/>
            )}
            <div className={classes.backgroundContainer}>
                <div className={classes.overlay}>
                    {/* welcome message */}
                    <div className={classes.welcome__container}>
                        <Logos/>
                        <h2 className={classes.welcome}>Hola {user && user.nickname}, ¡editar a los jugadores!</h2>        
                        {/* action buttons */}
                        <div className={classes.actionButtons}>
                            <Link to="/admin" className={classes.link}><WhiteButton>Regresar</WhiteButton></Link>
                        </div>
                    </div>

                    {/* list of players */}
                    <div className={classes.playerList}>
                        <div className={classes.headerContainer}>
                            {/* Round selection */}
                            <div className={classes.roundsContainer}>
                                <div className={classes.roundItem} onClick={() => {setRound(1)}} style={{background: round === 1 ? 'white': "none", color: round === 1 ? '#0a2e0a': 'white'}}>
                                    <p>R1</p>
                                </div>
                                <div className={classes.roundItem} onClick={() => {setRound(2)}} style={{background: round === 2 ? 'white': "none", color: round === 2 ? '#0a2e0a': 'white'}}>
                                    <p>R2</p>
                                </div>
                                <div className={classes.roundItem} onClick={() => {setRound(3)}} style={{background: round === 3 ? 'white': "none", color: round === 3 ? '#0a2e0a': 'white'}}>
                                    <p>R3</p>
                                </div>
                                <div className={classes.roundItem} onClick={() => {setRound(4)}} style={{background: round === 4 ? 'white': "none", color: round === 4 ? '#0a2e0a': 'white'}}>
                                    <p>R4</p>
                                </div>
                            </div>

                            {/* actions */}
                            <div className={classes.actionsContainer}>
                                <Tooltip title="Random results" placement='top'>
                                    <IconButton onClick={generateResults}>
                                        <ShuffleOnRoundedIcon className={classes.icon}/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Get players (solo antes de comenzar el torneo)" placement='top'>
                                    <IconButton onClick={handleGetPlayers}>
                                        <GroupsRoundedIcon className={classes.icon}/>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </div>
                        {playersList.length > 0 ? playersList.map((player,index) => (
                            <div key={index} className={classes.playerContainer}>
                                <div className={classes.playerHeader} onClick={() => {handlePlayerClick(player.name)}} style={{marginBottom: player.isOpen ? '15px' : '0px'}}>
                                    <div className={classes.playerImg} style={{backgroundImage: `url(.${player.img})`}}></div>
                                    <p className={classes.playerName}>{player.name}</p>

                                    {/* show */}
                                    <div className={classes.playerButtons}>
                                        <div className={classes.pointsItem}>
                                            <p className={classes.pointsNum2}>{player[`r${round}`].tot === 0 ? "-" : player[`r${round}`].tot}</p>
                                        </div>
                                        <Tooltip title="edit player" placement='top'>
                                            <IconButton>
                                                <>
                                                    {player.isOpen ? (  
                                                        <RemoveCircleOutlineRoundedIcon className={classes.playerIcon}/>
                                                    ) : (
                                                        <CreateRoundedIcon className={classes.playerIcon}/>
                                                    )}
                                                </>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </div>

                                {/* edit player data */}
                                <Collapse in={player.isOpen}>
                                    {/* Scorecard */}
                                    <div className={classes.scorecard}>
                                        <div className={classes.scorecardHeader}>
                                            <div className={classes.scorecardRowTitle}>
                                                <p>Hole</p>
                                            </div>
                                            <div className={classes.scorecardHoles}>
                                                {course.map((hole,i) => (
                                                    <div key={i} className={classes.scorecardHole}>
                                                        <p>{hole.num}</p>
                                                    </div>
                                                ))}
                                                <div className={classes.scorecardLast}>
                                                    <p>Tot</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.scorecardHeader}>
                                            <div className={classes.scorecardRowTitle}>
                                                <p>Par</p>
                                            </div>
                                            <div className={classes.scorecardHoles}>
                                                {course.map((hole,i) => (
                                                    <div key={i} className={classes.scorecardHole}>
                                                        <p>{hole.par}</p>
                                                    </div>
                                                ))}
                                                <div className={classes.scorecardLast}>
                                                    <p>{coursePar}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classes.scorecardRow}>
                                            <div className={classes.scorecardRowTitle}>
                                                <p>R{round}</p>
                                            </div>
                                            <div className={classes.scorecardHoles}>
                                                {player[`r${round}`].score.map((score,i) => (
                                                    <div key={i} className={classes.scorecardHole}>
                                                        <input name={`${player.name}_${round}_${i}`} className={classes.scorecardInput} value={playersList[index][`r${round}`].score[i]} onChange={(e) => {handleHoleChange(player.name,i,e.target.value)}}/>
                                                    </div>
                                                ))}
                                                <div className={classes.scorecardLast}>
                                                    <p>{player[`r${round}`].tot === 0 ? "-" : player[`r${round}`].tot}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* player points */}
                                    <div className={classes.pointsContainer}>
                                        <div className={classes.pointsItem}>
                                            <p className={classes.pointsNum}>{player[`r${round}`].ptsScore && player[`r${round}`].ptsScore}<span className={classes.pts}>pts</span></p>
                                            <p className={classes.pointsLabel}>Score</p>
                                        </div>
                                        <div className={classes.pointsItem}>
                                            <p className={classes.pointsNum}>{player[`r${round}`].ptsPos && fixedInt(player[`r${round}`].ptsPos)}<span className={classes.pts}>pts</span></p>
                                            <p className={classes.pointsLabel}>Pos</p>
                                        </div>
                                        <div className={classes.pointsItem}>
                                            <p className={classes.pointsNum}>{fixedInt(player[`r${round}`].ptsPos + player[`r${round}`].ptsScore)}<span className={classes.pts}>pts</span></p>
                                            <p className={classes.pointsLabel}>Tot</p>
                                        </div>
                                        
                                        {/* player place */}
                                        <div className={classes.pointsItem}>
                                            <input className={classes.placeInput} value={playersList[index][`r${round}`].pos} onChange={(e) => {handlePositionChange(player.name, Number(e.target.value))}}/>
                                            <p className={classes.pointsLabel}>Lugar</p>
                                        </div>
                                        <div className={classes.pointsItem}>
                                            <input className={classes.placeInput} value={playersList[index][`r${round}`].ties} onChange={(e) => {handleTiesChange(player.name, playersList[index][`r${round}`].pos, Number(e.target.value))}}/>
                                            <p className={classes.pointsLabel}>Empates</p>
                                        </div>

                                        {/* player passed cut */}
                                        <FormControlLabel
                                            control={
                                                <MySwitch sx={{ m: 1 }} 
                                                    defaultChecked={player.cut}
                                                    value={player.cut} 
                                                    onChange={(e) => {handlePlayerCutChange(player.name, e)}}
                                                />}
                                            label="Passed cut"
                                        />
                                    </div>

                                    
                                </Collapse>
                            </div>
                        )) :  (
                            <h1>No hay jugadores</h1>
                        )}
                        
                        
                        <WhiteButton onClick={handleSavePlayers}>Save players data</WhiteButton>
                        <WhiteButton onClick={handleUpdateLeaderboard}>Update leaderboard</WhiteButton>    
                    </div>

                    {/* Snackbars */}
                    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            Se agregaron los datos exitosamente
                        </Alert>
                    </Snackbar>

                    
                    {errors.length !== 0 && (
                        <div className={classes.messageContainer} style={{marginBottom: 20}}>
                            <ErrorOutlineRoundedIcon className={classes.infoIcon}/>
                            <ul>
                                {errors.map((err, i) => (
                                    <li key={i} className={classes.messageText}>{err}</li>
                                ))}
                            </ul>
                        </div> 
                    )}
                </div>
            </div>
            
        </>
    )
}

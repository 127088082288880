import React, { useEffect } from 'react';

//MaterialUI
import { makeStyles  } from "@material-ui/core/styles";

//Router dome
import { Link, Redirect } from "react-router-dom";

//redux
import { useSelector, useDispatch } from 'react-redux';
import { logout, setUsersRedux, setReduxPlayers } from '../../store/actions'

//Components
import { WhiteButton } from '../../utils/Buttons';
import Logos from '../../components/user/Logos'

//upload data
import firebase from '../../database/firebase'

//Constants
import { tournament } from '../../constants/tournament'

//CSS
const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        width: '100vw',
        maxWidth: '100vw',
        minHeight: '100vh',
        textAlign: 'center',
    },
    overlay: {
        width: "100%",
        height: "100%",
        maxWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 30%, #0e1d1deb 53%, #0e1d1de8 100%)',
        paddingTop: '30px'
    },
    title: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: 30,
        color: 'white',
    },

    /* action buttons */
    actionButtons: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 20,
        marginTop: 50,
        width: '90%',
        maxWidth: '600px',
        margin: 'auto',
        marginBottom: 150,
        [theme.breakpoints.down('720')]: {
            gridTemplateColumns: '1fr 1fr',
        },
        [theme.breakpoints.down('420')]: {
            gridTemplateColumns: '1fr',
        }
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        }
    }
}))

export default function Index({classes}) {
    classes = useStyles();

    //redux
    const dispatch = useDispatch()
    const user = useSelector(state => state.userState.user);

    const logoutUser = () => {
        dispatch(logout())
    }

    /* Fetch users from firebase */
    const fecthUsers =  () => {
        let data = []
        firebase.db.collection('users')
            .where('type', '==', 'user')
            .onSnapshot(snapshot => {
                snapshot.docs.forEach(doc => {
                    data.push({
                        ...doc.data(),
                        edited: false,
                    })
                })
                console.log(data)
                localStorage.setItem('reduxUsers', JSON.stringify(data))
                setUsersRedux(data)
            })
    }

    /* Fetch player from firebase */
    const fetchPlayers = () => {
        firebase.db.collection(tournament).doc('players').onSnapshot(doc => {
            if(doc.exists){
                dispatch(setReduxPlayers(doc.data()))
                console.log(doc.data())
            } else {
                dispatch(setReduxPlayers(null))
            }
        })
    }


    useEffect(() => {
       //logoutUser()

       //get players
       if(localStorage.getItem("reduxUsers") !== "null" && localStorage.getItem("reduxUsers") !== null) {
            console.log("localStoragge")
            dispatch(setUsersRedux(JSON.parse(localStorage.getItem("reduxUsers"))))
        } else {
            console.log("fecthUsers")
            fecthUsers()
        }

        //get players
        fetchPlayers()
    },[])



    return (
        <div>
            {!user && (
                <Redirect to="/"/>
            )}
            {user && user.type !== 'admin' && (
                <Redirect to="/inicio"/>
            )}
            <div className={classes.backgroundContainer}>
                <div className={classes.overlay}>
                    {/* header */}
                    <Logos/>
                    <h1 className={classes.title}>Bienvenido {user && user.nickname}</h1>

                    {/* action buttons */}
                    <div className={classes.actionButtons}>
                        <WhiteButton onClick={logoutUser}>Cerrar sesión</WhiteButton>
                        <Link to="/admin/users" className={classes.link}><WhiteButton>Editar usuarios</WhiteButton></Link>
                        
                        <Link to="/admin/players" className={classes.link}><WhiteButton>Editar jugadores</WhiteButton></Link>
                        <Link to="/leaderboard" className={classes.link}><WhiteButton>Ver tablero</WhiteButton></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react';

//firebase
import firebase from '../../database/firebase'

//router dom
import { Link, Redirect } from 'react-router-dom'

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from "../../store/actions";

//utils
import { WhiteButton } from '../../utils/Buttons'

//loader
import Loader from "react-loader-spinner";

//components
import Logos from '../../components/user/Logos';

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
    },
    overlay: {
        width: '100vw',
        height: '100vh',
        maxHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 40%, #0e1d1deb 53%, #0e1d1de8 100%)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: '30px'
    },
    infoContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center'
    },
    formContainer: {
        maxWidth: '600px',
        borderRadius: "20px",
        height: '300px',
        [theme.breakpoints.up(500)]: {
            minWidth: '450px'
        }
    },
    passwordInput: {
        flex: '1',
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '45px',
        border: 'none',
        background: "none",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    userInput: {
        width: '100%',
        padding: '15px',
        outline: 'none',
        borderRadius: '45px',
        border: '1px solid white',
        marginBottom: '20px',
        background: "none",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },
    passwordContainer: {
        display: 'flex',
        width: '100%',
        background: 'rgba(255,255,255,0)',
        borderRadius: '25px',
        border: '1px solid white',
        marginBottom: '20px',
    },
    errorContainer: {
        background: 'rgba(255,255,255,0.25)',
        borderRadius: 10,
        padding: 20,
        marginTop: 20
    },
    errorText: {
        color: 'white',
        fontSize: '25px'
    },
    link: {
        color: 'white',
        marginBottom: 10,
        textDecoration: 'none',
        '&:hover': {
            color: 'white'
        }
    },
    loaderContainer:  {
        margin: 'auto',
        width: 80,
        paddingTop: '30px'
    }
}))

export default function Index({classes}) {
    classes = useStyles();
    
    //redux
    const dispacth = useDispatch();
    const user = useSelector(state => state.userState.user);

    //State
    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
        loading: false,
        error: ""
    });

    //useEffect
    useEffect(() => {

        const unsuscribe = firebase.firebase.auth().onAuthStateChanged(userData => {
            if(userData != null){
                //check if user is in redux
                if(user == null){
                    
                    firebase.db.collection("users").doc(userData.uid).onSnapshot(snapshot => {
                        dispacth(setUser({
                            email: snapshot.data().email,
                            type: snapshot.data().type,
                            nickname: snapshot.data().nickname,
                            id: userData.uid,
                            hasSelected: snapshot.data().hasSelected,
                            hasPaid: snapshot.data().hasPaid,
                            uid: snapshot.data().uid,
                            points: snapshot.data().points || 0,
                            data: snapshot.data().data || null,
                            teams: snapshot.data().teams || null,
                            numTeams: snapshot.data().numTeams || 1,
                            paidTeams: Number(snapshot.data().paidTeams) || 0
                        }));
                    })
                }
            }

        })
        return unsuscribe
    },[])

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const handleLogin = (e) => {
        e.preventDefault();
        setValues({...values, loading: true})

        const { email, password } = values;

        firebase.firebase.auth().signInWithEmailAndPassword(email, password)
        .then((result) => {
            setValues({...values, error: ""})
            console.log(result.user)
            firebase.db.collection("users").doc(result.user.uid).onSnapshot(snapshot => {
                dispacth(setUser({
                    email: snapshot.data().email,
                    type: snapshot.data().type,
                    id: result.user.uid,
                    nickname: snapshot.data().nickname,
                    hasSelected: snapshot.data().hasSelected,
                    hasPaid: snapshot.data().hasPaid,
                    uid: snapshot.data().uid,
                    points: snapshot.data().points,
                    data: snapshot.data().data,
                    teams: snapshot.data().teams,
                    numTeams: snapshot.data().numTeams,
                    paidTeams: snapshot.data().paidTeams
                }));
                console.log(snapshot.data())
                console.log("success")
            })
            

        })
        .catch((error) => {
            //console.log(error.message)
            var errorMessage = error.message
            if(errorMessage === "There is no user record corresponding to this identifier. The user may have been deleted."){
                errorMessage = "No existe el usuario"
            } else if(errorMessage === "The email address is badly formatted."){
                errorMessage = "Formato de email incorrecto"
            } else{
                errorMessage = "Contraseña incorrecta. Favor de ingresarla de nuevo."
            }
            setValues({...values, error: errorMessage})
        })

    }

    return (
        <div className={classes.backgroundContainer}>
            <div className={classes.overlay}>
                {/* <img src='./img/login/logo.png' className={classes.logo}/> */}
                {/* logo */}
                <Logos/>
                <div className={classes.infoContainer}>
                    <form className={classes.formContainer}>
                        <input type="hidden" value="something"/>
                        <div style={{display: 'flex'}}>
                            <input 
                                type="text" 
                                placeholder="Usuario"
                                autoCapitalize="none"
                                name='text'
                                value={values.email}
                                onChange={(e) => setValues({...values, email: e.target.value})}
                                className={classes.userInput}
                            />
                        </div>
                        <input type="hidden" value="something2"/>
                        <div className={classes.passwordContainer}>
                            <input 
                                type={values.showPassword ? 'text' : 'password'}
                                placeholder="Contraseña"
                                autoCapitalize="none"
                                name='text'
                                value={values.password}
                                onChange={(e) => setValues({...values, password: e.target.value})}
                                className={classes.passwordInput}
                            />
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                >
                                {values.showPassword ? <Visibility  style={{color: 'white'}}/> : <VisibilityOff style={{color: 'white'}}/>}
                            </IconButton>
                        </div>
                        <div>
                            <div style={{background: 'none', textAlign: 'right', marginBottom: '20px'}}>
                                <Link to="/forgot-password" className={classes.link}>¿Olvidaste la contraseña? <u>Recuperala</u></Link>
                            </div>
                            <WhiteButton type="submit" onClick={handleLogin} style={{marginBottom: '20px'}}>Iniciar sesión</WhiteButton>
                            
                            <div style={{background: 'none', textAlign: 'center', marginTop: '20px'}}>
                                <Link to="/sign-up" className={classes.link}>¿Aún no te registras? <u>Regístrate</u></Link>
                            </div>
                        </div>
                        
                        {values.error !== "" && (
                            <div className={classes.errorContainer}>
                                <h2 className={classes.errorText}>{values.error}</h2>
                            </div>
                        )}
                        {/* Loading */}
                        {values.loading && (
                            <div className={classes.loaderContainer}>
                                <Loader  type="ThreeDots" color="#FFFFFF" height={100} width={100} />
                            </div>
                        )}


                        {/* redirect */}
                        {user && (
                            <>
                                {user.type === "admin" ? (
                                    <Redirect to="/admin"/>
                                ) : (
                                    <>
                                        {user.hasSelected ? (
                                            <Redirect to="/home"/>
                                        ) : (
                                            <Redirect to="/home"/>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    )
}

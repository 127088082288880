import React, { useEffect, useState } from 'react';

//MaterialUI
import { makeStyles  } from "@material-ui/core/styles";
import { IconButton, Tooltip } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

//Materil UI Icons
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ReplayCircleFilledRoundedIcon from '@mui/icons-material/ReplayCircleFilledRounded';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';

//Router dome
import { Link, Redirect } from "react-router-dom";

//redux
import { useSelector, useDispatch } from 'react-redux';
import { setUsersRedux } from '../../store/actions'

//Components
import { WhiteButton } from '../../utils/Buttons';
import Logos from '../../components/user/Logos'

//upload data
import firebase from '../../database/firebase'

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

//CSS
const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
        position: 'relative',
        width: '100vw',
        maxWidth: '100vw',
        minHeight: '100vh',
        textAlign: 'center',
    },
    overlay: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 30%, #0E191C 53%, #0E191C 100%)',
        paddingTop: 30,
        paddingBottom: 50,
        textAlign: 'center',
        color: 'white',
    },
    title: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: 30
    },
    subtitle: {
        fontSize: '35px',
        marginBottom: 20,
        marginTop: 100,
        fontWeight: 'bold',
        color: 'white',
        [theme.breakpoints.down('720')]: {
            fontSize: '30px',
        },
        [theme.breakpoints.down('420')]: {
            fontSize: '25px',
        }
    },

    /* action buttons */
    actionButtons: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: 20,
        marginTop: 50,
        width: '70%',
        maxWidth: '600px',
        margin: 'auto',
        marginBottom: 50,
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        }
    },

    /* filter */
    filterInput: {
        width: '80%',
        maxWidth: '600px',
        padding: '15px',
        outline: 'none',
        borderRadius: '45px',
        border: '1px solid white',
        margin: 'auto',
        marginBottom: '20px',
        background: "none",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },

    numUsers: {
        fontSize: '30px',
        fontWeight: 'bold',
        color: 'white',
        marginBottom: 0,
    },

    /* users */
    userContainer: {
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
        marginTop: 5,
        marginBottom: 5,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'white'
    },
    userText: {
        fontSize: '14px',
        fontWeight: 'bolder',
        color: 'white',
        marginBottom: 0,
        textAlign: 'left'
    },
    iconButton: {
        color: 'white',
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)',
        }
    },

    /* cells */
    usersContainer: {
        overflowX: 'scroll',
        padding: '20px'
    },
    userCell: {
        width: '250px',
        minWidth: '250px',
        marginRight: '10px',
        textAlign: 'left'
    },
    userCellSm: {
        width: '50px',
        minWidth: '50px'
    },
    userCellLg: {
        width: '150px',
        minWidth: '150px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    input: {
        background: 'none',
        outline: 'none',
        border: '1px solid white',
        borderRadius: '10px',
        color: 'white',
        width: '40px',
        padding: '5px'
    }
}))

export default function Users({classes}) {
    classes = useStyles();

    //redux
    const dispatch = useDispatch()
    const user = useSelector(state => state.userState.user);
    const users = useSelector(state => state.usersState.users);

    //state
    //const [users, setUsers] = useState([])
    const [usersList, setUsersList] = useState([])
    const [filter, setFilter] = useState('')
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('success')

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMenuCloseClick = (num) => {
        setAnchorEl(null);
        if(num === 0){
            setUsersList(users)
        } else if(num === 1){
            setUsersList(users.filter(user => user.hasPaid !== true))
        } else if(num === 2) {
            setUsersList(users.filter(user => user.hasPaid === true))
        } else if(num === 3){
            setUsersList(users.filter(user => user.hasSelected === true))
        } else {
            setUsersList(users.filter(user => user.hasSelected !== true))
        }
    }   

    const fecthUsersFirebase =  (flag) => {
        if(flag == true){
            let data = []
            firebase.db.collection('users')
                .where('type', '==', 'user')
                .onSnapshot(snapshot => {
                    snapshot.docs.forEach(doc => {
                        data.push({
                            ...doc.data(),
                            edited: false,
                        })
                    })
                    console.log(data)
                    //filter data for unique uid
                    let unique = [...new Map(data.map(item => [item.uid, item])).values()]
                    console.log(unique)

                    localStorage.setItem('reduxUsers', JSON.stringify(unique))
                    //setUsers(data)
                    dispatch(setUsersRedux(unique))
                    setUsersList(unique)
                })
        }
        

    }

    useEffect(() => {
        console.log(users)
        if(users) {
            console.log("redux users")
            
            setUsersList(users);
        } else {
            console.log("fecthUsersFirebase")
            fecthUsersFirebase(true)
        }
    },[])

    const handleFilterChange = (e) => {
        setFilter(e.target.value)
        let data = users.filter(user => user.email.toLowerCase().includes(e.target.value.toLowerCase()))
        setUsersList(data)
    }

    const handleUserChange = (uid, paidTeams) => {
        let data = [...users]
        let index = data.findIndex(user => user.uid === uid)
        
        data[index].edited = true
        data[index].paidTeams = Number(paidTeams)
        
        data[index].hasPaid = Number(paidTeams) > 0

        console.log(data)
        localStorage.setItem('reduxUsers', JSON.stringify(data))
        dispatch(setUsersRedux(data))

        if(filter !== "") {
            data = users.filter(user => user.email.toLowerCase().includes(filter.toLowerCase()))
        } 
        setUsersList(data)
    }

    const handleSaveUsers = (e) => {
        e.preventDefault()
        let data = [...users]
        data.forEach(item => {
            if(item.edited) {
                firebase.db.collection('users').doc(item.uid).update({
                    paidTeams: item.paidTeams,
                    hasPaid: item.hasPaid
                })
            }
        })
        console.log("data saved", data)
        localStorage.setItem('reduxUsers', JSON.stringify(data))
        //setOpen(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }

        setOpen(false);
    };

    const handleUserDelete = (uid, e) => {
        e.preventDefault()
        let data = [...users]
        let index = data.findIndex(user => user.uid === uid)
        data.splice(index, 1)
        dispatch(setUsersRedux(data))
        if(filter !== "") {
            data = users.filter(user => user.email.toLowerCase().includes(filter.toLowerCase()))
        }
        setUsersList(data)

        //delete user from firebase
        firebase.db.collection('users').doc(uid).delete()
        setOpen(true)
        setSeverity('success')
        setMessage('Usuario eliminado exitosamente')
    }

    const handleResetUsers = () => {
        let data = [...users]
        data.forEach(user => {
            firebase.db.collection('users').doc(user.uid).set({
                hasPaid: false,
                hasSelected: false,
                nickname: user.nickname,
                phone: user.phone,
                email: user.email,
                type: user.type,
                uid: user.uid,
                paidTeams: 0,
                numTeams: 0
            }).then(() => {
                setOpen(true)
                setSeverity('success')
                setMessage('Usuario reseteados exitosamente')
            }).catch((err) => {
                console.log(err)
                setOpen(true)
                setSeverity('error')
                setMessage('Error al resetear usuarios')
            })
        })
        //console.log("data saved", data)
        localStorage.setItem('reduxUsers', JSON.stringify(data))
    }

    return (
        <div>
            {!user && (
                <Redirect to="/"/>
            )}
            {user && user.type !== 'admin' && (
                <Redirect to="/inicio"/>
            )}
            <div className={classes.backgroundContainer}>
                <div className={classes.overlay}>
                    {/* header */}
                    <Logos/>
                    <h1 className={classes.title}>Bienvenido {user && user.nickname}</h1>

                    {/* action buttons */}
                    <div className={classes.actionButtons}>
                        <Link to="/admin" className={classes.link}><WhiteButton>Regresar</WhiteButton></Link>
                    </div>

                    {/* editar usuarios */}
                    <h2 className={classes.subtitle}>Editar los usuarios</h2>

                    <input className={classes.filterInput} placeholder="type email" value={filter} onChange={handleFilterChange}/>

                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 30}}>
                        {users && (
                            <p className={classes.numUsers}>{usersList.length}/{users.length}</p>
                        )}
                        <IconButton className={classes.iconButton} onClick={() => {fecthUsersFirebase(true)}}>
                            <ReplayCircleFilledRoundedIcon/>
                        </IconButton>
                        <IconButton 
                            className={classes.iconButton}
                            id="fade-button"
                            aria-controls={menuOpen ? 'fade-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={menuOpen ? 'true' : undefined}
                            onClick={handleMenuClick}
                        >
                            <FilterListRoundedIcon/>
                        </IconButton>
                        {/* <Tooltip title="Resetear usuarios" placement='top'>
                            <IconButton className={classes.iconButton} onClick={() => {handleResetUsers()}}>
                                <DeleteRoundedIcon/>
                            </IconButton>
                        </Tooltip> */}
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                            'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={menuOpen}
                            onClose={handleMenuClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={() => {handleMenuCloseClick(0)}}>Todos</MenuItem>
                            <MenuItem onClick={() => {handleMenuCloseClick(1)}}>No han pagado</MenuItem>
                            <MenuItem onClick={() => {handleMenuCloseClick(2)}}>Ya pagaron</MenuItem>
                            <MenuItem onClick={() => {handleMenuCloseClick(3)}}>Ya tienen equipo</MenuItem>
                            <MenuItem onClick={() => {handleMenuCloseClick(4)}}>No tienen equipo</MenuItem>
                        </Menu>
                    </div>
                    
                    <div className={classes.usersContainer}>
                        {/* table header */}
                        <div className={classes.userContainer}>
                            <div className={classes.userCell}>
                                <p className={classes.userText}>Mail</p>
                            </div>
                            <div className={classes.userCell}>
                                <p className={classes.userText}>Nombre</p>
                            </div>
                            <div className={classes.userCell}>
                                <p className={classes.userText}>Teléfono</p>
                            </div>
                            <div className={classes.userCellSm}>
                                <p className={classes.userText}>Equipo</p>
                            </div>
                        
                            <div className={classes.userCellLg}>
                                <p className={classes.userText}>Pago</p>
                            </div>
                        </div>

                        {usersList.length !== 0 && usersList.map((user, index) => (
                            <div key={index} className={classes.userContainer} style={{opacity: 0.75}}>
                                <div className={classes.userCell}>
                                    <p className={classes.userText}>{user.email}</p>
                                </div>
                                <div className={classes.userCell}>
                                    <p className={classes.userText}>{user.nickname}</p>
                                </div>
                                <div className={classes.userCell}>
                                    <p className={classes.userText}>{user.phone}</p>
                                </div>
                                <div className={classes.userCellSm}>
                                    <p className={classes.userText}>{user.hasSelected ? user.numTeams ? user.numTeams : 1 : 0}</p>
                                </div>
                            
                                <div className={classes.userCellLg}>
                                    {/* <p className={classes.userText}>{user.hasPaid ? "Yes" : "No"}</p> */}
                                    <input type="number" value={user.paidTeams} onChange={(e) => {handleUserChange(user.uid, e.target.value)}} className={classes.input}/>
                                    {/* <IconButton className={classes.iconButton} onClick={(e) => {handleUserDelete(user.uid, e)}}>
                                        <DeleteRoundedIcon style={{color: 'white'}}/>
                                    </IconButton> */}
                                </div>
                            </div>
                        ))}  
                    </div>

                    {/* id edited user */}
                    {users && users.filter(user => user.edited === true).length !== 0 && (
                        <div style={{maxWidth: 600, margin: 'auto'}}>
                            <WhiteButton onClick={(e) => {handleSaveUsers(e)}}>Save users</WhiteButton>
                        </div>
                    )}

                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                            {message}
                        </Alert>
                    </Snackbar>
                </div>
            </div>
        </div>
    )
}


import React, { useState, useEffect } from 'react'

//firebase
import firebase from '../../database/firebase'

//redux
import { useSelector, useDispatch } from 'react-redux';
import { logout, setUser, setReduxPlayers } from '../../store/actions'
import { Redirect } from 'react-router';

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { IconButton } from '@material-ui/core';

//data
import players from '../../constants/players';
import typePlayers from '../../constants/typePlayers'
import memberType from '../../constants/memberType';
import { tournament, title, fechaLim, year } from '../../constants/tournament';

//components
import { WhiteButton } from '../../utils/Buttons';
import Logos from '../../components/user/Logos';

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        overflowX: 'hidden'
    },
    overlay: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 30%, #0e1d1deb 53%, #0e1d1de8 100%)',
        paddingTop: 30,
        paddingBottom: 50,
        textAlign: 'center',
        color: 'white',
    },

    /* welcome */
    welcome__container: {
        width: '98%',
        maxWidth: '1200px',
        textAlign: 'center',
        margin: 'auto',
    },
    welcome: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginTop: 30,
        marginBottom: 20,
    },
    subtitle: {
        fontSize: '35px',
        marginBottom: 20,
        marginTop: 100,
        fontWeight: 'bold',
        [theme.breakpoints.down('720')]: {
            fontSize: '30px',
        },
        [theme.breakpoints.down('420')]: {
            fontSize: '20px',
        }
    },
    logoutButton: {
        width: '200px',
        margin: 'auto',
        marginBottom: '20px'
    },

    /* game - group round */
    gamesContainer: {
        width: '98%',
        maxWidth: '800px',
        margin: 'auto',
    },

    /* Selected players */
    selectedPlayersContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        overflowX: 'scroll',
        padding: '20px 10px',
        borderRadius: '10px',
        border: '2px solid white',
        margin: '10px',
        maxWidth: '800px',
        [theme.breakpoints.up('800')]: {
            margin: 'auto'
        }
    },
    typePlayerContainer: {
        background: 'none',
        minWidth: '80px',
        padding: '5px',
        border: '2px solid rgba(0,0,0,0)',
        borderRadius: '10px',
    },
    typePlayerImg: {
        width: '60px',
        height: '60px',
        margin: 'auto',
        marginBottom: 5,
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    typePlayerName: {
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    typePlayerText: {
        color: 'white',
        fontSize: '12px',
        opacity: 0.75
    },
    removeIcon: {
        position: 'relative',
        right: '-20px',
        top: '-40px',
        marginBottom: '-50px'
    },

    /* filter */
    filterContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 10px',
        maxWidth: '800px',
        [theme.breakpoints.up('800')]: {
            margin: 'auto'
        }
    },
    filterText: {
        flex: 1,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '0px',
        marginRight: '10px',
        textAlign: 'left'
    },
    filterInput: {
        //width: '100%',
        padding: '10px',
        outline: 'none',
        borderRadius: '45px',
        border: '1px solid white',
        background: "none",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },

    /* players list */
    playerList: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: '10px',
        padding: '10px',
        maxHeight: '300px',
        overflowY: 'scroll',
        borderRadius: '10px',
        border: '2px solid white',
        margin: '10px',
        maxWidth: '800px',
        [theme.breakpoints.up('800')]: {
            margin: 'auto',
        }
    },
    playerContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    playerImg: {
        width: '40px',
        height: '40px',
        marginRight: '10px',
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    playerName: {
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
        flex: 1,
        textAlign: 'left'
    },
    iconBtn: {
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    icon: {
        color: 'white !important'
    },

    /* error message */
    messageContainer: {
        background: 'rgba(255,255,255,0.05)',
        borderRadius: 10,
        padding: 20,
        margin: 10,
        marginTop: 20,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        maxWidth: '784px',
        [theme.breakpoints.up('800')]: {
            margin: 'auto',
            marginTop: 20,
        }
    },
    infoIcon: {
        width: '30px !important',
        height: '30px !important',
        marginRight: 20,
        color: 'white'
    },
    messageText: {
        color: 'white',
        fontSize: '14px',
        marginBottom: 0,
        textAlign: 'left',
    },

    /* action buttons */
    actionButton: {
        maxWidth: '824px',
        margin: '10px',
        [theme.breakpoints.up('800')]: {
            margin: 'auto',
            marginTop: 20,
        }
    }
}))


export default function Selection({classes}) {
    classes = useStyles();
    
    //redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userState.user);
    const reduxPlayers = useSelector(state => state.playersState.players);

    //State
    const [selectedPlayers, setSelectedPlayers] = useState([])
    const [selectedType, setSelectedType] = useState('Capitán')
    const [playerFilter, setPlayerFilter] = useState([])
    const [allPlayers, setAllPlayers] = useState([])
    const [playersList, setPlayersList] = useState([])
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        let tempSelected = []
        typePlayers.forEach((typePlayer,i) => {
            tempSelected.push(
                {
                    name: '--',
                    img: './img/players/person.png',
                    inSelection: typePlayer === "Capitán",
                    isSelected: false,
                    type: typePlayer
                }
            )
        })
        setSelectedPlayers(tempSelected)

        let tempPlayers = []
        players.forEach((player) => {
            tempPlayers.push({
                ...player,
                isSelected: false,
                selectedAs: ''
            })
        })
        setPlayersList(tempPlayers)
        setAllPlayers(tempPlayers)
        fetchPlayers()

    }, [])

    /* logout */
    const logoutUser = () => {
        dispatch(logout())
    }

    /* Fetch players */
    const fetchPlayers = () => {
        firebase.db.collection(tournament).doc('players').onSnapshot(doc => {
            if(doc.exists){
                dispatch(setReduxPlayers(doc.data()))
                console.log(doc.data())
            } else {
                console.log("players.. not created")
                dispatch(setReduxPlayers(null))
            }
        })
    }


    /* handle Selected Player Click */
    const handleSelectedPlayerClick = (index) => {
        let temp = [...selectedPlayers]
        temp.map((player,i) => {
            player.inSelection = i === index
        })
        console.log(allPlayers)
        setSelectedType(temp[index].type)
        setSelectedPlayers(temp)
    }


    /* handle filter change */
    const handleFilterChange = (value) => {
        setPlayerFilter(value)
        let data = allPlayers.filter(player => player.name.toLowerCase().includes(value.toLowerCase()))
        //setAllPlayers(data)
        setPlayersList(data)
    }

    /* handle player click */
    const handlePlayerClick = (action, name) => {
        let temp = [...selectedPlayers]
        let playersTemp = [...allPlayers]
        let index = selectedPlayers.findIndex(plr => plr.inSelection === true)

        if(action === "add"){
            let player = allPlayers.find(plr => plr.name === name)
            let i = allPlayers.findIndex(plr => plr.name === name)

            if(temp[index].name !== '--') {
                let i2 = allPlayers.findIndex(plr => plr.name === temp[index].name)
                playersTemp[i2].isSelected = false
            }
            

            //players list
            if(playersTemp[i].isSelected){
                if(playersTemp[i].selectedAs !== selectedType) {
                    selectedPlayers.forEach((plr,index2) => {
                        if(plr.name === playersTemp[i].name){
                            temp[index2].name = '--'
                            temp[index2].img = './img/players/person.png'
                            temp[index2].isSelected = false
                        }
                    })
                }
            }

            temp[index].name = player.name
            temp[index].img = player.img
            temp[index].isSelected = true
            playersTemp[i].isSelected = true
            playersTemp[i].selectedAs = selectedType

            //calculate next index
            let next = index
            let selected = [0,0,0,0,0,0]
            let found = false
            selected.forEach((el,k) => {selected[k] = temp[k].isSelected ? 1 : 0})

            while(next < 5 && !found){
                next += 1;
                if(!selected[next]){
                    found = true
                }
            }
            if(!found) {
                next = 0
                found = false
                while(next < 5 && !found){
                    if(!selected[next]){
                        found = true
                    } else {
                        next += 1;
                    }
                }
            }
            if(!found) {
                console.log("All are selected")
                setErrors([])
            } else {
                handleSelectedPlayerClick(next)
            }
            
        } else {
            index = selectedPlayers.findIndex(plr => plr.name === name)
            temp[index].name = '--'
            temp[index].img = './img/players/person.png'
            temp[index].isSelected = false

            //players list
            let i = allPlayers.findIndex(plr => plr.name === name)
            playersTemp[i].isSelected = false
            playersTemp[i].selectedAs = ''
        }

        setPlayerFilter('')
        setAllPlayers(playersTemp)
        setPlayersList(playersTemp)
        setSelectedPlayers(temp)
        if(action === "remove"){
            handleSelectedPlayerClick(index)
        }
    }

    const verifyData = () => {
        //let found = false
        let temp = []
        selectedPlayers.forEach((player,i) => {
            if(!player.isSelected || player.name === "--"){
                let str = "Falta agregar tu " + player.type
                temp.push(str)
            }
        })
        console.log(temp)
        setErrors(temp)
        return temp.length === 0
    }


    const uploadData = () => {
        console.log("uploading data")
        firebase.db.collection("users").doc(user.uid).update({
            data: selectedPlayers,
            hasSelected: true,
            numTeams: 1
        }, {
            merge: true
        }).catch(error => {
            console.log(error);
            setErrors(["Error al guardar los datos"]);
        }).then(() => {
            dispatch(setUser({
                ...user,
                hasSelected: true,
                data: selectedPlayers,
                numTeams: 1
            }))
        })

        
    }

    const handleSaveData = () => {
        if(verifyData()){
            //save data
            console.log(selectedPlayers)
            uploadData()
        }
    }

    return (
        <>
            {!user && (
                <Redirect to="/"/>
            )}
            {user && user.hasSelected && (
                <Redirect to="/home#header"/>
            )}
            <div className={classes.backgroundContainer}>
                <div className={classes.overlay}>
                    {/* welcome message */}
                    <div className={classes.welcome__container}>
                        <Logos/>
                        <h2 className={classes.welcome}>Hola {user && user.nickname}, ¡Selecciona a tu equipo!</h2>        
                        <div className={classes.logoutButton}>
                            <WhiteButton onClick={logoutUser}>Cerrar sesión</WhiteButton>
                        </div>
                        <div className={classes.messageContainer} style={{marginBottom: '30px'}}>
                            <p className={classes.messageText} style={{textAlign: 'center'}}>
                                ¡Bienvenido! Ahora puede seleccionar su primer equipo. Una vez registrado su pago, tendrá la oportunidad de crear más equipos, 
                                siendo cada uno su propia apuesta, es decir, cada equipo tiene un costo de $1,000 pesos y lo uniremos a nuestro sistema 
                                como nuevo miembro de la quiniela {title} {year}. ¡Muchas gracias por su participación y le deseamos la mejor a las suertes!
                            </p>
                        </div> 
                    </div>

                    {/* tipo de jugadores */}
                    <div className={classes.selectedPlayersContainer}>
                        {selectedPlayers.map((player, i) => (
                            <div key={i} className={classes.typePlayerContainer} style={{border: player.inSelection ? '1px solid white': 'none', marginRight: i === 5 ? '0px': '20px'}}>
                                <div onClick={() => {handleSelectedPlayerClick(i)}} className={classes.typePlayerImg} style={{backgroundImage: `url(${player.img})`, opacity: player.isSelected ? 1 : 0.75}}></div>
                                {player.isSelected && (
                                    <IconButton onClick={() => {handlePlayerClick("remove", player.name)}} className={classes.removeIcon}>
                                        <RemoveCircleOutlineRoundedIcon className={classes.icon}/>
                                    </IconButton>
                                )}
                                <p className={classes.typePlayerName}>{player.name}</p>
                                <p className={classes.typePlayerText}>{player.type} | ({memberType[i].per})</p>
                            </div>
                        ))}
                    </div>

                    {/* filter */}
                    <div className={classes.filterContainer}>
                        <p className={classes.filterText}>Selecciona tu {selectedType}</p>
                        <input 
                            type="text" 
                            placeholder="Nombre de jugador"
                            autoCapitalize="none"
                            name='text'
                            value={playerFilter}
                            onChange={(e) => handleFilterChange(e.target.value)}
                            className={classes.filterInput}
                        />
                    </div>

                    {/* list of players */}
                    <div className={classes.playerList}>
                        {playersList && playersList.map((player, i) => (
                            <div key={i} className={classes.playerContainer}>
                                <div className={classes.playerImg} style={{backgroundImage: `url(${player.img})`}}></div>
                                <p className={classes.playerName}>{player.name}</p>
                                {(player.isSelected && player.selectedAs === selectedType) ? (
                                    <IconButton onClick={() => {handlePlayerClick("remove", player.name)}} className={classes.iconBtn}>
                                        <RemoveCircleOutlineRoundedIcon className={classes.icon}/>
                                    </IconButton>
                                ) : (
                                    <IconButton onClick={() => {handlePlayerClick("add", player.name)}} className={classes.iconBtn}>
                                        <AddCircleOutlineRoundedIcon className={classes.icon}/>
                                    </IconButton>
                                )}
                            </div>
                        ))}
                    </div>

                    {/* info message */}
                    <div className={classes.messageContainer}>
                        <InfoRoundedIcon className={classes.infoIcon}/>
                        <p className={classes.messageText}>Podrás editar tu equipo hasta el {fechaLim} a las 11:59pm (CDMX)</p>
                    </div> 

                    {/* Save team data */}
                    <div className={classes.actionButton}>
                        <WhiteButton onClick={handleSaveData}>Guardar equipo</WhiteButton>
                    </div>
                    {errors.length !== 0 && (
                        <div className={classes.messageContainer} style={{marginBottom: 20}}>
                            <ErrorOutlineRoundedIcon className={classes.infoIcon}/>
                            <ul>
                                {errors.map((err, i) => (
                                    <li key={i} className={classes.messageText}>{err}</li>
                                ))}
                            </ul>
                        </div> 
                    )}
                </div>
            </div>
            
        </>
    )
}

//router
import { Link } from 'react-router-dom';

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import TextSnippetRoundedIcon from '@mui/icons-material/TextSnippetRounded';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';
import SportsGolfOutlinedIcon from '@mui/icons-material/SportsGolfOutlined';
import SportsGolfTwoToneIcon from '@mui/icons-material/SportsGolfTwoTone';

const useStyles = makeStyles((theme) =>({
    menuContainer: {
        position: 'fixed',
        bottom: '0px',
        left: '0px',
        width: '100vw',
        maxWidth: '100vw',
        overflow: 'hidden',
        height: '70px',
        background: '#0E1D1D',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '2px'
    },
    menuItem: {
        flex: 1,
        padding: '5px',
        paddingBottom: '20px',
        borderRadius: '5px',
        //margin: '2px'
    },
    menuText: {
        fontSize: '10px',
        marginBottom: '0px'
    },
    menuLink: {
        flex: 1,
        color: 'white !important',
        textDecoration: 'none !important'
    }
}))

export default function Home({classes, page}) {
    classes = useStyles();

    return (
        <div className={classes.menuContainer}>
            <Link className={classes.menuLink} to="/home">
                <div className={classes.menuItem} style={{background: page === "home" ? "#193636" : "none"}}>
                    {page === "home" ? (
                        <HomeRoundedIcon className={classes.menuIcon}/>
                    ) : (
                        <HomeOutlinedIcon className={classes.menuIcon}/>
                    )}
                    <p className={classes.menuText}>Inicio</p>
                </div>
            </Link>
            <Link className={classes.menuLink} to="/leaderboard">
                <div className={classes.menuItem} style={{background: page === "leaderboard" ? "#193636" : "none"}}>
                    {page === "leaderboard" ? (
                        <LeaderboardRoundedIcon className={classes.menuIcon}/>
                    ) : (
                        <LeaderboardOutlinedIcon className={classes.menuIcon}/>
                    )}
                    <p className={classes.menuText}>Participantes</p>
                </div>
            </Link>
            <Link className={classes.menuLink} to="/players">
                <div className={classes.menuItem} style={{background: page === "players" ? "#193636" : "none"}}>
                    {page === "players" ? (
                        <SportsGolfTwoToneIcon className={classes.menuIcon}/>
                    ) : (
                        <SportsGolfOutlinedIcon className={classes.menuIcon}/>
                    )}
                    <p className={classes.menuText}>Jugadores</p>
                </div>
            </Link>
            <Link className={classes.menuLink} to="/rules">
                <div className={classes.menuItem} style={{background: page === "rules" ? "#193636" : "none"}}>
                    {page === "rules" ? (
                        <TextSnippetRoundedIcon className={classes.menuIcon}/>
                    ) : (
                        <TextSnippetOutlinedIcon className={classes.menuIcon}/>
                    )}
                    <p className={classes.menuText}>Reglas</p>
                </div>
            </Link>
        </div>
    )
}

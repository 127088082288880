import React, { useEffect, useState } from 'react'

//redux
import { useSelector, useDispatch } from 'react-redux';
import {setReduxLeaderboard } from '../../store/actions'
//MaterialUI
import { makeStyles } from "@material-ui/core/styles";

//firebase
import firebase from '../../database/firebase'

//utils
import fixedInt from '../../utils/functions/fixedInt';
import formatMoney from '../../utils/functions/formatMoney'
import { leaderboard } from '../../constants/tournament'

const useStyles = makeStyles((theme) =>({
    resultsContainer: {
        borderRadius: '10px',
        border: '2px solid white',
        padding: '10px',
        margin: '10px',
        maxWidth: '500px',
        [theme.breakpoints.up(520)]: {
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    resultsHeader: {
        display: 'flex',
        justifyContent: 'space-evenly',
        borderBottom: '1px solid white',
        paddingBottom: '4px',
        marginBottom: '10px'
    },
    resultsHeaderItem: {
        width: '50px',
        background: 'none',
        textAlign: 'left'
    },
    resultsHeaderText: {
        fontSize: '14px',
        fontWeight: 'bold'
    },
    resultsRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginBottom: '5px'
    },
    resultsRowItem: {
        width: '50px',
        background: 'none',
        textAlign: 'left'
    },
    resultsRowText: {
        fontSize: '12px',
        fontWeight: 'bolder'
    },
}))


export default function UserResults({classes, team}) {
    classes = useStyles();
    const [currentUser, setCurrentUser] = useState(null)
    const [total, setTotal] = useState(0)
    
    //redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userState.user);

    /* fecth leaderboard */
    const fetchLeaderboard = () => {
        firebase.db.collection(leaderboard).doc('leaderboard').onSnapshot(doc => {
            if(doc.exists) {
                dispatch(setReduxLeaderboard(doc.data()))
                console.log(doc.data())
                let tempUser = {}
                let tot = 0

                if(doc.data().r1){
                    doc.data().r1.forEach((item) => {
                        if(item.uid === user.uid) {
                            tot += item.money || 0
                            if(item.num === team) {
                                tempUser.r1Pos = item.position
                                tempUser.r1 = item.pts
                                tempUser.r1Mon = item.money || 0
                                tempUser.acum = item.pts
                            }
                        }
                    })
                }

                if(doc.data().r2){
                    doc.data().r2.forEach((item) => {
                        if(item.uid === user.uid) {
                            tot += item.money || 0
                            if(item.num === team) {
                                tempUser.r2Pos = item.position
                                tempUser.r2 = item.pts
                                tempUser.r2Mon = item.money || 0
                                tempUser.acum += item.pts
                            }
                        }
                    })
                }

                if(doc.data().r3){
                    doc.data().r3.forEach((item) => {
                        if(item.uid === user.uid) {
                            tot += item.money || 0
                            if(item.num === team) {
                                tempUser.r3Pos = item.position
                                tempUser.r3 = item.pts
                                tempUser.r3Mon = item.money || 0
                                tempUser.acum += item.pts
                            }
                        }
                    })
                }

                if(doc.data().r4){
                    doc.data().r4.forEach((item) => {
                        if(item.uid === user.uid) {
                            tot += item.money || 0
                            if(item.num === team) {
                                tempUser.r4Pos = item.position
                                tempUser.r4 = item.pts
                                tempUser.r4Mon = item.money || 0
                                tempUser.acum += item.pts
                            }
                        }
                    })
                }

                if(doc.data().r5){
                    doc.data().r5.forEach((item) => {
                        if(item.uid === user.uid) {
                            tot += item.money || 0
                            if(item.num === team) {
                                tempUser.r5Pos = item.position
                                tempUser.r5 = item.pts
                                tempUser.r5Mon = item.money || 0
                            }
                        }
                    })
                }

                

                console.log(tempUser)
                setCurrentUser(tempUser)
                setTotal(tot)
                
            } else {
                dispatch(setReduxLeaderboard(null))
            }
        })
    }

    useEffect(() => {
        console.log(team)
        fetchLeaderboard()
    }, [team])


    return (
        <div className={classes.resultsContainer}>
            <div className={classes.resultsHeader}>
                <div className={classes.resultsHeaderItem}>
                    <p className={classes.resultsHeaderText}>Ronda</p>
                </div>
                <div className={classes.resultsHeaderItem}>
                    <p className={classes.resultsHeaderText}>Pos.</p>
                </div>
                <div className={classes.resultsHeaderItem}>
                    <p className={classes.resultsHeaderText}>Pts.</p>
                </div>
                <div className={classes.resultsHeaderItem}>
                    <p className={classes.resultsHeaderText}>Dinero</p>
                </div>
            </div>
            <div className={classes.resultsRow}>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>R1</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>{(currentUser !== null && currentUser.r1Pos) ? currentUser.r1Pos : "-"}</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>{(currentUser !== null && currentUser.r1) ? fixedInt(currentUser.r1) : "-"}</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>${(currentUser !== null && currentUser.r1Mon) ? currentUser.r1Mon : "0"}</p>
                </div>
            </div>
            <div className={classes.resultsRow}>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>R2</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>{(currentUser !== null && currentUser.r2Pos) ? currentUser.r2Pos : "-"}</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>{(currentUser !== null && currentUser.r2) ? fixedInt(currentUser.r2) : "-"}</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>${(currentUser !== null && currentUser.r2Mon) ? currentUser.r2Mon : "0"}</p>
                </div>
            </div>
            <div className={classes.resultsRow}>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>R3</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>{(currentUser !== null && currentUser.r3Pos) ? currentUser.r3Pos : "-"}</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>{(currentUser !== null && currentUser.r3) ? fixedInt(currentUser.r3) : "-"}</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>${(currentUser !== null && currentUser.r3Mon) ? currentUser.r3Mon : "0"}</p>
                </div>
            </div>
            <div className={classes.resultsRow}>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>R4</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>{(currentUser !== null && currentUser.r4Pos) ? currentUser.r4Pos : "-"}</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>{(currentUser !== null && currentUser.r4) ? fixedInt(currentUser.r4) : "-"}</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>${(currentUser !== null && currentUser.r4Mon) ? fixedInt(currentUser.r4Mon) : "0"}</p>
                </div>
            </div>
            <div className={classes.resultsRow}>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>Final</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>{(currentUser !== null && currentUser.r5Pos) ? currentUser.r5Pos : "-"}</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>{(currentUser !== null && currentUser.acum) ? fixedInt(currentUser.acum) : "-"}</p>
                </div>
                <div className={classes.resultsRowItem}>
                    <p className={classes.resultsRowText}>${(currentUser !== null && currentUser.r5Mon) ? currentUser.r5Mon : "0"}</p>
                </div>
            </div>

            {/* final earnings */}
            <div className={classes.resultsRow} style={{borderTop: '2px solid white', paddingTop: '10px'}}>
                <div className={classes.resultsRowItem} style={{flex: 1, textAlign: 'center'}}>
                    <p className={classes.resultsRowText}>Ganacias totales: {formatMoney(total)}</p>
                </div>
            </div>

        </div>
    )
}

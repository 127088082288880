import { SET_LEADERBOARD } from "./actionTypes";

const initilaState = {
    leaderboard: null
}

const leaderboard = (state=initilaState, action) => {
    switch(action.type){
        case SET_LEADERBOARD:
            state = {
                ...state,
                leaderboard: action.data
            }
            break
        default:
            state = {...state}

    }
    return state
}

export default leaderboard
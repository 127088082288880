//Firebase
import { SET_LEADERBOARD } from "./actionTypes";

export const setReduxLeaderboard = (leaderboard) => {
    //localStorage.setItem("leaderboard", JSON.stringify(leaderboard));
    return {
        type: SET_LEADERBOARD,
        data: leaderboard
    }
}

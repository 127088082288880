//React
import React, { useEffect, useState } from 'react'

//redux
import { useSelector, useDispatch } from 'react-redux';
import { logout, setUser, setReduxPlayers } from '../../store/actions'
import { Redirect, Link } from 'react-router-dom';

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

//MaterialUI - icons
import { IconButton, Tooltip } from '@material-ui/core';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import ReportGmailerrorredRoundedIcon from '@mui/icons-material/ReportGmailerrorredRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import Logout from '@mui/icons-material/Logout';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

//Components
import { WhiteButton } from '../../utils/Buttons';
import { TransparentInput } from '../../utils/Select';
import MyMenu from '../../components/user/Menu'
import PlayerData from '../../components/user/PlayerData';
import UserResults from '../../components/user/UserResults'
import Logos from '../../components/user/Logos';

//data
import players from '../../constants/players';
import memberType from '../../constants/memberType';
import typePlayers from '../../constants/typePlayers'
import roundBase from '../../constants/roundBase';

//firebase
import firebase from '../../database/firebase'

//Constats
import { fechaLim, tournament } from '../../constants/tournament';
import lastDate from '../../constants/lastDate';

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        maxWidth: '100vw',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        overflow: 'hidden'
    },
    overlay: {
        minWidth: '100vw',
        maxWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 30%, #0e1d1deb 53%, #0e1d1de8 100%)',
        paddingTop: 30,
        paddingBottom: 100,
        textAlign: 'center',
        color: 'white',
        overflow: 'hidden !important'
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: 30,
        [theme.breakpoints.down(450)]: {
            fontSize: '22px'
        }
    },
    subtitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginLeft: '10px',
        marginBottom: '10px',
        maxWidth: '800px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto',
            marginBottom: '10px'
        }
    },
    subtitle: {
        fontSize: '20px',
        marginBottom: 20,
        marginTop: 10,
        fontWeight: 'bold',
        textAlign: 'left',
        flex: 1
    },
    icon: {
        color: 'white'  
    },
    selectContainer: {
        flex: 1,
        textAlign: 'left'
    },
    groupBtn: {
        background: 'white !important',
        borderRadius: '25px !important',
        color: '#0E191C !important',
        padding: '5px 10px !important',
        fontSize: '12px !important',
        marginRight: '10px !important',
        [theme.breakpoints.down(400)]: {
            fontSize: "10px",
            padding: '4px 8px !important'
        }
    },

    /* Selected players */
    selectedPlayersContainer: {
        borderRadius: '10px',
        border: '2px solid white',
        margin: '10px',
        paddingBottom: '10px',
        maxWidth: '800px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto'
        }
    },
    selectedPlayersInnerContainer: {
        display: 'flex',
        overflowX: 'scroll',
        padding: '10px 10px',
        marginBottom: '10px'
    },
    typePlayerContainer: {
        background: 'none',
        minWidth: '80px',
        marginRight: '20px',
        padding: '5px',
        border: '2px solid rgba(0,0,0,0)',
        borderRadius: '10px',
        cursor: 'pointer'
    },
    typePlayerImg: {
        width: '60px',
        height: '60px',
        margin: 'auto',
        marginBottom: 5,
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    typePlayerName: {
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    typePlayerText: {
        color: 'white',
        fontSize: '12px',
        opacity: 0.75
    },
    removeIcon: {
        position: 'relative',
        right: '-20px',
        top: '-40px',
        marginBottom: '-50px'
    },

    /* filter */
    filterContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 10px',
        maxWidth: '800px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto'
        }
    },
    filterText: {
        flex: 1,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '0px',
        marginRight: '10px',
        textAlign: 'left'
    },
    filterInput: {
        //width: '100%',
        padding: '10px',
        outline: 'none',
        borderRadius: '45px',
        border: '1px solid white',
        background: "none",
        color: 'white',
        '&::placeholder': {
            color: 'rgba(255,255,255,0.75)'
        }
    },

    /* paid team */
    paidContainer: {
        textAlign: 'left',
        padding: '10px 0px 0px 10px'
    },

    /* players list */
    playerList: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridGap: '10px',
        padding: '10px',
        maxHeight: '300px',
        overflowY: 'scroll',
        borderRadius: '10px',
        border: '2px solid white',
        margin: '10px',
        maxWidth: '780px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto'
        }
    },
    playerContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    playerImg: {
        width: '40px',
        height: '40px',
        marginRight: '10px',
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    playerName: {
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
        flex: 1,
        textAlign: 'left'
    },
    iconBtn: {
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)'
        }
    },
    icon: {
        color: 'white !important'
    },

    /* Menu */
    menuLink: {
        flex: 1,
        color: 'rgba(0, 0, 0, 0.75) !important',
        textDecoration: 'none !important'
    },
    
    /* error message */
    errorContainer: {
        background: 'rgba(255,255,255,0.05)',
        borderRadius: 10,
        padding: 20,
        margin: 10,
        marginTop: 20,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        maxWidth: '760px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto',
            marginTop: 20,
        }
    },
    messageContainer: {
        background: 'rgba(255,255,255,0.05)',
        borderRadius: 10,
        padding: 20,
        margin: 10,
        marginTop: 20,
        maxWidth: '760px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto',
            marginTop: 20,
        }
    },
    messageSubtitle: {
        color: 'white',
        fontSize: '25px',
        marginBottom: 10,
        textAlign: 'left',
        fontWeight: 'bold',
        [theme.breakpoints.down('420')]: {
            fontSize: '18px',
        }
    },
    messageText: {
        color: 'white',
        fontSize: '12px',
        marginBottom: 0,
        textAlign: 'left',
    },
    link: {
        color: '#40d6ed',
        textDecoration: 'none',
    },
    infoIcon: {
        width: '30px !important',
        height: '30px !important',
        marginRight: 20,
        color: 'white'
    },
    errorIcon: {
        width: '50px !important',
        height: '50px !important',
        marginRight: 20,
        color: 'white'
    },
    errorText: {
        color: 'white',
        fontSize: '25px',
        marginBottom: 0,
        textAlign: 'left',
        [theme.breakpoints.down('420')]: {
            fontSize: '18px',
        }
    },
    iconButton: {
        color: 'white',
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)',
        }
    },

    /* action buttons */
    actionButton: {
        maxWidth: '824px',
        margin: '10px',
        [theme.breakpoints.up('800')]: {
            margin: 'auto',
            marginTop: 20,
        }
    }
}))


export default function Home({classes}) {
    classes = useStyles();

    //redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userState.user);
    const reduxPlayers = useSelector(state => state.playersState.players)

    //state - user
    const [userHasPaid, setUserHasPaid] = useState(false);

    //useState - teams
    const [initialTeams, setInitialTeams] = useState([])
    const [teams, setTeams] = useState([])
    const [numTeams, setNumTeams] = useState(1);
    const [team, setTeam] = useState(1) 
    const [adding, setAdding] = useState(false)

    //useState - selected
    const [initialSeleced, setInitialSelected] = useState([])
    const [selectedPlayers, setSelectedPlayers] = useState([])
    const [selectedType, setSelectedType] = useState('Capitán')
    const [selectedPlayer, setSelectedPlayer] = useState('');
    const [playerFilter, setPlayerFilter] = useState([])

    //useState - players
    const [allPlayers, setAllPlayers] = useState([])
    const [playersList, setPlayersList] = useState([])

    //useState - utils
    const [errors, setErrors] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [changes, setChanges] = useState(false)
    //const [canEdit, setCanEdit] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    

    //useState - menu
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    const logoutUser = () => {
        dispatch(logout())
    }

    /* handle refresh users */
    const refreshUser = () => {
        if(user){
            firebase.db.collection('users').doc(user.id).onSnapshot((doc) => {
                let newUser = user;
                if(doc.data().hasPaid && doc.data().hasSelected){
                    newUser.hasPaid = doc.data().hasPaid;
                    setUserHasPaid(doc.data().hasPaid)
                    dispatch(setUser(newUser))
                    fetchPlayers()
                }
            })
        }  
    }


    /* reset data */
    const resetData = () => {
        setUserHasPaid(user.hasPaid)
        let tempTeams = []
        let tempSelected = []
        user.data.forEach((player) => {
            tempSelected.push({
                ...player,
                inSelection: player.type === "Capitán"
            })
        })
        
        tempTeams.push({team: user.data, num: 1})
        //add teams
        if(user.numTeams > 1){
            user.teams.forEach((item) => {
                let temp = []
                item.team.forEach((item2) => {
                    temp.push({
                        ...item2,
                        inSelection: item2.type === "Capitán"
                    })
                })
                tempTeams.push({
                    ...item,
                    team: temp
                })
            })
            setNumTeams(user.numTeams)
        }

        setTeams(tempTeams)
        setSelectedPlayer(user.data[0].name)
        setSelectedPlayers(tempSelected)
        setInitialSelected(user.data)
        //setSelectedPlayers(user.data)

        let tempPlayers = []
        players.forEach((player) => {
            let index = user.data.findIndex(plr => plr.name === player.name)
            if(index !== -1) {
                tempPlayers.push({
                    ...player,
                    isSelected: true,
                    selectedAs: user.data[index].type
                })
            } else {
                tempPlayers.push({
                    ...player,
                    isSelected: false,
                    selectedAs: ''
                })
            }
        })
        
        setPlayersList(tempPlayers)
        setAllPlayers(tempPlayers)
        fetchPlayers()
    }


    /* fecth Players */
    const fetchPlayers = () => {
        firebase.db.collection(tournament).doc('players').onSnapshot(doc => {
            if(doc.exists){
                dispatch(setReduxPlayers(doc.data()))
            } else {
                dispatch(setReduxPlayers(null))
            }
        })
    }

    useEffect(() => {
        //logoutUser()
        if(user !== null){
            if(user.hasSelected){
                if(user.hasPaid !== true) {
                    refreshUser()
                } else {
                    resetData()
                }
            } else {
                refreshUser()
            }
        }
        
        let today = new Date()
        let day = today.getDate()
        let month = today.getMonth() + 1
        let year = today.getFullYear()
        if(year <= lastDate.year){
            if(month === lastDate.month){
                if(day <= lastDate.day){
                    setCanEdit(true)
                } else {
                    setCanEdit(false)
                }
            } else if(month < lastDate.month){
                setCanEdit(true)
            } else {
                setCanEdit(false)
            }  
        } else {
            setCanEdit(false)
        }

    }, [])

    const handleEditClick = () => {
        if(!editOpen){
            setEditOpen(true)
        } else if(checkChanges()) {
            setOpen(true)
        } else {
            setEditOpen(!editOpen)
        } 
        
        setInitialSelected(teams[team-1].team)

        let tempPlayers = []
        players.forEach((player) => {
            let index = teams[team-1].team.findIndex(plr => plr.name === player.name)
            if(index !== -1) {
                tempPlayers.push({
                    ...player,
                    isSelected: true,
                    selectedAs: user.data[index].type
                })
            } else {
                tempPlayers.push({
                    ...player,
                    isSelected: false,
                    selectedAs: ''
                })
            }
        })
        
        setPlayersList(tempPlayers)
        setAllPlayers(tempPlayers)
     
    }

    /* check if there are changes (compare: user.data vs selectedPlayers) */
    const checkChanges = () => {
        let flag = false
        console.log(initialSeleced)
        console.log(selectedPlayers)
        initialSeleced.forEach((player, i) => {
            //console.log(player)
            if(player.name !== selectedPlayers[i].name) {
                //there are changes
                flag = true
            }
        })
        console.log(flag)
        setChanges(flag)
        return flag
    }

    /* handle Selected Player Click */
    const handleSelectedPlayerClick = (index) => {
        let temp = [...selectedPlayers]
        temp.map((player,i) => {
            player.inSelection = i === index
        })
        //console.log(allPlayers)
        setSelectedType(temp[index].type)
        setSelectedPlayer(temp[index].name)
        setSelectedPlayers(temp)
    }

    /* handle filter change */
    const handleFilterChange = (value) => {
        setPlayerFilter(value)
        let data = allPlayers.filter(player => player.name.toLowerCase().includes(value.toLowerCase()))
        //setAllPlayers(data)
        setPlayersList(data)
    }

    /* handle player click */
    const handlePlayerClick = (action, name) => {
        let temp = [...selectedPlayers]
        let playersTemp = [...allPlayers]
        let index = selectedPlayers.findIndex(plr => plr.inSelection === true)

        if(action === "add"){
            let player = allPlayers.find(plr => plr.name === name)
            let i = allPlayers.findIndex(plr => plr.name === name)

            if(temp[index].name !== '--') {
                let i2 = allPlayers.findIndex(plr => plr.name === temp[index].name)
                playersTemp[i2].isSelected = false
            }
            

            //players list
            if(playersTemp[i].isSelected){
                if(playersTemp[i].selectedAs !== selectedType) {
                    selectedPlayers.forEach((plr,index2) => {
                        if(plr.name === playersTemp[i].name){
                            temp[index2].name = '--'
                            temp[index2].img = './img/players/person.png'
                            temp[index2].isSelected = false
                        }
                    })
                }
            }

            temp[index].name = player.name
            temp[index].img = player.img
            temp[index].isSelected = true
            playersTemp[i].isSelected = true
            playersTemp[i].selectedAs = selectedType

            //calculate next index
            let next = index
            let selected = [0,0,0,0,0,0]
            let found = false
            selected.forEach((el,k) => {selected[k] = temp[k].isSelected ? 1 : 0})

            while(next < 5 && !found){
                next += 1;
                if(!selected[next]){
                    found = true
                }
            }
            if(!found) {
                next = 0
                found = false
                while(next < 5 && !found){
                    if(!selected[next]){
                        found = true
                    } else {
                        next += 1;
                    }
                }
            }
            if(!found) {
                console.log("All are selected")
                setErrors([])
            } else {
                handleSelectedPlayerClick(next)
            }
            
        } else {
            index = selectedPlayers.findIndex(plr => plr.name === name)
            temp[index].name = '--'
            temp[index].img = './img/players/person.png'
            temp[index].isSelected = false

            //players list
            let i = allPlayers.findIndex(plr => plr.name === name)
            playersTemp[i].isSelected = false
            playersTemp[i].selectedAs = ''
        }

        setPlayerFilter('')
        setAllPlayers(playersTemp)
        setPlayersList(playersTemp)
        setSelectedPlayers(temp)
        checkChanges()
    }

    /* handle verify data */
    const verifyData = () => {
        //let found = false
        let temp = []
        selectedPlayers.forEach((player,i) => {
            if(!player.isSelected){
                let str = "Falta agregar tu " + player.type
                temp.push(str)
            }
        })
        console.log(temp)
        setErrors(temp)
        return errors.length === 0
    }

    /* handle upload data */
    const updateData = () => {
        console.log("uploading data")
        firebase.db.collection("users").doc(user.uid).update({
            data: selectedPlayers,
            hasSelected: true,
        }, {
            merge: true
        }).catch(error => {
            console.log(error);
            setErrors(["Error al guardar los datos"]);
        }).then(() => {
            dispatch(setUser({
                ...user,
                hasSelected: true,
                data: selectedPlayers,
            }))
            setEditOpen(false)

            if(reduxPlayers !== null){
                let players = []

                selectedPlayers.forEach((player2) => {
                    if(reduxPlayers.data.find(plr => plr.name === player2.name) === undefined){
                        players.push({
                            name: player2.name,
                            img: player2.img,
                            cut: true,
                            r1: roundBase,
                            r2: roundBase,
                            r3: roundBase,
                            r4: roundBase,
                        })
                    }
                })
                console.log(players)
                dispatch(setReduxPlayers({data:players}))
                firebase.db.collection(tournament).doc('players').set(
                    {
                        data: players,
                    }
                )
            } else {
                let temp = []
                selectedPlayers.map((player) => {
                    temp.push(
                        {
                            name: player.name,
                            img: player.img,
                            times: 1,
                            r1: roundBase,
                            r2: roundBase,
                            r3: roundBase,
                            r4: roundBase,
                        }
                    )
                })
                console.log(temp)
                firebase.db.collection(tournament).doc('players').set(
                    {
                        data: temp,
                    }
                )
            }
        })

        
    }

    /* handle update team */
    const handleUpdateTeam = () => {
        if(verifyData()){
            let tempTeams = [...teams]
            
            tempTeams[team-1].team = selectedPlayers
            tempTeams[team-1].num = team
            setTeams(tempTeams)
            tempTeams = tempTeams.slice(1)
            console.log(tempTeams)
            firebase.db.collection("users").doc(user.uid).update({
                teams: tempTeams,
                numTeams: numTeams
            }, {
                merge: true
            }).catch(error => {
                console.log(error);
                setErrors(["Error al guardar los datos"]);
            }).then(() => {
                dispatch(setUser({
                    ...user,
                    hasSelected: true,
                    teams: tempTeams
                }))

                if(reduxPlayers !== null){
                    let players = []
                    
                    //add new players
                    selectedPlayers.forEach((player2) => {
                        if(reduxPlayers.data.find(plr => plr.name === player2.name) === undefined){
                            players.push({
                                name: player2.name,
                                img: player2.img,
                                cut: true,
                                r1: roundBase,
                                r2: roundBase,
                                r3: roundBase,
                                r4: roundBase,
                            })
                        }
                    })
                    console.log(players)
                    dispatch(setReduxPlayers({data:players}))
                    firebase.db.collection(tournament).doc('players').set(
                        {
                            data: players,
                        }
                    )
                } else {
                    let temp = []
                    selectedPlayers.map((player) => {
                        temp.push(
                            {
                                name: player.name,
                                img: player.img,
                                times: 1,
                                pickedBy: [user.uid],
                                cut: true,
                                r1: roundBase,
                                r2: roundBase,
                                r3: roundBase,
                                r4: roundBase,
                            }
                        )
                    })
                    console.log(temp)
                    firebase.db.collection(tournament).doc('players').set(
                        {
                            data: temp,
                        }
                    )
                }
                setEditOpen(false)
            })
        }
    }

    /* handle save data */
    const handleSaveData = () => {
        if(verifyData()){
            console.log(team)
            if(team > 1){
                handleUpdateTeam()
            } else {
                updateData()
            }
            
        }
    }

    /* handle close */
    const handleClose = (option) => {
        setOpen(false);

        if(option){
            //save changes
            if(verifyData()){
                setEditOpen(false)
                handleSaveData()
            }
            
        } else {
            //discard changes
            setEditOpen(false)
            resetData()
        }
    };

    /* handle team change */
    const handleTeamChange = (num) => {
        let tempSelected = []
        teams[num-1].team.forEach((player) => {
            tempSelected.push({
                ...player,
                inSelection: player.type === "Capitán"
            })
        })

        console.log(tempSelected)
        setTeam(num)
        setSelectedPlayers(tempSelected)
    }

    /* handle add team click */
    const handleAddTeamClick = () => {
        let tempSelected = []
        typePlayers.forEach((typePlayer,i) => {
            tempSelected.push(
                {
                    name: '--',
                    img: './img/players/person.png',
                    inSelection: typePlayer === "Capitán",
                    isSelected: false,
                    type: typePlayer
                }
            )
        })
        setSelectedPlayers(tempSelected)

        let tempPlayers = []
        players.forEach((player) => {
            tempPlayers.push({
                ...player,
                isSelected: false,
                selectedAs: ''
            })
        })
        setPlayersList(tempPlayers)
        setAllPlayers(tempPlayers)
        setNumTeams(numTeams + 1)
        setEditOpen(true)
        setAdding(true)
        fetchPlayers()
    }

    /* handle cancel add click */
    const handleCancelAddClick = () => {
        setNumTeams(numTeams - 1)
        setTeam(1)
        setEditOpen(false)
        setAdding(false)
        resetData()
    }

    /* handle add team */
    const handleAddTeam = () => {
        if(verifyData()){
            let allTeams = [...teams]
            let tempTeams = [...teams]
            tempTeams = tempTeams.slice(1)
            tempTeams.push({team: selectedPlayers, num: numTeams})
            allTeams.push({team: selectedPlayers, num: numTeams})
            console.log(tempTeams)
            firebase.db.collection("users").doc(user.uid).update({
                teams: tempTeams,
                numTeams: numTeams
            }, {
                merge: true
            }).catch(error => {
                console.log(error);
                setErrors(["Error al guardar los datos"]);
            }).then(() => {
                dispatch(setUser({
                    ...user,
                    hasSelected: true,
                    teams: tempTeams
                }))
            })
            setAdding(false)
            setEditOpen(false)
            setTeams(allTeams)
        }
    }

    /* handle delete team click */
    const handleDeleteClick = () => {
        //alert confirmation
        setDialogOpen(true)
    }

    /* handle delete team */
    const handleDeleteTeam = () => {
        //delete team
        let tempTeams = [...teams]
        let allTeams = [...teams]
        console.log(tempTeams)
        allTeams = allTeams.filter((el) => el.num !== team)
        tempTeams = tempTeams.filter((el) => el.num !== team)
        tempTeams = tempTeams.filter((el) => el.num !== 1)
        //allTeams = allTeams.splice(team - 1, 1)
        console.log(allTeams)

        if(team < numTeams) {
            let temp = []
            let temp2 = []
            temp2.push(allTeams[0])
            //change teamss nnumbera
            tempTeams.forEach((item, i) => {
                temp.push({
                    ...item,
                    num: i + 2
                })
                temp2.push({
                    ...item,
                    num: i + 2
                })
            })
            console.log(temp)
            console.log(temp2)
            tempTeams = temp
        }

        firebase.db.collection('users').doc(user.uid).update({
            teams: tempTeams,
            numTeams: numTeams - 1
        }, {
            merge: true
        }).catch(error => {
            console.log(error);
            setErrors(["Error al guardar los datos"]);
        }).then(() => {
            setTeams(tempTeams)
            setNumTeams(numTeams - 1)
            setTeam(1)
            setDialogOpen(false)
        })

    }

    return (
        <>
            {(user === null || user === undefined) && (
                <Redirect to="/"/>
            )}
            {user && !user.hasSelected && (
                <Redirect to="/selection"/>
            )}
            
            <div className={classes.backgroundContainer}>
                <div className={classes.overlay}>
                    {/* header */}
                    <div className={classes.header} id="header">
                        <Logos/>
                        <h1 className={classes.title}>Bienvenido {user && user.nickname}</h1>
                    </div>

                    {user && !userHasPaid ? (
                        <>
                            <div className={classes.errorContainer} style={{marginBottom: 20}}>
                                <ReportGmailerrorredRoundedIcon className={classes.errorIcon}/>
                                <p className={classes.errorText}>Una vez que hayamos registrado tu pago, tendrás acceso a la plataforma</p>
                            </div> 
                            <div className={classes.messageContainer} style={{marginBottom: 20}}>
                                <p className={classes.messageSubtitle}><b>Información del pago</b></p>
                                <p className={classes.messageText}><b>Money Pool (preferida)</b></p>
                                <p className={classes.messageText} style={{marginBottom: 10}}>
                                    <a href="https://www.moneypool.mx/p/RWfdu5M" target="_blank" rel="noreferrer" className={classes.link}>https://www.moneypool.mx/p/RWfdu5M</a>
                                </p>
                                <p className={classes.messageText}><b>Efectivo</b></p>
                                <p className={classes.messageText} style={{marginBottom: 10}}>Pago en efectivo a Gabriel Gutierrez (999 997 2751)</p>
                                <p className={classes.messageText}><b>Transferencia</b></p>
                                <p className={classes.messageText}>CLABE: 002910701710520076</p>
                                <p className={classes.messageText}>Banco: Banamex</p>
                                <p className={classes.messageText}>Titular: Gabriel Gutierrez Castillo</p>
                                <p className={classes.messageText}>Concepto: Quiniela - tu teléfono</p>
                                <p className={classes.messageText}>Enviar el comprobante a Gabriel Gutiérrez (999 997 2751) o Abraham Cepeda (999 365 4620)</p>
                            </div> 
                            <div className={classes.actionButton}>
                                <WhiteButton onClick={logoutUser}>Cerrar sesión</WhiteButton>
                            </div>
                        </>
                    ): (
                        <>
                            {/* user results */}
                            {user && (
                                <UserResults team={team}/>
                            )}


                            {/* Edit header */}
                            <div className={classes.subtitleContainer}>
                                {adding ? (
                                    <h3 className={classes.subtitle}>Agregando equipo {numTeams}</h3>
                                ) : (
                                    <div className={classes.selectContainer}>
                                        <Select
                                            labelId="demo-customized-select-label"
                                            id="demo-customized-select"
                                            value={team}
                                            onChange={(e) => {handleTeamChange(e.target.value)}}
                                            input={<TransparentInput />}
                                            style={{color: 'white !important'}}
                                            inputProps={{
                                                classes: {
                                                    icon: classes.icon
                                                }
                                            }}
                                            disabled={editOpen}
                                            >
                                            {teams.length !== 0 && teams.map((item, i) => (
                                                <MenuItem key={i} value={i+1}>Equipo {i+1}/{numTeams}</MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                )}
                                {canEdit && (
                                    <>
                                        {adding ? (
                                            <Button className={classes.groupBtn} onClick={handleCancelAddClick} variant="contained" endIcon={<HighlightOffRoundedIcon />}>
                                                Cancelar
                                            </Button>
                                        ) : (
                                            <Button className={classes.groupBtn} onClick={handleAddTeamClick} variant="contained" endIcon={<AddCircleRoundedIcon />}>
                                                Equipo
                                            </Button>
                                        )}    
                                    </>
                                )}
                                
                                {canEdit && (
                                    <>
                                        {editOpen && !adding ? (
                                            <Tooltip title="Cerrar" placement='top'>
                                                <IconButton onClick={handleEditClick}>
                                                    <HighlightOffRoundedIcon className={classes.icon}/>
                                                </IconButton>
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Editar equipo" placement='top'>
                                                <IconButton onClick={handleEditClick} disabled={adding}>
                                                    <CreateRoundedIcon className={classes.icon}/>
                                                </IconButton>
                                            </Tooltip>
                                        )}  
                                    </>
                                )}
                                {(canEdit && !editOpen && numTeams > 1 && team !== 1) && (
                                    <Tooltip title="Eliminar" placement='top'>
                                        <IconButton
                                            onClick={handleDeleteClick}
                                            size="small"
                                            sx={{ ml: 2 }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <DeleteRoundedIcon className={classes.icon}/>
                                        </IconButton>
                                    </Tooltip>
                                )}
                                
                                <Tooltip title="Menu" placement='top'>
                                    <IconButton
                                        onClick={handleMenuClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <MoreVertRoundedIcon className={classes.icon}/>
                                    </IconButton>
                                </Tooltip>
                            </div>

                            {/* list of selected players */}
                            <div className={classes.selectedPlayersContainer}>
                                {(!editOpen && user !== null) && (
                                    <div className={classes.paidContainer}>
                                        {user.paidTeams < team ? (
                                            <Chip icon={<CancelRoundedIcon/>} label="NO PAGADO" color='error' variant='outlined'/>
                                        ) : (
                                            <Chip icon={<CheckCircleRoundedIcon/>} label="PAGADO" color='success' variant='outlined'/>
                                        )}
                                    </div>
                                )}
                                <div className={classes.selectedPlayersInnerContainer}>
                                    {selectedPlayers.map((player, i) => (
                                        <div key={i} className={classes.typePlayerContainer} onClick={() => {handleSelectedPlayerClick(i)}} style={{border: (player.inSelection) ? '1px solid white': 'none'}}>
                                            <div className={classes.typePlayerImg} style={{backgroundImage: `url(${player.img})`, opacity: player.isSelected ? 1 : 0.75}}></div>
                                            {(player.isSelected && editOpen) && (
                                                <IconButton onClick={() => {handlePlayerClick("remove", player.name)}} className={classes.removeIcon}>
                                                    <RemoveCircleOutlineRoundedIcon className={classes.icon}/>
                                                </IconButton>
                                            )}
                                            <p className={classes.typePlayerName}>{player.name}</p>
                                            <p className={classes.typePlayerText}>{player.type} | {memberType[i].per}</p>
                                        </div>
                                    ))}
                                </div>
                                {!editOpen && (
                                    <PlayerData name={selectedPlayer} type={selectedType} round2={1}/>
                                )}
                            </div>

                            {/* edit players content */}
                            <Collapse in={editOpen}>
                                <div>
                                    {/* filter */}
                                    <div className={classes.filterContainer}>
                                        <p className={classes.filterText}>Selecciona tu {selectedType}</p>
                                        <input 
                                            type="text" 
                                            placeholder="Nombre de jugador"
                                            autoCapitalize="none"
                                            name='text'
                                            value={playerFilter}
                                            onChange={(e) => handleFilterChange(e.target.value)}
                                            className={classes.filterInput}
                                        />
                                    </div>

                                    {/* list of players */}
                                    <div className={classes.playerList}>
                                        {playersList && playersList.map((player, i) => (
                                            <div key={i} className={classes.playerContainer}>
                                                <div className={classes.playerImg} style={{backgroundImage: `url(${player.img})`}}></div>
                                                <p className={classes.playerName}>{player.name}</p>
                                                {(player.isSelected && player.selectedAs === selectedType) ? (
                                                    <IconButton onClick={() => {handlePlayerClick("remove", player.name)}} className={classes.iconBtn}>
                                                        <RemoveCircleOutlineRoundedIcon className={classes.icon}/>
                                                    </IconButton>
                                                ) : (
                                                    <IconButton onClick={() => {handlePlayerClick("add", player.name)}} className={classes.iconBtn}>
                                                        <AddCircleOutlineRoundedIcon className={classes.icon}/>
                                                    </IconButton>
                                                )}
                                            </div>
                                        ))}
                                    </div>

                                    {/* Save team data */}
                                    {changes && (
                                        <div className={classes.actionButton}>
                                            {adding ? (
                                                <WhiteButton onClick={handleAddTeam}>Agregar equipo</WhiteButton>
                                            ) : (
                                                <WhiteButton onClick={handleSaveData}>Guardar equipo</WhiteButton>
                                            )}
                                        </div>
                                    )}
                                    

                                </div>
                                
                            </Collapse>

                            {/* info message */}
                            <div className={classes.errorContainer}>
                                <InfoRoundedIcon className={classes.infoIcon}/>
                                <p className={classes.messageText}>Podrás editar tu equipo hasta el {fechaLim} a las 11:59pm (CDMX)</p>
                            </div>

                            {errors.length !== 0 && (
                                <div className={classes.messageContainer} style={{marginBottom: 20}}>
                                    <ErrorOutlineRoundedIcon className={classes.infoIcon}/>
                                    <ul>
                                        {errors.map((err, i) => (
                                            <li key={i} className={classes.messageText}>{err}</li>
                                        ))}
                                    </ul>
                                </div> 
                            )}


                            {/* Menu */}
                            <MyMenu page="home"/>

                            {/* Dialog confirm delete team */}
                            <Dialog
                                open={dialogOpen}
                                onClose={handleDialogClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                {"¿Estas seguro de eliminar el equipo?"}
                                </DialogTitle>
                                <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Esta acción no se puede deshacer
                                </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={handleDialogClose} color="error" variant='outlined'>Cancelar</Button>
                                <Button onClick={handleDeleteTeam} color="error" variant='contained'>Eliminar</Button>
                                </DialogActions>
                            </Dialog>

                            {/* Dialog (confirm changes or discard them) */}
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogTitle id="alert-dialog-title">
                                {"Tienes cambios sin guardar"}
                                </DialogTitle>
                                <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Selecciona si deseas descartar o guardar los cambios en tu equipo.
                                </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                <Button onClick={() => handleClose(false)}>Descartar</Button>
                                <Button onClick={() => handleClose(true)} autoFocus>
                                    Guardar
                                </Button>
                                </DialogActions>
                            </Dialog>

                            {/* Menu display */}
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={menuOpen}
                                onClose={handleMenuClose}
                                onClick={handleMenuClose}
                                PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 5,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <Link to="/tutorials" className={classes.menuLink}>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <AssignmentRoundedIcon/>
                                        </ListItemIcon>
                                        Tutoriales
                                    </MenuItem>
                                </Link>
                                <Link to="/update-password" className={classes.menuLink}>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <KeyRoundedIcon/>
                                        </ListItemIcon>
                                        Cambiar constraseña
                                    </MenuItem>
                                </Link>
                                <Link to="/" className={classes.menuLink} onClick={logoutUser}>
                                    <MenuItem>
                                        <ListItemIcon>
                                            <Logout/>
                                        </ListItemIcon>
                                        Cerrar sesión
                                    </MenuItem>
                                </Link>
                            </Menu>
                        </>
                    )}

                    
                </div>
            </div>
        </>
    )
}

import React, { useEffect, useState } from 'react'

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";

//components
import PlayerData from './PlayerData';

//data
import memberType from '../../constants/memberType';

const useStyles = makeStyles((theme) =>({
    /* Selected players */
    selectedPlayersContainer: {
        margin: '10px',
        paddingBottom: '10px',
        maxWidth: '800px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto'
        }
    },
    selectedPlayersInnerContainer: {
        display: 'flex',
        overflowX: 'scroll',
        padding: '10px 10px',
        marginBottom: '10px'
    },
    typePlayerContainer: {
        background: 'none',
        minWidth: '80px',
        marginRight: '20px',
        padding: '5px',
        border: '2px solid rgba(0,0,0,0)',
        borderRadius: '10px',
        cursor: 'pointer'
    },
    typePlayerImg: {
        width: '60px',
        height: '60px',
        margin: 'auto',
        marginBottom: 5,
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    typePlayerName: {
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold'
    },
    typePlayerText: {
        color: 'white',
        fontSize: '12px',
        opacity: 0.75
    },
}))


export default function UserResults({classes, team, round}) {
    classes = useStyles();
    
    const [selectedPlayers, setSelectedPlayers] = useState([])
    const [selectedPlayer, setSelectedPlayer] = useState("")
    const [selectedType, setSelectedType] = useState("Capitán")


    useEffect(() => {
        //fecthProfile()
        let temp = []
        team.forEach((item) => {
            temp.push({
                ...item,
                inSelection: item.type === "Capitán"
            })
        })
        setSelectedPlayers(temp)
        setSelectedPlayer(temp[0].name)
    }, [team])

    const handleSelectedPlayerClick = (index) => {
        let temp = [...selectedPlayers]
        temp.map((player,i) => {
            player.inSelection = i === index
        })
        
        setSelectedType(temp[index].type)
        setSelectedPlayer(temp[index].name)
        setSelectedPlayers(temp)
    }

    return (
        <div className={classes.profileContainer}>
            {selectedPlayers.length !== 0 && (
                <div className={classes.selectedPlayersContainer}>
                    <div className={classes.selectedPlayersInnerContainer}>
                        {selectedPlayers.map((player, i) => (
                            <div key={i} className={classes.typePlayerContainer} onClick={() => {handleSelectedPlayerClick(i)}} style={{border: (player.inSelection) ? '1px solid white': 'none'}}>
                                <div className={classes.typePlayerImg} style={{backgroundImage: `url(${player.img})`, opacity: player.isSelected ? 1 : 0.75}}></div>
                                <p className={classes.typePlayerName}>{player.name}</p>
                                <p className={classes.typePlayerText}>{player.type} | {memberType[i].per}</p>
                            </div>
                        ))}
                    </div>
                    <PlayerData name={selectedPlayer} type={selectedType} round2={round}/>
                </div>
            )}
        </div>
    )
}

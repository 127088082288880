import React from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom";


//Global
import Login from './Pages/Login'
import SignUp from './Pages/SignUp'
import ForgotPassword from './Pages/Login/ForgotPassword'
import UpdatePassword from './Pages/Login/UpdatePassword'

//User
import Home from './Pages/User/Home'
import Selection from './Pages/User/Selection'
import Leaderboard from "./Pages/User/Leaderboard";
import Rules from './Pages/User/Rules'
import Players from './Pages/User/Players'
import Tutorials from "./Pages/User/Tutorials";

//Admin
import AdminIndex from './Pages/Admin'
import AdminUsers from './Pages/Admin/Users'
import AdminPlayers from './Pages/Admin/Players'

//Not Found
import NotFound from './Pages/NotFound'

const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                {/* Auth */}
                <Route exact path="/" component={Login}/>
                <Route exact path="/sign-up" component={SignUp}/>
                <Route exact path="/forgot-password" component={ForgotPassword}/>
                <Route exact path="/update-password" component={UpdatePassword}/>

                {/* User */}
                <Route exact path="/home" component={Home}/>
                <Route exact path="/selection" component={Selection}/>
                <Route exact path="/leaderboard" component={Leaderboard}/>
                <Route exact path="/rules" component={Rules}/>
                <Route exact path="/tutorials" component={Tutorials}/>
                <Route exact path="/players" component={Players}/>

                {/* Admin */}
                <Route exact path="/admin" component={AdminIndex}/>
                <Route exact path="/admin/users" component={AdminUsers}/>
                <Route exact path="/admin/players" component={AdminPlayers}/>

                {/* Not found */}
                <Route path="*" component={NotFound}/>
            </Switch>
        </BrowserRouter>
    )
}

export default Router;
//MaterialUI
import { makeStyles } from "@material-ui/core/styles";

//utils
import openInNewTab from '../../utils/functions/openInNewTab'

//router
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) =>({
    logosContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    peninsula: {
        height: '90px',
        width: 'auto',
        cursor: 'pointer',
        transition: 'transform 450ms',
        [theme.breakpoints.down(500)]: {
            height: '60px'
        },
        '&:hover': {
            transform: 'scale(1.08)'
        }
    },
    golf: {
        height: '100px',
        width: 'auto',
        cursor: 'pointer',
        transition: 'transform 450ms',
        [theme.breakpoints.down(500)]: {
            height: '60px'
        },
        '&:hover': {
            transform: 'scale(1.08)'
        }
    },
    logo: {
        height: '160px',
        width: 'auto',
        marginLeft: '10px',
        marginRight: '10px',
        cursor: 'pointer',
        transition: 'transform 450ms',
        [theme.breakpoints.down(500)]: {
            height: '120px'
        },
        '&:hover': {
            transform: 'scale(1.06)'
        }
    }
}))

export default function Logos({classes}) {
    classes = useStyles();

    return (
        <div className={classes.logosContainer}>
            <img src="/./img/login/dfuture2.png" className={classes.peninsula} onClick={() => {openInNewTab('https://dfuture.com.mx/')}}/>
            <Link to="/home">
                <img src="/./img/login/golf_logo.png" className={classes.logo}/>
            </Link>
            <img src="/./img/login/olympics_2024.png" className={classes.golf} onClick={() => {openInNewTab('https://olympics.com/en/paris-2024/schedule/golf?day=1-august')}}/>
        </div>
    )
}

import React, { useEffect, useState } from 'react'

//redux
import { useSelector, useDispatch } from 'react-redux';
import { logout, setReduxPlayers } from '../../store/actions'
import { Redirect } from 'react-router-dom';

//MaterialUI
import { makeStyles } from "@material-ui/core/styles";
import Collapse from '@mui/material/Collapse';

//Material UI - Icons

import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

//Components
import Menu from '../../components/user/Menu'
import { TransparentButton } from '../../utils/Buttons';
import PlayerData from '../../components/user/PlayerData';
import Logos from '../../components/user/Logos';

//firebase
import firebase from '../../database/firebase'

//utils
import fixedInt from '../../utils/functions/fixedInt';

//loader
import Loader from "react-loader-spinner";

//Constants
import { tournament } from '../../constants/tournament';

const useStyles = makeStyles((theme) =>({
    backgroundContainer: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'url(/./img/login/background.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
    },
    overlay: {
        minWidth: '100vw',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(180deg, #0E191C 30%, #0e1d1deb 53%, #0e1d1de8 100%)',
        paddingTop: 30,
        paddingBottom: 100,
        textAlign: 'center',
        color: 'white',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: '25px',
        fontWeight: 'bold',
        marginBottom: 20,
        marginTop: 30,
        [theme.breakpoints.down(450)]: {
            fontSize: '22px'
        }
    },

    /* action buttons */
    actionsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px',
        marginBottom: '30px',
        maxWidth: '800px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto',
            marginBottom: '30px',
        }
    },

    /* rounds */
    roundsContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '10px',
        flex: 1
    },
    roundItem: {
        width: '40px',
        border: '2px solid white',
        borderRadius: '20px',
        marginRight: '8px',
        fontWeight: 'bold',
        fontSize: '14px',
        cursor: 'pointer',
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)'
        },
        [theme.breakpoints.up(768)]: {
            width: '40px',
            marginRight: '10px',
            fontSize: '16px',
        }
    },
    refreshButton: {
        border: '1px solid white',
        borderRadius: '50%',
        padding: '6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.up(500)]: {
            '&:hover': {
                background: 'white',
                color: 'black'
            }
        }
    },

    /* leaderboard */
    leaderboard: {
        margin: '5px',
        borderRadius: '10px',
        maxWidth: '800px',
        [theme.breakpoints.up(800)]: {
            margin: 'auto'
        },
        
    },
    leaderboardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '12px',
        fontWeight: 'bold',
        padding: '8px 0px',
        //background: '#0a2e0a'
        background: '#0E1D1D'
    },
    leaderboardSmallCell: {
        width: '35px',
        textAlign: 'center',
        //borderRight: '0.5px solid white'
    },
    leaderboardLargeCell: {
        flex: 1,
        textAlign: 'left',
        paddingLeft: '5px',
        borderLeft: '0.5px solid white',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        [theme.breakpoints.up(500)]: {
            paddingLeft: '10px'
        }
    },
    leaderboardMediumCell: {
        width: '52px',
        textAlign: 'center',
        borderLeft: '0.5px solid white',
        [theme.breakpoints.up(500)]: {
            width: '70px'
        }
    },
    leaderboardLastCell: {
        width: '35px',
        textAlign: 'center',
        borderLeft: '0.5px solid white'
    },

    /* player rows */
    playerRow: {
        fontSize: '12px',
        fontWeight: 'bold',
        padding: '5px 0px',
        background: '#193636',
        cursor: 'pointer',
        '&:hover': {
            background: '#225050',
        }
    },
    playerHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 0px',
    },
    playerImg: {
        width: '30px',
        height: '30px',
        minWidth: '30px',
        marginRight: '5px',
        borderRadius: '50%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.up(500)]: {
            marginRight: '10px'
        }
    },
    
    /* icons */
    icon: {
        color: 'white',
        transition: 'transform 450ms',
        '&:hover': {
            transform: 'scale(1.1)',
        }
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        }
    }
}))


export default function Players({classes}) {
    classes = useStyles();

    //redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.userState.user);
    const reduxPlayers = useSelector(state => state.playersState.players);

    //state
    const [allPlayers, setAllPlayers] = useState([])
    const [playersList, setPlayersList] = useState([])
    const [missedCutPlayers, setMissedCutPlayers] = useState([])
    const [selectedPlayer, setSelectedPlayer] = useState('');
    const [round, setRound] = useState(1)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetchPlayers()
    }, [])

    /* sleep */
    function sleep(time){
        return new Promise((resolve)=>setTimeout(resolve,time)
        )
    }

    /* Fetch player from firebase */
    const fetchPlayers = () => {
        setLoading(true)
        sleep(1000).then(() => {
            firebase.db.collection(tournament).doc('players').onSnapshot(doc => {
                if(doc.exists){
                    dispatch(setReduxPlayers(doc.data()))
                    if(round > 2){
                        //send missed cuts to the bottom
                        let temp = []
                        let missedCut = []
                        let all = []
                        doc.data().data.forEach((item) => {
                            if(item.cut){
                                temp.push({
                                    ...item,
                                    isOpen: false
                                })
                            } else {
                                missedCut.push({
                                    ...item,
                                    isOpen: false
                                })
                            }
                            all.push({
                                ...item,
                                isOpen: false
                            })
                        })
                        temp.sort((a,b) => { return a[`r${round}`].ptsPos + a[`r${round}`].ptsScore > b[`r${round}`].ptsPos + b[`r${round}`].ptsScore ? -1 : 1})
                        setAllPlayers(all)
                        setPlayersList(temp)
                        setMissedCutPlayers(missedCut)
                    } else {
                        let temp = []
                        doc.data().data.forEach((item) => {
                            temp.push({
                                ...item,
                                isOpen: false
                            })
                        })
                        temp.sort((a,b) => { return a[`r${round}`].ptsPos + a[`r${round}`].ptsScore > b[`r${round}`].ptsPos + b[`r${round}`].ptsScore ? -1 : 1})
                        setAllPlayers(temp)
                        setPlayersList(temp)
                    }
                } else {
                    dispatch(setReduxPlayers(null))
                }
                setLoading(false)
            })
        })
    }

    /* Handle select player */
    const handleSelectPlayer = (name) => {
        setSelectedPlayer(name === selectedPlayer ? "" : name)
    }


    const handleRoundChange = (num) => {
        
        let data = [...allPlayers]

        if(num > 2){
            //send missed cuts to the bottom
            let temp = []
            let missedCut = []
            let all = []
            data.forEach((item) => {
                if(item.cut){
                    temp.push({
                        ...item,
                        isOpen: false
                    })
                } else {
                    missedCut.push({
                        ...item,
                        isOpen: false
                    })
                }
                all.push({
                    ...item,
                    isOpen: false
                })
            })
            temp.sort((a,b) => { return a[`r${num}`].ptsPos + a[`r${num}`].ptsScore > b[`r${num}`].ptsPos + b[`r${num}`].ptsScore ? -1 : 1})
            setAllPlayers(all)
            setPlayersList(temp)
            setMissedCutPlayers(missedCut)
        } else {
            let temp = []
            data.forEach((item) => {
                temp.push({
                    ...item,
                    isOpen: false
                })
            })
            temp.sort((a,b) => { return a[`r${num}`].ptsPos + a[`r${num}`].ptsScore > b[`r${num}`].ptsPos + b[`r${num}`].ptsScore ? -1 : 1})
            setAllPlayers(temp)
            setPlayersList(temp)
        }
        /* temp.sort((a,b) => { return a[`r${num}`].ptsPos + a[`r${num}`].ptsScore >  b[`r${num}`].ptsPos + b[`r${num}`].ptsScore ? -1 : 1})
        setPlayersList(temp) */
        setRound(num)
    }

    return (
        <>
            {(user === null || user === undefined) && (
                <Redirect to="/"/>
            )}
            {user && user.type === 'user' && !user.hasSelected && (
                <Redirect to="/seleccion"/>
            )}
            
            <div className={classes.backgroundContainer}>
                <div className={classes.overlay}>
                    {/* header */}
                    <div className={classes.header} id="header">
                        <Logos/>
                        <h1 className={classes.title}>Ranking jugadores</h1>
                    </div>

                    {/* rounds && refresh */}
                    <div className={classes.actionsContainer}>
                        <div className={classes.roundsContainer}>
                            <div className={classes.roundItem} onClick={() => {handleRoundChange(1)}} style={{background: round === 1 ? 'white': "none", color: round === 1 ? '#0a2e0a': 'white'}}>
                                <p>R1</p>
                            </div>
                            <div className={classes.roundItem} onClick={() => {handleRoundChange(2)}} style={{background: round === 2 ? 'white': "none", color: round === 2 ? '#0a2e0a': 'white'}}>
                                <p>R2</p>
                            </div>
                            <div className={classes.roundItem} onClick={() => {handleRoundChange(3)}} style={{background: round === 3 ? 'white': "none", color: round === 3 ? '#0a2e0a': 'white'}}>
                                <p>R3</p>
                            </div>
                            <div className={classes.roundItem} onClick={() => {handleRoundChange(4)}} style={{background: round === 4 ? 'white': "none", color: round === 4 ? '#0a2e0a': 'white'}}>
                                <p>R4</p>
                            </div>
                        </div>
                        <div>
                            <div onClick={fetchPlayers} className={classes.refreshButton}>
                                <RefreshRoundedIcon/>
                            </div>
                        </div>
                    </div>

                    {/* List of players */}
                    {loading ? (
                        <div className={classes.loaderContainer}>
                            <Loader  type="ThreeDots" color="#FFFFFF" height={100} width={100} />
                        </div>
                    ) : (
                        <div className={classes.leaderboard}>
                            <div className={classes.leaderboardHeader}>
                                <div className={classes.leaderboardSmallCell}>POS</div>
                                <div className={classes.leaderboardLargeCell}>NOMBRE</div>
                                <div className={classes.leaderboardMediumCell}>SCORE</div>
                                <div className={classes.leaderboardMediumCell}>POS</div>
                                <div className={classes.leaderboardMediumCell}>TOTAL</div>
                                <div className={classes.leaderboardLastCell}>R{round}</div>
                            </div>
                            {/* rows of players */}
                            {playersList.length !== 0 && playersList.map((player,index) => (
                                <div key={index} className={classes.playerRow}>
                                    <div className={classes.playerHeader} onClick={() => {handleSelectPlayer(player.name)}}>
                                        <div className={classes.leaderboardSmallCell}>{index+1}</div>
                                        <div className={classes.leaderboardLargeCell}>
                                        <div className={classes.playerImg} style={{backgroundImage: `url(${player.img})`}}></div>
                                        <p>{player.name}</p>
                                        </div>
                                        <div className={classes.leaderboardMediumCell}>{player[`r${round}`].ptsScore}</div>
                                        <div className={classes.leaderboardMediumCell}>{fixedInt(player[`r${round}`].ptsPos)}</div>
                                        <div className={classes.leaderboardMediumCell}>{fixedInt(player[`r${round}`].ptsScore + player[`r${round}`].ptsPos)}</div>
                                        <div className={classes.leaderboardLastCell}>{fixedInt(player[`r${round}`].tot)}</div>
                                    </div>
                                    <Collapse in={selectedPlayer === player.name}>
                                        <PlayerData name={selectedPlayer} type="" round2={round}/>
                                    </Collapse>
                                </div>
                            ))}

                            {(round > 2 && missedCutPlayers.length !== 0) && missedCutPlayers.map((player, index) => (
                                <div key={index} className={classes.playerRow} style={{borderTop: index === 0 && '2px dashed white'}}>
                                    <div className={classes.playerHeader} onClick={() => {handleSelectPlayer(player.name)}}>
                                        <div className={classes.leaderboardSmallCell}>{playersList.length+index+1}</div>
                                        <div className={classes.leaderboardLargeCell}>
                                        <div className={classes.playerImg} style={{backgroundImage: `url(${player.img})`}}></div>
                                        <p>{player.name}</p>
                                        </div>
                                        <div className={classes.leaderboardMediumCell}>-</div>
                                        <div className={classes.leaderboardMediumCell}>-</div>
                                        <div className={classes.leaderboardMediumCell}>-</div>
                                        <div className={classes.leaderboardLastCell}>MC</div>
                                    </div>
                                    <Collapse in={selectedPlayer === player.name}>
                                        <PlayerData name={selectedPlayer} type="" round2={round}/>
                                    </Collapse>
                                </div>
                            ))}
                        </div>
                    )}
                    

                    {/* Menu */}
                    <Menu page="players"/>
                </div>
            </div>
        </>
    )
}
